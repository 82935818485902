import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { useGeneralStore } from "%/stores/general-store";

export const useCollaboratorInvitationFeedStore = defineSmartFeedPiniaStore("collaborator-invitation-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    metadata: {
      orgId: useGeneralStore().orgId,
      lastId: 0,
    },
  }),
  getters: {
    feedUrl() {
      return `/collaborator/${this.metadata.orgId}/invitations/smart-feed`;
    },
    smartFeedRequestGetParams() {
      return {
        last_id: this.metadata.lastId,
      };
    },
  },
});
