<template>
  <iModal
    v-if="visible"
    :title="`Connect ${displayName}`"
    :max-width="500"
    @close="$emit('close')"
  >
    <template #body>
      <iColumn gap="large">
        <iParagraph>
          Simply enter your connection details and we will save this to your account.
        </iParagraph>
        <iTextInput
          v-for="(field, index) in config"
          :key="field.key"
          v-model="field.value"
          :label="field.label"
          :name="field.key"
          width="fill"
          :type="field.type"
          :focus="index === 0"
        />
      </iColumn>
    </template>
    <template #footer>
      <iRow width="fill" align="right">
        <iColumn width="hug">
          <iButton
            :disabled="!isFormValid"
            variant="primary"
            @click="submit"
          >
            Connect
          </iButton>
        </iColumn>
      </iRow>
    </template>
  </iModal>
</template>

<script>
import { mapState } from "pinia";
import { useAffiliatePlatformsStore } from "@/stores/affiliate-platforms-store";

export default {
  name: "PlatformDetailsModal",
  props: {
    platform: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ["close", "submit"],
  data() {
    return {
      config: [],
    };
  },
  computed: {
    ...mapState(useAffiliatePlatformsStore, ["affiliatePlatforms"]),
    isFormValid() {
      if (this.config.length === 0) {
        return false;
      }
      for (let index = 0; index < this.config.length; index++) {
        if (!this.config[index].value) {
          return ;
        }
      }
      return true;
    },
    currentAffiliatePlatform() {
      return this.affiliatePlatforms.find(platform => platform.affiliate_platform === this.platform) ?? {};
    },
    displayName() {
      return this.currentAffiliatePlatform.display_name;
    },
  },
  created() {
    this.config = this.currentAffiliatePlatform.connection_config;
  },
  methods: {
    submit() {
      const updatedCredentials = this.config.reduce((credentials, field) => {
        credentials[field.key] = field.value;
        return credentials;
      }, {});

      this.$emit("submit", updatedCredentials);
    },
  },
};
</script>
