<template>
  <iRow v-if="showFilters || userFeedLayoutOptions.length > 0" justify="between">
    <iRow v-if="showFilters && Object.keys(smartFeedStore.filters).length > 0">
      <template v-for="(filter) in smartFeedStore.filters" :key="filter.key">
        <component
          :is="getSmartFeedFilterComponent(filter)"
          v-model="filter.value"
          v-bind="getFilterProps(filter)"
          @change="reloadSmartFeed"
        />
      </template>
    </iRow>
    <iRow v-if="userFeedLayoutOptions.length > 0" wrap="nowrap">
      <iSelect v-model="smartFeedLayout" :items="iSelectLayoutChoices" return-value />
    </iRow>
  </iRow>
  <SmartFeedPlaceholder v-if="editMode" :smart-feed-layout="defaultFeedLayout" :entity-layout="entityLayout" />
  <iInfiniteScroll
    v-else
    :has-more="smartFeedStore.hasMore"
    :is-loading="smartFeedStore.isLoading"
    height="hug"
    :scroll-threshold="1000"
    :show-load-more-trigger="wrapperComponent !== 'iCarousel'"
    :load-more-behavior="loadMoreBehavior"
    @load-more="smartFeedStore.getSmartFeed()"
  >
    <slot name="above-content" />
    <slot />
    <component
      :is="wrapperComponent"
      v-if="smartFeedStore.feed.length > 0"
      v-bind="wrapperComponentProps"
      @load-more="smartFeedStore.getSmartFeed()"
    >
      <SmartFeedItem
        v-for="feedItem in smartFeedStore.feed"
        :key="`${feedItem.type}-${feedItem.id}`"
        :entity-type="feedItem.type"
        :entity-id="feedItem.id"
        :layout="entityLayout"
        :fields="smartFeedStore.tableFields"
        :smart-feed-engine="smartFeedStore.smartFeedEngine"
        @click="$emit('click:item', feedItem)"
      />
    </component>
    <slot v-else-if="!smartFeedStore.isLoading" name="empty-state" />
  </iInfiniteScroll>
</template>

<script>
import { Enum } from "@bloglovin/vue-component-library";
import { SmartFeedError } from "@bloglovin/vue-framework";
import SmartFeedGraph from "SmartFeed/components/SmartFeedGraph.vue";
import SmartFeedItem from "SmartFeed/components/SmartFeedItem.vue";
import SmartFeedPlaceholder from "SmartFeed/components/SmartFeedPlaceholder.vue";
import SmartFeedTable from "SmartFeed/components/SmartFeedTable.vue";
import SmartFeedFilterDropdown from "SmartFeed/filters/SmartFeedFilterDropdown.vue";
import SmartFeedFilterTextInput from "SmartFeed/filters/SmartFeedFilterTextInput.vue";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";

export default {
  name: "SmartFeedV2",
  components: { SmartFeedTable, SmartFeedItem, SmartFeedPlaceholder },
  props: {
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    useCase: {
      type: String,
      required: false,
      default: () => {
        return (Math.random() + 1).toString(36).substring(7);
      },
    },
    source: {
      type: Function,
      required: true,
    },
    defaultEntitySize: {
      type: Enum,
      options: ["small", "large"],
      required: false,
      default: "large",
    },
    defaultFeedLayout: {
      type: Enum,
      options: ["list", "grid", "carousel", "table", "graph"],
      required: false,
      default: "grid",
    },
    showUserEntitySizeOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    userFeedLayoutOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    showSorts: {
      type: Boolean,
      required: false,
      default: false,
      validator(value) {
        if (value) {
          throw new SmartFeedError("prop showSorts not yet implemented");
        }
        return true;
      },
    },
    showFilters: {
      type: Boolean,
      required: false,
      default: true,
      validator(value, self) {
        if (self.filtersToExpose.length > 0 && this.showFilters === false) {
          throw new SmartFeedError("prop showFilters cannot be false when filtersToExpose is set");
        }
        return true;
      },
    },
    sortsToExpose: {
      type: Object,
      required: false,
      default: () => ({}),
      validator(value) {
        if (value.length > 0) {
          throw new SmartFeedError("prop sortsToExpose not yet implemented");
        }
        return true;
      },
    },
    filtersToExpose: {
      type: Object,
      required: false,
      default: () => [],
      validator(value) {
        value.forEach(filter => {
          if (!Array.isArray(filter)) {
            throw new SmartFeedError("filters to expose must be an array", { filter });
          }
        });
        return true;
      },
    },
    showTableColumnPicker: {
      type: Boolean,
      required: false,
      default: false,
      validator(value) {
        if (value) {
          throw new SmartFeedError("prop showTableColumnPicker not yet implemented");
        }
        return true;
      },
    },
    defaultTableColumnsToShow: {
      type: Array,
      required: false,
      default: () => [],
      validator(value) {
        if (value.length > 0) {
          throw new SmartFeedError("prop defaultTableColumnsToShow not yet implemented");
        }
        return true;
      },
    },
    tableColumnsAvailableForUserToHide: {
      type: Array,
      required: false,
      default: () => [],
      validator(value) {
        if (value.length > 0) {
          throw new SmartFeedError("prop tableColumnsAvailableForUserToHide not yet implemented");
        }
        return true;
      },
    },
    userCanReorderTableColumns: {
      type: Boolean,
      required: false,
      default: false,
      validator(value) {
        if (value) {
          throw new SmartFeedError("prop userCanReorderTableColumns not yet implemented");
        }
        return true;
      },
    },
    columnsUserCannotReorder: {
      type: Array,
      required: false,
      default: () => [],
      validator(value) {
        if (value.length > 0) {
          throw new SmartFeedError("prop columnsUserCannotReorder not yet implemented");
        }
        return true;
      },
    },
    showCarouselArrows: {
      type: Boolean,
      required: false,
      default: false,
      validator(value) {
        if (value) {
          throw new SmartFeedError("prop showCarouselArrows not yet implemented");
        }
        return true;
      },
    },
    showCarouselDots: {
      type: Boolean,
      required: false,
      default: false,
      validator(value) {
        if (value) {
          throw new SmartFeedError("prop showCarouselDots not yet implemented");
        }
        return true;
      },
    },
    snapCarouselItems: {
      type: Boolean,
      required: false,
      default: false,
      validator(value) {
        if (value) {
          throw new SmartFeedError("prop snapCarouselItems not yet implemented");
        }
        return true;
      },
    },
    tableColumnsToShow: {
      type: Array,
      required: false,
      default: () => [],
      validator(value) {
        if (value.length > 0) {
          throw new SmartFeedError("prop tableColumnsToShow not yet implemented");
        }
        return true;
      },
    },
    defaultFilterValues: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    loadMoreBehavior: {
      type: Enum,
      required: false,
      options: ["auto", "manual"],
      default: "manual",
    },
  },
  emits: ["click:item"],
  data() {
    return {
      entitySize: null,
      smartFeedLayout: null,
      smartFeedStore: null,
    };
  },
  computed: {
    iSelectLayoutChoices() {
      return this.userFeedLayoutOptions.map(userLayoutChoice => {
        return {
          id: userLayoutChoice,
          name: userLayoutChoice,
        };
      });
    },
    wrapperComponent() {
      switch (this.smartFeedLayout) {
        case SmartFeedEngine.SMART_FEED_LAYOUT_CAROUSEL:
          return "iCarousel";
        case SmartFeedEngine.SMART_FEED_LAYOUT_TABLE:
          return SmartFeedTable;
        case SmartFeedEngine.SMART_FEED_LAYOUT_GRID:
          return "iMasonry";
        case SmartFeedEngine.SMART_FEED_LAYOUT_LIST:
          return "iColumn";
        case SmartFeedEngine.SMART_FEED_LAYOUT_GRAPH:
          return SmartFeedGraph;
        default:
          return "iRow";
      }
    },
    wrapperComponentProps() {
      switch (this.smartFeedLayout) {
        case SmartFeedEngine.SMART_FEED_LAYOUT_TABLE:
          return { fields: this.smartFeedStore.tableFields };
        case SmartFeedEngine.SMART_FEED_LAYOUT_GRAPH:
          return {
            smartFeedStore: this.smartFeedStore,
            graphType: this.entityLayout,
          };
        case SmartFeedEngine.SMART_FEED_LAYOUT_GRID:
          return { align: "left" };
        case SmartFeedEngine.SMART_FEED_LAYOUT_CAROUSEL:
          return {
            hasMore: this.smartFeedStore.hasMore,
            dots: true,
            arrows: false,
            snap: true,
          };
        default:
          return {};
      }
    },
    entityLayout() {
      switch (this.smartFeedLayout) {
        case SmartFeedEngine.SMART_FEED_LAYOUT_TABLE:
          return SmartFeedEngine.ENTITY_LAYOUT_TABLE_ROW;
        case SmartFeedEngine.SMART_FEED_LAYOUT_GRAPH:
          return SmartFeedEngine.ENTITY_LAYOUT_GRAPH_LINE;
        case SmartFeedEngine.SMART_FEED_LAYOUT_CAROUSEL:
        case SmartFeedEngine.SMART_FEED_LAYOUT_GRID:
          if (this.entitySize === "small") {
            return SmartFeedEngine.ENTITY_LAYOUT_CARD_SMALL;
          }
          return SmartFeedEngine.ENTITY_LAYOUT_CARD_LARGE;
        default:
          throw new SmartFeedError("Invalid entity layout", { smartFeedLayout: this.smartFeedLayout });
      }
    },
  },
  created() {
    this.smartFeedStore = this.source(this.$pinia);
    this.smartFeedStore.setDefaultFilterValues(this.defaultFilterValues);

    if (this.smartFeedStore.feed.length === 0) {
      this.smartFeedStore.getSmartFeed();
    }
    this.entitySize = this.defaultEntitySize;
    this.smartFeedLayout = this.defaultFeedLayout;
  },
  methods: {
    getSmartFeedFilterComponent(filter) {
      const filterType = filter.ui_type;
      switch (filterType) {
        case "single_select":
          return SmartFeedFilterDropdown;
        case SmartFeedEngine.SMART_FEED_FILTER_TYPE_TEXT_INPUT:
          return SmartFeedFilterTextInput;
        default:
          throw new SmartFeedError("Unknown filter type", { filterType });
      }
    },
    getFilterProps(filter) {
      const filterType = filter.ui_type;
      switch (filterType) {
        case "single_select":
          return {
            label: filter.name,
            key: filter.key,
            items: filter.options,
          };
        case SmartFeedEngine.SMART_FEED_FILTER_TYPE_TEXT_INPUT:
          return {
            label: filter.name,
            key: filter.key,
          };
        default:
          throw new SmartFeedError("Unknown filter type", { filterType });
      }
    },
    reloadSmartFeed() {
      this.smartFeedStore.reload({ filters: this.smartFeedStore.filters });
    },
  },
};
</script>
