<template>
  <iLoadingSpinner v-if="isLoading" />
  <iImageContainer
    v-else
    class="avatar"
    :src="myAvatarUrl"
    alt="avatar"
    shape="circle"
    :width="myAvatarSize"
    :fill-background="false"
    :height="myAvatarSize"
    object-fit="cover"
  />
</template>

<script>
import { DEFAULT_USER_AVATAR } from "%/constants/user-constants";

export default {
  name: "AvatarImage",
  props: {
    size: {
      type: String,
      required: true,
      validator: value => {
        return !isNaN(value) || value.includes("px");
      },
    },
    src: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    myAvatarUrl() {
      if (this.src === "") {
        return DEFAULT_USER_AVATAR;
      }
      return this.src;
    },
    myAvatarSize() {
      if (this.size.includes("px")) {
        return parseInt(this.size.replace("px", ""));
      }
      return this.size;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
