<template>
  <PageWrapper
    :active-page="PAGE_PUBLICATION_OVERVIEW"
    :active-section="SECTION_PUBLICATIONS"
    title="My Publications"
    :breadcrumbs="publicationBreadCrumbs"
    page-name="Overview"
    align="left"
    width="full"
    action-button-text="Create New Publication"
    @click:action="navigateToCreatePublication"
  >
    <iColumn v-if="myPublications?.length === 0">
      <iSpace :height="1" />
      <iText variant="subtle">
        No Publications Found. Create a new publication to get started.
      </iText>
    </iColumn>
    <iSubHeading>Draft Publications</iSubHeading>
    <SmartFeed
      :pinia-store="useDraftPublicationFeedStore"
      default-smart-feed-layout="carousel"
      default-entity-layout="cardSmall"
    >
      <template #empty-state>
        <iText variant="subtle">
          You have no draft publications
        </iText>
      </template>
    </SmartFeed>

    <iSubHeading>Active Publications</iSubHeading>
    <SmartFeed
      :pinia-store="usePublicationFeedStore"
      default-smart-feed-layout="carousel"
      default-entity-layout="cardSmall"
    >
      <template #empty-state>
        <iText variant="subtle">
          You have no active publications
        </iText>
      </template>
    </SmartFeed>

    <iSubHeading>Offline Publications</iSubHeading>
    <SmartFeed
      :pinia-store="useOfflinePublicationFeedStore"
      default-smart-feed-layout="carousel"
      default-entity-layout="cardSmall"
    >
      <template #empty-state>
        <iText variant="subtle">
          You have no offline publications
        </iText>
      </template>
    </SmartFeed>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_PUBLICATION_OVERVIEW,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import { mapState } from "pinia";

import SmartFeed from "SmartFeed/components/SmartFeed";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import { useDraftPublicationFeedStore } from "SmartFeed/stores/feed/draft-publication-feed-store";
import { useOfflinePublicationFeedStore } from "SmartFeed/stores/feed/offline-publication-feed-store";
import { usePublicationFeedStore } from "SmartFeed/stores/feed/publication-feed-store";

export default {
  name: "PublicationOverview",
  components: { SmartFeed, PageWrapper },
  data() {
    return {
      PAGE_PUBLICATION_OVERVIEW,
      SECTION_PUBLICATIONS,
    };
  },
  computed: {
    ...mapState(useCreatorPublicationStore, ["orgId"]),
    ...mapState(useCreatorPublicationStore, ["myPublications"]),
    publicationBreadCrumbs() {
      return [{ url: "/", label: "Home" }, { url: `/publication/org/${this.orgId}/overview`, label: "My Publications" }];
    },
  },
  methods: {
    usePublicationFeedStore,
    useDraftPublicationFeedStore,
    useOfflinePublicationFeedStore,
    navigateToCreatePublication() {
      window.location = `/publication/org/${this.orgId}/create`;
    },
  },
};
</script>
