import { defineStore } from "pinia";

export const useGeneralStore = defineStore("general-store", {
  state: () => ({
    appId: 1,
    links: [],
    test: 0,
  }),
  getters: {
    getLink: state => linkName => {
      return state.links[linkName];
    },
  },
  actions: {
    setLinks(links) {
      this.links = links;
    },
  },
});
