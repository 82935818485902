import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { useAffiliateLinkStore } from "%/stores/affiliate-link-store";

export const useAffiliateLinkCollectionFeedStore = defineSmartFeedPiniaStore("affiliate-link-collection-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    metadata: {
      affiliateLinkCollectionId: useAffiliateLinkStore().affiliateLinkCollectionId,
    },
    tableFields: [
      {
        key: "affiliateLink",
        label: "Affiliate Link",
        width: "320px",
      },
      {
        key: "originalLink",
        label: "Original Link",
        width: "320px",
      },
      {
        key: "advertiserName",
        label: "Advertiser",
        width: "200px",
      },
      {
        key: "myDescription",
        label: "My Description",
        width: "300px",
      },
      {
        key: "notes",
        label: "Notes",
        width: "300px",
      },
      {
        key: "brandName",
        label: "Brand Name",
        width: "200px",
      },
      {
        key: "productName",
        label: "Product Name",
        width: "200px",
      },
      {
        key: "clicks",
        label: "Clicks",
      },
      {
        key: "sales",
        label: "Sales",
      },
      {
        key: "totalEarnings",
        label: "Total Earnings",
      },
      {
        key: "pendingEarnings",
        label: "Pending Earnings",
      },
      {
        key: "lockedEarnings",
        label: "Locked Earnings",

      },
      {
        key: "actions",
        label: "",
      },
    ],
  }),
  getters: {
    feedUrl() {
      return `/affiliate-link-collection/${this.metadata.affiliateLinkCollectionId}/smartfeed`;
    },
  },
});
