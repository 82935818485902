<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_FEATURES"
    title="Publication Features"
    :breadcrumbs="breadcrumbs"
    page-name="Features"
    align="left"
    width="full"
  >
    <PublicationDomainConfiguration />
  </PageWrapper>
</template>

<script>
import { mapState } from "pinia";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_PUBLICATION_FEATURES,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import PublicationDomainConfiguration from "@/components/publication/features/PublicationDomainConfiguration.vue";
export default {
  name: "PublicationFeatures",
  components: {
    PageWrapper,
    PublicationDomainConfiguration,
  },
  data() {
    return {
      PAGE_PUBLICATION_FEATURES,
      SECTION_PUBLICATIONS,
    };
  },
  computed: {
    ...mapState(useCreatorPublicationStore, ["orgId", "publicationId"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
        { url: `/publication/${this.publicationId}/settings`, label: "Settings" },
      ];
    },
  },
};
</script>

<style scoped>
</style>
