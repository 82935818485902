<template>
  <iColumn>
    <iLoadingSpinner v-if="loadingMessages" />
    <template
      v-else-if="messages.length !== 0"
    >
      <iRow
        v-for="(message, index) in messages"
        :key="index"
        gap="extraSmall"
      >
        <iColumn class="comment-container">
          <ActiveCommentWidget
            :replies="message.replies ? message.replies : []"
            :message-id="message.messageId"
            :conversation-id="message.conversationId"
            :author-id="message.userId"
            :author-avatar="message.avatarUrl"
            :message="message.message"
            :vote-score="message.voteScore"
            :date-sent="message.timestampCreated"
            :author-name="message.authorName"
            :moderation-status="message.moderationStatus"
            :num-approved-replies="message.numApprovedReplies"
            :num-likes="message.numLikes"
            :num-reports="message.numReports"
            :moderation-score="message.moderationScore"
            :status="message.status"
            :date-updated="message.timestampUpdated"
            :is-deleted="false"
            :user-liked="message.userLiked"
            :user-up-voted="message.userUpVoted"
            :user-down-voted="message.userDownVoted"
          />
        </iColumn>
      </iRow>
    </template>
    <template v-else>
      <iColumn>
        <iText>No comments yet</iText>
      </iColumn>
    </template>
  </iColumn>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useConversationStore } from "%/stores/conversation-store";
import ActiveCommentWidget from "%/components/community/chatrooms/comments/ActiveCommentWidget";

export default {
  name: "CommentThread",
  components: {
    ActiveCommentWidget,
  },
  props: {
    context: {
      type: String,
      required: false,
      default: "comment",
    },
    entityId: {
      type: Number,
      required: false,
      default: 0,
    },
    conversationId: {
      type: Number,
      required: false,
      default: 0,
    },
    canLikeComments: {
      type: Boolean,
      required: false,
      default: false,
    },
    canReplyToComments: {
      type: Boolean,
      required: false,
      default: false,
    },
    canVoteOnComments: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      newComment: "",
      sortingOptions: [
        { name: "by_date_sent", value: "by_date_sent" },
        { name: "by_score", value: "by_score" },
      ],
      currentSortingOption: "by_date_sent",
    };
  },
  computed: {
    ...mapState(useConversationStore, ["messages", "sortStrategy", "loadingMessages"]),
  },
  mounted() {
    this.getConversationByEntity(this.context, this.entityId);
  },
  methods: {
    ...mapActions(useConversationStore, ["getConversationByEntity"]),
  },
};
</script>

<style scoped lang="scss">
.comment-container {
  padding: 12px;

  &:hover {
    background-color: #f5f5f5;
    border-radius: 8px;
    transition: 0.3s;
  }
}
</style>
