<template>
  <PageWrapper
    :active-page="PAGE_PUBLICATION_SUPPORT"
    :active-section="SECTION_PUBLICATIONS"
    :breadcrumbs="breadcrumbs"
    page-name="Support"
    align="center"
    width="full"
  >
    <iRow width="fill" height="fill" wrap="nowrap">
      <iColumn
        :width="200"
        height="fill"
        wrap="nowrap"
        class="support-chat-list"
      >
        <iRow
          v-for="supportConversation in supportConversations"
          :key="supportConversation.supportConversationId"
        >
          <iLink
            width="fill"
            :href="`/publication/${publicationId}/support/${supportConversation.supportConversationId}`"
          >
            <iRow
              :class="{
                'chat-list-item': true,
                'active': conversationId === parseInt(supportConversation.conversationId)
              }"
            >
              {{ supportConversation.username }}
            </iRow>
          </iLink>
        </iRow>
      </iColumn>
      <iColumn
        v-if="!conversationId"
        width="fill"
        height="fill"
        vertical-align="middle"
      >
        <iTitle variant="subtle">
          Click on a chat to view the conversation
        </iTitle>
      </iColumn>
      <Chatroom
        v-else
        context="contact_us"
        :entity-id="publicationId"
        :conversation-id="conversationId"
        :has-chat-room-header="false"
      />
    </iRow>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper.vue";
import {
  PAGE_PUBLICATION_SUPPORT,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import { mapState, mapActions } from "pinia";
import {
  useMyPublicationSupportConversationFeedStore,
} from "SmartFeed/stores/feed/my-publication-support-conversation-feed-store";
import { useSupportConversationEntityStore } from "SmartFeed/stores/entity/support-conversation-entity-store";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import { useConversationStore } from "%/stores/conversation-store";
import Chatroom from "%/components/community/chatrooms/slack-style/Chatroom.vue";

export default {
  name: "PublicationSupport",
  components: {
    PageWrapper,
    Chatroom,
  },
  props: {
    publicationId: {
      type: Number,
      required: true,
    },
    conversationId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      SECTION_PUBLICATIONS,
      PAGE_PUBLICATION_SUPPORT,
    };
  },
  computed: {
    ...mapState(useCreatorPublicationStore, ["publication", "orgId"]),
    ...mapState(useSupportConversationEntityStore, { supportConversationEntities: "entities" }),
    ...mapState(useMyPublicationSupportConversationFeedStore, { publicationSupportConversationHasMore: "hasMore", publicationSupportConversationFeed: "feed" }),
    supportConversations() {
      const entities = {};
      this.publicationSupportConversationFeed.forEach(supportConversation => {
        let supportConversationIds = Object.keys(this.supportConversationEntities);
        if (supportConversationIds.includes(supportConversation.id.toString())) {
          entities[supportConversation.id] = this.supportConversationEntities[supportConversation.id];
        }
      });
      return Object.values(entities);
    },
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: this.publication.name },
      ];
    },
  },
  mounted() {
    useMyPublicationSupportConversationFeedStore().getSmartFeed();
  },
  methods: {
    ...mapActions(useConversationStore, ["fetchSupportConversations"]),
    useMyPublicationSupportConversationFeedStore,
  },
  styleGuide: () => ({
    chatListBorderRightWidth: { "size.border": "thin" },
    chatListBorderRightColor: { "color.border": "dark" },
    activeItemBackgroundColor: { "color.background": "dark" },
    itemHoverBackgroundColor: { "color.background": "light" },
    itemPadding: { "size.spacing": "small" },
    activeItemFontColor: { "color.font": "light" },
    itemBorderRadius: { "size.borderRadius": "large" },
  }),
};
</script>
<style lang="scss" scoped>
  .support-chat-list{
    border-right: v-bind("$getStyles.chatListBorderRightWidth") solid v-bind("$getStyles.chatListBorderRightColor") !important;
  }

  .chat-list-item.active {
    background: v-bind('$getStyles.activeItemBackgroundColor');

    :deep(svg) {
      fill: v-bind('$getStyles.activeItemFontColor');
    }
  }

  .active {
    color: v-bind('$getStyles.activeItemFontColor');
  }

  .chat-list-item {
    border-radius: v-bind('$getStyles.itemBorderRadius');
    padding: v-bind('$getStyles.itemPadding');
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover:not(.active) {
      background: v-bind('$getStyles.itemHoverBackgroundColor');
    }
  }
</style>
