import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";

export const useCommunityPublicationCollaborationAdFeedStore = defineSmartFeedPiniaStore("community-publication-collaboration-ad-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    feedUrl: "/publication-ads/community/smart-feed",
  }),
  getters: {
    smartFeedRequestGetParams() {
      return {
        status: "active",
      };
    },
  },
});
