<template>
  <iRow>
    <iButton :is-loading="isLoading" size="small" @click="brandAction">
      {{ buttonTitle }}
    </iButton>
  </iRow>
</template>
<script>
import { mapActions, mapState } from "pinia";
import { myFavoriteAdvertiserCollectionStore } from "@/stores/my-favorite-advertiser-collection-store";
export default {
  name: "MyBrandsButton",
  props: {
    advertiserOrgId: {
      type: Number,
      required: true,
    },
    creatorOrgId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState(myFavoriteAdvertiserCollectionStore, [
      "isLoading",
      "collection",
      "currentCollectionItemId",
    ]),
    buttonTitle() {
      return !this.currentCollectionItemId ? "Add to My Brands" : "Remove from My Brands";
    },
  },
  created() {
    this.getCollection(this.creatorOrgId, this.advertiserOrgId);
  },
  methods: {
    ...mapActions(myFavoriteAdvertiserCollectionStore, [
      "getCollection",
      "createCollectionItem",
      "removeCollectionItem",
    ]),
    brandAction() {
      if (this.currentCollectionItemId) {
        this.removeCollectionItem(this.advertiserOrgId);
      } else {
        this.createCollectionItem(this.creatorOrgId, this.advertiserOrgId);
      }
    },
  },
};
</script>
