<template>
  <iRow
    vertical-align="middle"
    wrap="nowrap"
  >
    <iEditor
      focus
      variant="slack"
      placeholder="Type a reply..."
      :upload-post-image="uploadMessageImage"
      @input="replyMessage = $event"
      @submit="addComment"
    />
  </iRow>
</template>

<script>
import { mapState, mapActions, mapWritableState } from "pinia";
import { useConversationStore } from "%/stores/conversation-store";
import { useGeneralStore } from "%/stores/general-store";
import { useWindowStore } from "@bloglovin/vue-component-library";

export default {
  name: "NewReply",
  props: {
    context: {
      type: String,
      required: false,
      default: "",
    },
    entityId: {
      type: Number,
      required: false,
      default: 0,
    },
    conversationId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      replyMessage: "",
    };
  },
  computed: {
    ...mapWritableState(useConversationStore, ["draftMessage"]),
    ...mapState(useConversationStore, [
      "isLoading",
    ]),
    ...mapState(useGeneralStore, ["userData", "userAvatar", "isLoggedIn"]),
    ...mapState(useWindowStore, ["$vssMobile"]),
  },
  created() {
    useWindowStore().init();
  },
  beforeUnmount() {
    useWindowStore().remove();
  },
  methods: {
    ...mapActions(useConversationStore, ["replyToMessage", "uploadMessageImage"]),
    addComment() {
      this.replyToMessage(this.entityId, this.replyMessage);
      this.replyMessage = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.send-btn{
  position: absolute;
  right: 12px;
  top:0;
  bottom: 0;
  margin-block: auto;
}

.message-box-container {
  position: relative;
}

.i-editor :deep(input){
  padding-right: 36px;
}
</style>
