<template>
  <PageWrapper
    :active-section="SECTION_MONETIZATION"
    :active-page="PAGE_MONETIZATION_SPONSORED_CONTENT"
    page-name="Sponsored Content"
    align="left"
    width="fixed"
  >
    <iRow align="between" vertical-align="middle">
      <iHeading>Sponsored Content</iHeading>
      <iLink variant="tertiary" href="javascript:void(0)">
        Change Date Range
      </iLink>
    </iRow>

    <iDataTable
      :fields="sponsoredContentFields"
      :items="sponsoredContentItems"
      primary-key="campaignName"
      :has-more="false"
      :is-loading="false"
      hide-actions
    >
      <template #td="{data, value}">
        <iText :font-weight="data.campaignName === 'Total' ? 'dark' : 'standard'">
          {{ value }}
        </iText>
      </template>
    </iDataTable>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { SECTION_MONETIZATION, PAGE_MONETIZATION_SPONSORED_CONTENT } from "@/constants/nav-constants";
import { sponsoredContentFields, sponsoredContentItems } from "@/constants/monetization-fake-data";

export default {
  name: "SponsoredContent",
  components: {
    PageWrapper,
  },
  data() {
    return {
      SECTION_MONETIZATION,
      PAGE_MONETIZATION_SPONSORED_CONTENT,
      sponsoredContentFields,
      sponsoredContentItems,
    };
  },
};
</script>

<style scoped></style>
