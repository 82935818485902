<template>
  <iModal
    v-if="showModal"
    :max-width="600"
    title="Add User"
    primary-action-button-label="Add user"
    :primary-action-button-loading="loadingCreateUser"
    @close="onClose"
    @click:primary="addUser"
  >
    <template #body>
      <iColumn gap="large">
        <iColumn gap="none">
          <iTextInput
            v-model="userEmail"
            label="Email address:"
            name="email"
            type="email"
            width="fill"
            focus
          />
          <iText v-if="validationErrors.userEmail" variant="error">
            {{ validationErrors.userEmail }}
          </iText>
        </iColumn>

        <iColumn gap="none">
          <iTextInput
            v-model="userFirstName"
            label="First Name:"
            name="first_name"
            width="fill"
          />
          <iText v-if="validationErrors.userFirstName" variant="error">
            {{ validationErrors.userFirstName }}
          </iText>
        </iColumn>

        <iColumn gap="none">
          <iTextInput
            v-model="userLastName"
            label="Last Name:"
            name="last_name"
            width="fill"
          />
          <iText v-if="validationErrors.userLastName" variant="error">
            {{ validationErrors.userLastName }}
          </iText>
        </iColumn>

        <iColumn gap="none">
          <iSelect
            v-model="userRole"
            :items="userRoleSelectOptions"
            empty-string="Select Role"
            label="Role:"
            text-field="label"
            value-field="value"
            width="fill"
          />
          <iText v-if="validationErrors.userRole" variant="error">
            {{ validationErrors.userRole }}
          </iText>
        </iColumn>

        <iText v-if="error" variant="error">
          {{ error }}
        </iText>
        <iText v-if="addUserErrorMessage" variant="error">
          {{ addUserErrorMessage }}
        </iText>
      </iColumn>
    </template>
  </iModal>
</template>

<script>
import { ALLOWED_ADDITIONAL_USER_ROLES } from "%/constants/user-constants";
import { mapActions, mapState, mapWritableState } from "pinia";
import { useOrganizationStore } from "@/stores/organization-store";
import { validateEmail } from "@bloglovin/vue-component-library";

export default {
  name: "AddUserToOrgModal",
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close"],
  data() {
    return {
      userEmail: "",
      userFirstName: "",
      userLastName: "",
      userRole: {},
      validationErrors: {
        userEmail: "",
        userFirstName: "",
        userLastName: "",
        userRole: "",
      },
    };
  },
  computed: {
    ...mapState(useOrganizationStore, [
      "loadingCreateUser",
      "addUserErrorMessage",
    ]),
    ...mapWritableState(useOrganizationStore, ["error"]),
    userRoleSelectOptions() {
      return Object.entries(ALLOWED_ADDITIONAL_USER_ROLES).map(userRole => {
        return {
          "label": userRole[1],
          "value": userRole[0],
        };
      });
    },
  },
  methods: {
    ...mapActions(useOrganizationStore, [
      "createUserInOrg",
    ]),
    onClose() {
      this.resetForm();
      this.$emit("close");
    },
    resetForm() {
      this.userEmail = "";
      this.userFirstName = "";
      this.userLastName = "";
      this.userRole = {};
      this.validationErrors = {
        userEmail: "",
        userFirstName: "",
        userLastName: "",
        userRole: "",
      };
      this.error = "";
    },
    validateForm() {
      let isValid = true;
      this.validationErrors = {
        userEmail: "",
        userFirstName: "",
        userLastName: "",
        userRole: "",
      };

      if (!this.userEmail) {
        this.validationErrors.userEmail = "Email is required";
        isValid = false;
      } else if (!validateEmail(this.userEmail)) {
        this.validationErrors.userEmail = "Please enter a valid email address";
        isValid = false;
      }

      if (!this.userFirstName) {
        this.validationErrors.userFirstName = "First name is required";
        isValid = false;
      }

      if (!this.userLastName) {
        this.validationErrors.userLastName = "Last name is required";
        isValid = false;
      }

      if (!this.userRole.value) {
        this.validationErrors.userRole = "Please select a role";
        isValid = false;
      }

      return isValid;
    },
    addUser() {
      if (this.validateForm()) {
        this.createUserInOrg(
          this.userEmail,
          this.userRole.value,
          this.userFirstName,
          this.userLastName,
        ).then(success => {
          if (success) {
            this.onClose();
          }
        });
      }
    },
  },
};
</script>
