<template>
  <iColumn vertical-align="middle" gap="none">
    <iRow
      v-if="reactions.length > 0"
      :disabled="!isActive"
      width="fill"
      wrap="nowrap"
    >
      <iReactionViewer :reactions="reactionsWithUserData" @reaction-selected="$emit('reaction-selected', $event)" />
    </iRow>
    <iRow
      v-if="allowReplying && numReplies > 0"
      :disabled="!isActive"
      class="reply-btn"
      gap="extraSmall"
      variant="tertiary"
      wrap="nowrap"
      @click="reply"
    >
      <iRow :variant="isActive? 'standard' : 'subtle'" vertical-align="middle">
        <iImageContainer
          :alt="latestReply.authorName"
          :height="20"
          :src="latestReply.avatarUrl || DEFAULT_USER_AVATAR"
          :width="20"
          class="user-avatar"
          object-fit="cover"
          shape="rectangle"
        />
        <iLink font-size="small">
          {{ getReplies }}
        </iLink>
        <iText class="reply-time" font-size="small" variant="subtle">
          {{ latestTimestampCreated }}
        </iText>
      </iRow>
    </iRow>
  </iColumn>
</template>

<script>
import { mapState } from "pinia";
import { useConversationStore } from "%/stores/conversation-store";
import { OFFENSE_OPTIONS } from "%/constants/conversations-constants";
import { useGeneralStore } from "%/stores/general-store";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { DEFAULT_USER_AVATAR } from "%/constants/user-constants";

dayjs.extend(relativeTime);

export default {
  name: "ConversationFooter",
  props: {
    numReplies: {
      type: Number,
      required: false,
      default: "0",
    },
    authorId: {
      type: Number,
      required: true,
    },
    messageId: {
      type: Number,
      required: true,
    },
    allowReplying: {
      type: Boolean,
      required: false,
      default: true,
    },
    status: {
      type: String,
      required: true,
    },
    reactions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  emits: [
    "reply",
    "reaction-selected",
  ],
  data() {
    return {
      DEFAULT_USER_AVATAR,
      OFFENSE_OPTIONS,
      isSharing: false,
      editMessage: "",
      hasMoreReactions: false,
    };
  },
  computed: {
    ...mapState(useConversationStore, [
      "isLoading",
      "isModerator",
      "moderationEnabled",
      "isMessageLoading",
      "allMessages",
    ]),
    reactionsWithUserData() {
      return this.reactions.map(reaction => {
        return {
          ...reaction,
          current_user_reacted: this.userData.user_id === reaction.user_id,
        };
      });
    },
    ...mapState(useGeneralStore, ["userData"]),
    isAuthor() {
      return this.userData.user_id === this.authorId;
    },
    getReplies() {
      switch (this.numReplies) {
        case 0:
          return "Reply";
        case 1:
          return "1 Reply";
        default:
          return `${this.numReplies} Replies`;
      }
    },
    activeMessageReplies() {
      return this.allMessages[this.messageId].replies ?? [];
    },
    latestReply() {
      if (this.activeMessageReplies.length > 0) {
        const firstReply = this.activeMessageReplies[this.activeMessageReplies.length - 1];
        const lastReply = this.activeMessageReplies[0];

        if (firstReply.timestampCreated > lastReply.timestampCreated) {
          return firstReply;
        }
        return lastReply;
      }
      return {};
    },
    latestTimestampCreated() {
      if (this.latestReply.timestampCreated) {
        return dayjs.unix(this.latestReply.timestampCreated).fromNow();
      }
      return "";
    },
    isActive() {
      return this.status === "active";
    },
  },
  methods: {
    reply() {
      this.$emit("reply");
    },
  },
  styleGuide: () => ({
    borderRadius: { "size.borderRadius": "standard" },
  }),
};
</script>
<style lang="scss" scoped>
.reply-btn {
  cursor: pointer;
}

:deep(.user-avatar img) {
  border-radius: v-bind("$getStyles.borderRadius");
}

.reply-time {
  position: relative;
  top: 1px;
}
</style>
