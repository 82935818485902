import { defineStore } from "pinia";
import { useStoryCommentEntityStore } from "SmartFeed/stores/entity/story-comment-entity-store";

export const useFanStoryStore = defineStore("fan-story-store", {
  state: () => ({
    storyId: 0,
    stories: {},
    loadingFetchStory: false,
    loadingFetchMessage: {},
    messages: [],
    // TODO: this is not the best way to do this, will revisit this
    isExclusive: false,
  }),
  actions: {
    fetchStory(storyId) {
      if (this.loadingFetchStory) {
        return;
      }
      this.loadingFetchStory = true;
      return this.apiGet(
        "/story",
        { story_id: storyId },
        this.setStory,
        () => {
        },
        () => {
          this.loadingFetchStory = false;
        },
      );

    },
    setStory(story) {
      this.stories[story.storyId] = story;
    },

    fetchMessage(messageId) {
      let comments = useStoryCommentEntityStore().entities;
      return comments[messageId];
    },
  },
});
