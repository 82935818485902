import "@bloglovin/vue-component-library/style.css";
import "@/styles/style.scss";
import { createPinia } from "pinia";
import { init, axiosInit, AxiosPiniaPlugin, InjectedDataPlugin, loadFonts } from "@bloglovin/vue-framework";
import pages from "@/pages";
import { useGeneralStore } from "%/stores/general-store";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import { usePaymentStore } from "%/stores/payments-store";
import { useSocialNetworksStore } from "@/stores/social-networks-store";
import VCL, { useStyleStore } from "@bloglovin/vue-component-library";
import basicTheme from "%/themes/basic-theme";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { useFeatureStore } from "%/stores/feature-store";
import { useConfigurationStore } from "%/stores/configuration-store";
import { useUserPreferenceStore } from "@/stores/user-preference-store";

axiosInit(window.blclient.data.JWT, window.blclient.data.links.api);

const initializationFunction = async function(vuePlugins, piniaDataToBeInjected) {

  const piniaInstance = vuePlugins.pinia;
  for (const storeOrEngine of Object.keys(piniaDataToBeInjected)) {

    if (storeOrEngine.startsWith("use")) {
      let piniaStoreFileName = storeOrEngine.replace(/^use/, "").replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
      try {
        await import(`@/stores/${piniaStoreFileName}`).then(resolvedStoreFile => {
          const storeInstance = resolvedStoreFile[storeOrEngine](piniaInstance);
          storeInstance.$injectCustomProperties(piniaDataToBeInjected[storeOrEngine]);
        });
      } catch (error) {
        await import(`%/stores/${piniaStoreFileName}`).then(resolvedStoreFile => {
          const storeInstance = resolvedStoreFile[storeOrEngine](piniaInstance);
          storeInstance.$injectCustomProperties(piniaDataToBeInjected[storeOrEngine]);
        });
      }
    } else if (storeOrEngine.endsWith("SmartFeedEngine")) {
      const smartFeedEngine = new SmartFeedEngine();

      for (const piniaStore of Object.keys(piniaDataToBeInjected[storeOrEngine])) {
        let piniaStoreFileName = piniaStore.replace(/^use/, "").replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
        await import(`SmartFeed/stores/feed/${piniaStoreFileName}`).then(resolvedStoreFile => {
          const storeInstance = resolvedStoreFile[piniaStore](piniaInstance);
          storeInstance.handleSmartFeedResponse(piniaDataToBeInjected[storeOrEngine][piniaStore], smartFeedEngine);
        });
      }
    }
  }

  const styleStore = useStyleStore(piniaInstance);
  styleStore.setTheme(basicTheme, styleStore.DEFAULT_THEME_ID);

  const generalStore = useGeneralStore(piniaInstance);
  generalStore.setAppId(parseInt(window.blclient.data.appId));
  generalStore.setLinks(window.blclient.data.links);
  generalStore.setBaseDomain(window.blclient.data.baseDomain);

  const socialStore = useSocialNetworksStore(piniaInstance);

  if (window.blclient.data.pageProps["social_networks"]) {
    socialStore.setSocialNetworks(window.blclient.data.pageProps["social_networks"]);
  }

  const publicationStore = useCreatorPublicationStore(piniaInstance);
  const configurationStore = useConfigurationStore(piniaInstance);
  const userPreferenceStore = useUserPreferenceStore(piniaInstance);

  if (window.blclient.data.publicationData) {
    configurationStore.setPublicationId(window.blclient.data.publicationData.publicationId);
    configurationStore.setPublicationStagingVersionId(window.blclient.data.publicationData.publication.staging_version_id);
  }

  if (window.blclient.data.userId) {
    generalStore.setUserData(window.blclient.data.userData);
    generalStore.setUserId(window.blclient.data.userData.user_id);
    generalStore.setOrgData(window.blclient.data.orgData);
    if (window.blclient.data.adminUserId) {
      generalStore.setAdminUserId(window.blclient.data.adminUserId);
      generalStore.setAdminUserData(window.blclient.data.adminUserData);
    }
    const paymentStore = usePaymentStore(piniaInstance);
    paymentStore.setDigitalWalletData(window.blclient.data.digitalWallet);
  }
  if (window.blclient.data.publicationData) {
    publicationStore.setPublication(window.blclient.data.publicationData);
  }
  // if (window.blclient.data.configurationData) {
  //   configurationStore.setConfiguration(window.blclient.data.configurationData);
  // }
  if (window.blclient.data.myPublicationsData) {
    publicationStore.setMyPublications(window.blclient.data.myPublicationsData);
  }

  if (window.blclient.data.userPreferences) {
    userPreferenceStore.setUserPreferences(window.blclient.data.userPreferences);
  }

  useFeatureStore().updateFeatureConfig(window.blclient.data.featureConfig);
};

const pinia = createPinia();
pinia.use(AxiosPiniaPlugin);
pinia.use(InjectedDataPlugin);

const plugins = {
  pinia: pinia,
  vcl: VCL,
};

const alegreyaSans = loadFonts("Alegreya Sans", [
  "url(https://cdn-alpha.inspiration.co/fonts/AlegreyaSansNormalLatin400.woff2)",
]);

const nunito = loadFonts("Nunito", [
  "url(https://cdn-alpha.inspiration.co/fonts/NunitoItalicCyrillicExt.woff2)",
  "url(https://cdn-alpha.inspiration.co/fonts/NunitoItalicCyrillic.woff2)",
  "url(https://cdn-alpha.inspiration.co/fonts/NunitoItalicVietnamese.woff2)",
  "url(https://cdn-alpha.inspiration.co/fonts/NunitoItalicLatinExt.woff2)",
  "url(https://cdn-alpha.inspiration.co/fonts/NunitoItalicLatin.woff2)",
  "url(https://cdn-alpha.inspiration.co/fonts/NunitoNormalCyrillicExt.woff2)",
  "url(https://cdn-alpha.inspiration.co/fonts/NunitoNormalCyrillic.woff2)",
  "url(https://cdn-alpha.inspiration.co/fonts/NunitoNormalVietnamese.woff2)",
  "url(https://cdn-alpha.inspiration.co/fonts/NunitoNormalLatinExt.woff2)",
  "url(https://cdn-alpha.inspiration.co/fonts/NunitoNormalLatin.woff2)",
]);

await Promise.all([alegreyaSans, nunito]);


init(process.env.NODE_ENV, process.env.PROJECT_NAME, pages, plugins, initializationFunction, {}, false);
