<template>
  <iColumn
    class="chatroom-thread-container"
    gap="none"
    height="fill"
    wrap="nowrap"
  >
    <iRow
      v-if="hasChatRoomHeader"
      align="between"
      class="chatroom-header"
      vertical-align="middle"
      width="fill"
      wrap="nowrap"
    >
      <iRow vertical-align="middle" width="hug" wrap="nowrap">
        <iRow vertical-align="middle" width="hug" wrap="nowrap">
          <iIcon v-if="channelVisibility" :icon="channelIcon" size="standard" />
          <iHeading> {{ channelName }}</iHeading>
        </iRow>
        <iColumn overflow="hidden" width="hug">
          <iText variant="subtle" class="header-description">
            {{ description }}
          </iText>
        </iColumn>
      </iRow>
      <iRow vertical-align="middle" width="hug" wrap="nowrap">
        <iText class="member-count" :wrap="false" @click="openChannelMembersModal">
          {{ memberCountText }}
        </iText>
        <iDropdownMenu
          :items="channelDropdownItems"
          :show-dropdown="isChannelDropdownOpen"
          position="right"
          @click="handleChannelDropdownAction"
          @close="closeChannelDropdown"
        >
          <template #trigger>
            <iRow vertical-align="middle" wrap="nowrap">
              <iIcon icon="dots-vertical" @click="isChannelDropdownOpen = !isChannelDropdownOpen" />
            </iRow>
          </template>
        </iDropdownMenu>
      </iRow>
    </iRow>
    <iRow v-if="isMounted" height="fill" overflow="scroll">
      <ChatroomThread
        :channel-href="channelHref"
        :channel-name="channelName"
        :context="context"
        :conversation-id="conversationId"
        :description="description"
        :entity-id="entityId"
        :visibility="channelVisibility"
        :show-deleted-messages="showDeletedMessages"
      />
    </iRow>
    <ChatroomUserActions
      v-if="hasChatRoomActions"
      :channel-href="channelHref"
      :channel-name="channelName"
      :context="context"
      :conversation-id="conversationId"
      :description="description"
      :entity-id="entityId"
    />
  </iColumn>
</template>

<script>
import ChatroomThread from "%/components/community/chatrooms/slack-style/ChatroomThread";
import { useChannelStore } from "%/stores/channel-store";
import { mapActions, mapState, mapWritableState } from "pinia";
import { useConversationStore } from "%/stores/conversation-store";
import { MenuConstants } from "@bloglovin/vue-component-library";
import ChatroomUserActions from "%/components/community/chatrooms/slack-style/ChatroomUserActions.vue";
import { useGeneralStore } from "%/stores/general-store";

export default {
  name: "Chatroom",
  components: {
    ChatroomUserActions,
    ChatroomThread,
  },
  props: {
    context: {
      type: String,
      required: true,
    },
    entityId: {
      type: Number,
      required: true,
    },
    conversationId: {
      type: Number,
      required: false,
      default: 0,
    },
    channelName: {
      type: String,
      required: false,
      default: "",
    },
    description: {
      type: String,
      required: false,
      default: "",
    },
    channelHref: {
      type: String,
      required: false,
      default: "#",
    },
    userCount: {
      type: Number,
      required: false,
      default: 0,
    },
    hasChatRoomActions: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasChatRoomHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
    showDeletedMessages: {
      type: Boolean,
      required: false,
      default: false,
    },
    unauthorizedViewing: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      isChannelDropdownOpen: false,
      isMounted: false,
      channelDropdownItems: [
        {
          name: "Details",
          label: "Details",
          actionType: MenuConstants.ACTION_TYPE_BUTTON_CLICK,
          actionValue: "openChannelDetailsModal",
        },
        {
          name: "Members",
          label: "Members",
          actionType: MenuConstants.ACTION_TYPE_BUTTON_CLICK,
          actionValue: "openChannelMembersModal",
        },
        {
          name: "Settings",
          label: "Settings",
          actionType: MenuConstants.ACTION_TYPE_BUTTON_CLICK,
          actionValue: "openChannelSettingsModal",
        },
      ],
    };
  },
  computed: {
    ...mapState(useConversationStore, ["conversation"]),
    ...mapState(useGeneralStore, ["isLoggedIn"]),
    ...mapState(useChannelStore, ["channelId", "isChannelUser", "channelVisibility"]),
    ...mapWritableState(useConversationStore, [
      "showMembersChannelModal",
      "showDetailsChannelModal",
      "showSettingsChannelModal",
    ]),
    memberCountText() {
      return `${this.userCount} ${this.userCount === 1 ? "member" : "members"}`;
    },
    channelIcon() {
      return this.channelVisibility.includes("invite_only") ? "lock" : "hashtag";
    },
  },
  mounted() {
    if (this.unauthorizedViewing && !this.isLoggedIn) {
      window.location.href = this.getRelativeHref("/login");
    }
    this.isMounted = true;
  },
  methods: {
    ...mapActions(useGeneralStore, ["getRelativeHref"]),
    ...mapActions(useChannelStore, ["fetchChannelCreatorDetails"]),
    openChannelMembersModal() {
      this.showMembersChannelModal = true;
    },
    openChannelDetailsModal() {
      this.showDetailsChannelModal = true;
      this.fetchChannelCreatorDetails();
    },
    openChannelSettingsModal() {
      this.showSettingsChannelModal = true;
    },
    handleChannelDropdownAction(action) {
      this[action]();
      this.isChannelDropdownOpen = false;
    },
    closeChannelDropdown() {
      this.isChannelDropdownOpen = false;
    },
  },
  styleGuide: () => ({
    borderTopWidth: { "size.border": "thin" },
    borderTopColor: { "color.border": "standard" },
    chatroomHeaderBorderBottomSize: { "size.border": "thin" },
    chatroomHeaderBorderBottomColor: { "color.border": "standard" },
    chatroomHeaderPaddingTop: { "size.spacing": "none" },
    chatroomHeaderPaddingBottom: { "size.spacing": "standard" },
    chatroomHeaderPaddingLeft: { "size.spacing": "none" },
    chatroomHeaderPaddingRight: { "size.spacing": "none" },
  }),
};
</script>

<style lang="scss" scoped>
.chatroom {
  width: 100%;
}
.header-description{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}

.member-count:hover {
  text-decoration: underline;
  cursor: pointer;
}

.chatroom-header {
  padding-top: v-bind("$getStyles.chatroomHeaderPaddingTop");
  padding-bottom: v-bind("$getStyles.chatroomHeaderPaddingBottom");
  padding-left: v-bind("$getStyles.chatroomHeaderPaddingLeft");
  padding-right: v-bind("$getStyles.chatroomHeaderPaddingRight");
  border-bottom: v-bind("$getStyles.chatroomHeaderBorderBottomSize") solid v-bind("$getStyles.chatroomHeaderBorderBottomColor") !important;
}
</style>
