<template>
  <iModal
    v-if="invitation"
    title="Invitation to Collaborate"
    :close-on-secondary="false"
    :max-width="650"
    @close="onClose"
  >
    <template #body>
      <iColumn gap="large">
        <iColumn gap="none">
          <iSubHeading font-size="standard">
            Publication:
          </iSubHeading>
          <iText v-if="invitation.publicationName">
            {{ invitation.publicationName }}
          </iText>
          <iText v-else variant="subtle">
            No publication name provided.
          </iText>
        </iColumn>

        <iColumn gap="none">
          <iSubHeading font-size="standard">
            Inviter:
          </iSubHeading>
          <iText v-if="invitation.owningOrgName">
            {{ invitation.owningOrgName }}
          </iText>
          <iText v-else variant="subtle">
            No inviter name provided.
          </iText>
        </iColumn>

        <iColumn gap="none">
          <iSubHeading font-size="standard">
            Invite Message:
          </iSubHeading>
          <iText v-if="invitation.invite_message">
            {{ invitation.invite_message }}
          </iText>
          <iText v-else variant="subtle">
            No invitation message provided.
          </iText>
        </iColumn>

        <iColumn gap="none">
          <iSubHeading font-size="standard">
            Role:
          </iSubHeading>
          <iText v-if="invitation.role">
            {{ invitation.role }}
          </iText>
          <iText v-else variant="subtle">
            No role specified.
          </iText>
        </iColumn>

        <iColumn>
          <iSubHeading font-size="standard">
            Collaboration Timeline
          </iSubHeading>
          <iTimeLine
            :items="timelineItems"
          />
        </iColumn>

        <iColumn>
          <iSubHeading font-size="standard">
            Compensation
          </iSubHeading>
          <iText variant="subtle">
            Placeholder compensation details or structure. This will be replaced
            with final design in a future update.
          </iText>
        </iColumn>
      </iColumn>
    </template>

    <template #footer>
      <iRow align="right" gap="standard">
        <iColumn width="hug">
          <iButton
            variant="secondary"
            :is-loading="isDenyingInvitation"
            loading-spinner-position="inside"
            @click="emitDecline"
          >
            Decline
          </iButton>
        </iColumn>
        <iColumn v-if="collaborationCounterOfferEnabled" width="hug">
          <iButton
            variant="secondary"
            @click="emitCounter"
          >
            Counter
          </iButton>
        </iColumn>
        <iColumn width="hug">
          <iButton
            variant="primary"
            :is-loading="isAcceptingInvitation"
            loading-spinner-position="inside"
            @click="emitAccept"
          >
            Accept
          </iButton>
        </iColumn>
      </iRow>
    </template>
  </iModal>
</template>

<script>
import { mapState } from "pinia";
import { useFeatureStore } from "%/stores/feature-store";
import { useCollaboratorStore } from "@/stores/collaborator-store";

export default {
  name: "InvitationReviewModal",
  props: {
    invitation: {
      type: Object,
      required: true,
    },
  },
  emits: ["close", "accept", "decline", "counter"],
  computed: {
    ...mapState(useCollaboratorStore, [
      "isAcceptingInvitation",
      "isDenyingInvitation",
    ]),
    collaborationCounterOfferEnabled() {
      return useFeatureStore().isFeatureEnabled("collaborationCounterOffer");
    },
    timelineItems() {
      return [
        {
          title: "Collaboration Start",
          description: "Project start date: TBD",
          primary: true,
        },
        {
          title: "Milestones",
          description: "Key deliverables (placeholder)",
          bubbleSmall: false,
        },
        {
          title: "End of Collaboration",
          description: "Closing / Handover (placeholder)",
          secondary: true,
        },
      ];
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    emitAccept() {
      this.$emit("accept", this.invitation.publication_applicant_id);
    },
    emitDecline() {
      this.$emit("decline", this.invitation.publication_applicant_id);
    },
    emitCounter() {
      this.$emit("counter");
    },
  },
};
</script>
