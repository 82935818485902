// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-label[data-v-aef1c594] {
  font-size: var(--aef1c594-\\\$getStyles\\.labelSize) !important;
}
.detail-label[data-v-aef1c594]:hover {
  font-size: var(--aef1c594-\\\$getStyles\\.labelSize) !important;
}
.subtle-text[data-v-aef1c594] {
  color: var(--aef1c594-\\\$getStyles\\.valueColor);
}
.subtle-text[data-v-aef1c594]:hover {
  color: var(--aef1c594-\\\$getStyles\\.valueColor);
}`, "",{"version":3,"sources":["webpack://./src/SharedWeb/scripts/components/community/chatrooms/slack-style/ChatroomUserActions.vue","webpack://./ChatroomUserActions.vue"],"names":[],"mappings":"AAEA;EACE,4DAAA;ACDF;ADGE;EACE,4DAAA;ACDJ;ADKA;EACE,8CAAA;ACFF;ADGE;EACE,8CAAA;ACDJ","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.detail-label {\n  font-size: v-bind('$getStyles.labelSize') !important;\n\n  &:hover {\n    font-size: v-bind('$getStyles.labelSize') !important;\n  }\n}\n\n.subtle-text {\n  color: v-bind('$getStyles.valueColor');\n  &:hover {\n    color: v-bind('$getStyles.valueColor');\n  }\n}\n",".detail-label {\n  font-size: v-bind(\"$getStyles.labelSize\") !important;\n}\n.detail-label:hover {\n  font-size: v-bind(\"$getStyles.labelSize\") !important;\n}\n\n.subtle-text {\n  color: v-bind(\"$getStyles.valueColor\");\n}\n.subtle-text:hover {\n  color: v-bind(\"$getStyles.valueColor\");\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
