<template>
  <iColumn gap="extraLarge">
    <iColumn gap="small">
      <iRow vertical-align="middle">
        <iColumn width="hug">
          <iHeading> Domain </iHeading>
        </iColumn><iColumn v-if="!isProTier" width="hug">
          <iButton
            variant="secondary"
            loading-spinner-position="inside"
            :is-loading="isSubscriberActionLoading"
            size="small"
            @click="subscribeToPro"
          >
            Upgrade to Pro
          </iButton>
        </iColumn>
      </iRow>

      <iParagraph>
        Your publication will always be visible on inspiration.co, but you can also connect it to a custom domain of your choosing.
        You must already own this domain, and will need to specify DNS settings to make your domain point to our servers.
        To get started enter the domain that you own below
      </iParagraph>
    </iColumn>

    <iTextEditInput
      v-model="publication.domain"
      label="Domain"
      :min-width="400"
      editable-on-click
      :is-loading="loadingUpdateDomain"
      @submit="saveDomain"
    />
    <iText v-if="errorUpdateDomain" variant="error">
      {{ errorUpdateDomain }}
    </iText>
  </iColumn>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import { useInspirationProStore } from "@/stores/publication-pro-store";
export default {
  name: "PublicationDomainConfiguration",
  computed: {
    ...mapState(useCreatorPublicationStore, ["loadingUpdateDomain", "errorUpdateDomain", "publication", "isProTier"]),
    ...mapState(useInspirationProStore, ["isSubscriberActionLoading"]),
  },
  methods: {
    ...mapActions(useCreatorPublicationStore, ["saveDomain"]),
    ...mapActions(useInspirationProStore, ["subscribeToPro"]),
  },
};
</script>
<style scoped>
</style>
