<template>
  <iModal
    :close-on-backdrop="false"
    :close-on-escape="false"
    :close-on-secondary="false"
    :content-height="80"
    :max-width="900"
    :show-footer="false"
    scrollable
    title="Assign Publishing Platform"
    width="fill"
    @close="close"
  >
    <template #body>
      <iColumn>
        <iTitle>
          Publications
        </iTitle>
        <iRow>
          <template v-if="publications.length === 0">
            <iText variant="subtle">
              You have no exclusive publications.
            </iText>
          </template>
          <template v-else>
            <iStyledCard
              v-for="publication in publications"
              :key="publication.publicationId"
              :max-width="270"
              :splash-image="publication.publicationLogo || DEFAULT_SPLASH_IMAGE"
              :splash-image-max-height="100"
              :title="publication.name"
              width="fill"
            >
              <template #footer>
                <iButton
                  variant="secondary"
                  width="fill"
                  @click="handleSelectPlatform(PUBLISHING_PLATFORM_TYPE.PUBLICATION, publication.publicationId)"
                >
                  Select
                </iButton>
              </template>
            </iStyledCard>
          </template>
        </iRow>
      </iColumn>
      <iColumn v-if="canSkip" align="center">
        <iButton
          variant="secondary"
          width="hug"
          @click="$emit('change', {})"
        >
          Continue without selecting a platform
        </iButton>
      </iColumn>
    </template>
  </iModal>
</template>

<script>
import { PUBLISHING_PLATFORM_TYPE } from "%/constants/story-constants";
import { STORY_VARIANT_TYPES } from "@/constants/story-variant-type-constants";
import { DEFAULT_SPLASH_IMAGE } from "%/constants/publication-constants";
import { useExclusivePublicationFeedStore } from "SmartFeed/stores/feed/exclusive-publication-feed-store";
import { usePublicationEntityStore } from "SmartFeed/stores/entity/publication-entity-store";

export default {
  name: "AssignExclusivePublishingPlatformModal",
  props: {
    storyId: {
      type: [Number, String],
      required: false,
      default: "",
    },
    canSkip: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["close", "change"],
  data() {
    return {
      PUBLISHING_PLATFORM_TYPE,
      STORY_VARIANT_TYPES,
      DEFAULT_SPLASH_IMAGE,
    };
  },
  computed: {
    publications() {
      return Object.values(usePublicationEntityStore().entities);
    },
  },
  mounted() {
    useExclusivePublicationFeedStore().getSmartFeed();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    handleSelectPlatform(type, id) {
      this.$emit("change", this.getEntity(type, id));
    },
    getEntity(type, id) {
      const publication = this.publications.find(publication => publication.publicationId === id);
      switch (type) {
        case PUBLISHING_PLATFORM_TYPE.PUBLICATION:
          return {
            publishing_platform_id: publication.publicationId,
            publishing_platform_type: PUBLISHING_PLATFORM_TYPE.PUBLICATION,
            publishing_platform_name: publication.name,
          };
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
