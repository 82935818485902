import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";

export const useOrgFeedStore = defineSmartFeedPiniaStore("org-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    feedUrl: "/org/public-orgs/smart-feed",
    metadata: {
      lastId: Number.MAX_SAFE_INTEGER,
    },
  }),
});
