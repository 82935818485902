<template>
  <PageWrapper
    :active-page="PAGE_COMMUNITY_COMMUNITY"
    :active-section="SECTION_COMMUNITY"
    :breadcrumbs="breadcrumbs"
    align="left"
    page-name="Message"
    sub-title=""
    width="fixed"
  >
    <Chatroom
      :conversation-id="conversationId"
      :entity-id="userId"
      :has-chat-room-header="false"
      context="direct_message"
    />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper.vue";
import { PAGE_COMMUNITY_COMMUNITY, SECTION_COMMUNITY } from "@/constants/nav-constants";
import Chatroom from "%/components/community/chatrooms/slack-style/Chatroom.vue";
import { useOrganizationStore } from "@/stores/organization-store";
import { mapState } from "pinia";
import { useGeneralStore } from "%/stores/general-store";
import { useConversationStore } from "%/stores/conversation-store";

export default {
  name: "DirectMessagePage",
  components: { Chatroom, PageWrapper },
  props: {
    orgId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_COMMUNITY_COMMUNITY,
      breadcrumbs: [
        { url: "/", label: "Home" },
        { url: "/community", label: "Community" },
        { url: `/community/org/${this.orgId}/profile`, label: useOrganizationStore().orgName },
      ],
    };
  },
  computed: {
    ...mapState(useConversationStore, ["conversationId"]),
    ...mapState(useGeneralStore, ["userId"]),
  },
};
</script>

<style lang="scss" scoped>

</style>
