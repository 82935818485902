<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_CHAT_ROOM"
    :breadcrumbs="breadcrumbs"
    page-name="Chat Room"
  >
    <iRow align="between" gap="standard" width="fill">
      <iColumn width="hug">
        <iHeading>
          {{ `${publication.name} Chat Room` }}
        </iHeading>
      </iColumn>
      <iColumn width="hug">
        <iLink :hover-underline="false" :href="`/publication/${publicationId}/collaborators`">
          <iButton
            variant="secondary"
          >
            Manage Collaborators
          </iButton>
        </iLink>
      </iColumn>
    </iRow>
    <Conversation
      layout="chatroom"
      :entity-id="publicationId"
      context="publication_chatroom"
      :channel-name="publication.name"
      :channel-href="`/community/publication/${publicationId}/profile`"
      :can-comment="conversationSettings.allowsComments"
      :can-like-comments="conversationSettings.allowsLike"
      :can-reply-to-comments="conversationSettings.allowsReply"
      :can-vote-on-comments="conversationSettings.allowsUpvoteAndDownvote"
      show-deleted-messages
    />
  </PageWrapper>
</template>

<script>
import Conversation from "%/components/community/chatrooms/Conversation";
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_PUBLICATION_CHAT_ROOM,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import { mapState } from "pinia";
import { useConversationStore } from "%/stores/conversation-store";

export default {
  name: "PublicationChatRoom",
  components: {
    PageWrapper,
    Conversation,
  },
  data() {
    return {
      PAGE_PUBLICATION_CHAT_ROOM,
      SECTION_PUBLICATIONS,
      previewData: null,
    };
  },
  computed: {
    ...mapState(useCreatorPublicationStore, ["publication", "publicationId", "orgId"]),
    ...mapState(useConversationStore, ["conversationSettings", "isLinkPreviewLoading"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        {
          url: `/publication/${this.publicationId}`,
          label: this.publication.name,
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.page-wrapper {
  :deep(.primary-content) {
    overflow-y: scroll;
  }
}
</style>
