<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_USERS"
    title="My Users"
    sub-title="Manage your users"
    :breadcrumbs="breadcrumbs"
    page-name="My Users"
    align="center"
    width="full"
    action-button-text="Add User"
    @click:action="openAddUserModal"
  >
    <SmartFeed
      :pinia-store="useMyPublicationUsersFeedStore"
      default-entity-layout="tableRow"
      default-smart-feed-layout="table"
    >
      <template #empty-state>
        <iRow vertical-align="middle" class="empty-state">
          <iIcon icon="person" size="large" />
          <iText>No users yet.</iText>
        </iRow>
      </template>
    </SmartFeed>
  </PageWrapper>
  <AddPublicationUser
    :show-modal="showAddUserModal"
    :publication-id="publicationId"
    @close="closeAddUserModal"
  />
</template>

<script>
import PageWrapper from "@/components/PageWrapper.vue";
import { mapState } from "pinia";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import {
  PAGE_PUBLICATION_USERS,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import SmartFeed from "SmartFeed/components/SmartFeed";
import { useMyPublicationUsersFeedStore } from "SmartFeed/stores/feed/my-publication-users-feed-store";
import AddPublicationUser from "@/components/publication/AddPublicationUser.vue";

export default {
  name: "PublicationUserManagement",
  components: {
    SmartFeed,
    PageWrapper,
    AddPublicationUser,
  },
  data() {
    return {
      PAGE_PUBLICATION_USERS,
      SECTION_PUBLICATIONS,
      showAddUserModal: false,
    };
  },
  computed: {
    ...mapState(useCreatorPublicationStore, [
      "orgId",
      "publication",
      "publicationId",
    ]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
      ];
    },
  },
  methods: {
    useMyPublicationUsersFeedStore,
    openAddUserModal() {
      this.showAddUserModal = true;
    },
    closeAddUserModal() {
      this.showAddUserModal = false;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
