<template>
  <SplashWrapper :hide-buttons="true">
    <iRow align="center" vertical-align="middle" height="fill">
      <iLoadingSpinner v-if="isLoading" />
      <iColumn
        v-else
        width="fill"
        :max-width="440"
        align="center"
      >
        <iForm
          :questions="[]"
          width="fill"
          @submit.prevent="submitAcceptInvitation"
        >
          <iColumn gap="extraLarge" width="fill">
            <iColumn gap="standard" width="fill">
              <iHeading font-size="extraExtraLarge" align="center">
                Welcome to {{ orgName }}!
              </iHeading>

              <iRow align="center" width="fill">
                <iText variant="subtle">
                  Please complete your profile to join the organization.
                </iText>
              </iRow>
            </iColumn>

            <iColumn gap="large" width="fill">
              <iColumn gap="standard" width="fill">
                <iTextInput
                  ref="firstname"
                  v-model="localFirstName"
                  placeholder="First name*"
                  name="firstname"
                  type="text"
                  label="First Name"
                  width="fill"
                />
                <iTextInput
                  ref="lastname"
                  v-model="localLastName"
                  placeholder="Last name*"
                  name="lastname"
                  type="text"
                  label="Last Name"
                  width="fill"
                />
                <iTextInput
                  v-model="password"
                  placeholder="Password*"
                  name="password"
                  type="password"
                  label="Password"
                  width="fill"
                />
              </iColumn>

              <PasswordComplexity
                v-if="password"
                :password="password"
                @update:password="validatePasswordComplexity"
              />
            </iColumn>

            <iColumn gap="standard" width="fill">
              <iCheckbox
                v-model="confirmAge"
                required
                label="I confirm that I am 18 years or older"
                name="confirm-age"
                :disabled="!validPassword"
              />
              <iCheckbox
                v-model="acceptedTerms"
                required
                label="I agree to the terms and conditions and privacy policy"
                name="terms"
                :disabled="!confirmAge"
              />
            </iColumn>

            <iColumn gap="standard" width="fill">
              <iAlert v-if="hasError" variant="error" width="fill">
                {{ signUpError }}
              </iAlert>

              <iButton
                type="submit"
                variant="primary"
                width="fill"
                :disabled="!isValidForm"
                @click="submitAcceptInvitation"
              >
                Complete Sign Up
              </iButton>
            </iColumn>
          </iColumn>
        </iForm>
      </iColumn>
    </iRow>
  </SplashWrapper>
</template>

<script>
import SplashWrapper from "@/components/splash/SplashWrapper";
import PasswordComplexity from "@/components/PasswordComplexity";
import { mapActions, mapState } from "pinia";
import { useUserStore } from "@/stores/user-store";

export default {
  name: "Invited",
  components: {
    PasswordComplexity,
    SplashWrapper,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    orgId: {
      type: Number,
      required: true,
    },
    orgName: {
      type: String,
      required: true,
    },
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      acceptedTerms: false,
      confirmAge: false,
      localFirstName: this.firstName,
      localLastName: this.lastName,
      password: "",
      validPassword: false,
    };
  },
  computed: {
    ...mapState(useUserStore, [
      "signupErrorMessage",
      "isLoading",
    ]),
    isValidForm() {
      return this.localFirstName &&
          this.localLastName &&
          this.acceptedTerms &&
          this.confirmAge &&
          this.validPassword;
    },
    hasError() {
      return this.signupErrorMessage !== "";
    },
  },
  methods: {
    ...mapActions(useUserStore, [
      "acceptInvitation",
    ]),
    submitAcceptInvitation() {
      if (!this.isValidForm) {
        return;
      }
      this.acceptInvitation(
        this.userId,
        this.token,
        this.localFirstName,
        this.localLastName,
        this.password,
        this.orgId,
      );
    },
    validatePasswordComplexity(value) {
      this.validPassword = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-form {
  margin: 0 auto;
  padding: 0 10px;
}
</style>
