<template>
  <iRow>
    <iRow align="center">
      <iDisplayText>Something went wrong</iDisplayText>
    </iRow>
    <iRow align="center">
      <iParagraph text-align="center">
        Please wait a few seconds and then refresh the page. If this keeps happening please contact support with the error id below
      </iParagraph>
    </iRow>
    <iRow align="center">
      <iText>Error ID: {{ errorId }}</iText>
    </iRow>
    <iRow v-if="debugMode" align="center">
      <iRow align="center">
        <iText font-size="large">
          {{ message }}
        </iText>
      </iRow>
      <iRow v-for="(entry, index) in stacktrace" :key="index" align="center">
        <template v-if="entry.file">
          #{{ index }} +{{ entry.line }} {{ entry.file }}
        </template>
        <template v-if="entry.class">
          {{ entry.class }}::{{ entry.function }}
        </template>
      </iRow>
    </iRow>
    <iRow v-else align="center">
      <iLink href="https://inspirationco.freshdesk.com/support/tickets/new" target="_blank">
        <iButton>Contact Support</iButton>
      </iLink>
    </iRow>
  </iRow>
</template>

<script>
export default {
  name: "Error",
  props: {
    errorId: {
      type: String,
      required: false,
      default: "",
    },
    debugMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    stacktrace: {
      type: Array,
      required: false,
      default: () => [],
    },
    message: {
      type: String,
      required: false,
      default: "",
    },
    file: {
      type: String,
      required: false,
      default: "",
    },
    line: {
      type: Number,
      required: false,
      default: 0,
    },
  },
};
</script>
