import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import useFanPublicationStore from "%/stores/fan-publication-store";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";

const MAX_TIMESTAMP = 9999999999999;
export const useEventFeedStore = defineSmartFeedPiniaStore("event-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    tableFields: [
      {
        key: "title",
        label: "Title",
      },
      {
        key: "description",
        label: "Description",
      },
      {
        key: "eventTimestamp",
        label: "Event Date",
      },
      {
        key: "imageUrl",
        label: "Image",
      },
      {
        key: "status",
        label: "Status",
      },
      {
        key: "actions",
        label: "",
      },
    ],
    metadata: {
      lastId: 0,
      hasMore: false,
      publicationId: useFanPublicationStore().publicationId,
      eventTimestampFrom: 0,
      eventTimestampTo: MAX_TIMESTAMP,
    },
  }),
  getters: {
    feedUrl() {
      return `events/publication/${this.metadata.publicationId}`;
    },
    smartFeedRequestGetParams() {
      return {
        last_id: this.metadata.lastId,
        event_timestamp_from: this.metadata.eventTimestampFrom,
        event_timestamp_to: this.metadata.eventTimestampTo,
      };
    },
  },
});
