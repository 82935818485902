import { defineStore } from "pinia";
import { useCollectionStore } from "~/stores/collection-store";
export const myFavoriteAdvertiserCollectionStore = defineStore("my-favorite-advertiser-collection-store", {
  state: () => ({
    collection: null,
    isLoading: false,
    errorMessage: "",
  }),
  getters: {
    currentCollectionItemId() {
      return this.collection && this.collection["collectionItemId"] > 0 ? this.collection["collectionItemId"] : null;
    },
  },
  actions: {
    createCollectionItem(creatorOrgId, advertiserOrgId) {
      this.isLoading = true;

      this.apiPost("/my-favorite-advertiser-collection/create",
        {
          creator_org_id: creatorOrgId,
          advertiser_org_id: advertiserOrgId,
        },
        response => {
          this.collection = response.collection;
        },
        () => {
          this.addEventError = "Failed to add this advertiser to your brands" ;
        },
        () => {
          this.isLoading = false;
        });
    },
    async removeCollectionItem(advertiserOrgId) {
      if (!this.collection) {
        return ;
      }

      this.isLoading = true;
      await useCollectionStore().removeItemFromCollection(
        this.currentCollectionItemId,
        this.collection.collectionId,
        advertiserOrgId,
        "org",
        () => {
          this.collection = null;
        },
        () => {
          this.errorMessage = "Failed to remove this advertiser from your brands.";
        },
      );
      this.isLoading = false;
    },
    getCollection(creatorOrgId, advertiserOrgId = 0) {
      this.apiGet(`/my-favorite-advertiser-collection/${creatorOrgId}`,
        {
          advertiser_org_id: advertiserOrgId,
        },
        response => {
          if (Object.keys(response.collection).length) {
            this.collection = response.collection;
          }
        },
        () => {
          this.addEventError = "Failed to fetch the collection" ;
        },
        () => {
          this.isLoading = false;
        });
    },
  },
});
