<template>
  <PageWrapper :active-section="SECTION_COMMUNITY" :active-page="PAGE_COMMUNITY_TOPIC">
    <iColumn>
      <iHeading>This is an example blog post (with id: {{ postId }}) in topic: {{ topic }} </iHeading>
      <Conversation
        :entity-id="postId"
        context="comments"
        layout="comments"
        :can-comment="conversationSettings.allowsComments"
        :can-like-comments="conversationSettings.allowsLike"
        :can-reply-to-comments="conversationSettings.allowsReply"
        :can-vote-on-comments="conversationSettings.allowsUpvoteAndDownvote"
        show-deleted-messages
      />
    </iColumn>
  </PageWrapper>
</template>

<script>
import { mapState } from "pinia";
import Conversation from "%/components/community/chatrooms/Conversation";
import { useGeneralStore } from "%/stores/general-store";
import { SECTION_COMMUNITY, PAGE_COMMUNITY_TOPIC } from "@/constants/nav-constants";
import PageWrapper from "@/components/PageWrapper";
import { useConversationStore } from "%/stores/conversation-store";

export default {
  name: "TopicPost",
  components: {
    Conversation,
    PageWrapper,
  },
  props: {
    topic: {
      type: String,
      required: true,
    },
    postId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_COMMUNITY_TOPIC,
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["userData", "orgId"]),
    ...mapState(useConversationStore, ["conversationSettings"]),
  },
};
</script>

<style scoped lang="scss">
</style>
