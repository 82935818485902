import { defineStore } from "pinia";
import { useEventFeedStore } from "SmartFeed/stores/feed/event-feed-store";

export const EVENT_ACTIVE_STATUS = "active";
export const EVENT_INACTIVE_STATUS = "inactive";

export const EVENT_STATUSES = [
  EVENT_INACTIVE_STATUS,
  EVENT_ACTIVE_STATUS,
];

export const useEventStore = defineStore("event-store", {
  state: () => ({
    addEventError: null,
    isUploadingImage: false,
    isLoading: false,
    publicationId: 0, // value will be injected in from frontend
    title: "",
    description: "",
    imageUrl: "",
    status: EVENT_ACTIVE_STATUS,
    eventTimestamp: Math.round(Date.now() / 1000),
    showAddEventModal: false,
    editEventId: null,
  }),
  getters: {
  },
  actions: {
    uploadImage(image) {
      this.isUploadingImage = true;

      let formData = new FormData();
      formData.append("image", image);
      formData.append("publicationId", this.publicationId);

      this.apiPost("/event/upload-image", formData, data => {
        this.imageUrl = data.image;
      }, () => {
        this.addEventError = "Failed to upload image, please retry.";
      }, () => {
        this.isUploadingImage = false;
      });
    },
    createEvent() {
      this.isLoading = true;

      this.apiPost("/event/create",
        {
          title: this.title,
          description: this.description,
          event_timestamp: this.eventTimestamp,
          image_url: this.imageUrl,
          publication_id: this.publicationId,
          status: this.status,
        },
        () => {
          this.addEventError = null;
          let metadata = useEventFeedStore().metadata;
          metadata.lastId = 0;
          useEventFeedStore().reload({ metadata: metadata });
          this.resetEventParameters();
        },
        () => {
          this.addEventError = "Failed to create event, please retry." ;
        },
        () => {
          this.isLoading = false;
        });
    },
    updateEvent() {
      this.isLoading = true;
      this.apiPost(`/event/${this.editEventId}/update`,
        {
          title: this.title,
          description: this.description,
          event_timestamp: this.eventTimestamp,
          image_url: this.imageUrl,
          publication_id: this.publicationId,
          metadata: "",
          status: this.status,
        },
        data => {
          useEventFeedStore().smartFeedEngine.addEntities(data.entities);
          this.resetEventParameters();
        },
        () => {
          this.addEventError = "Failed to update event, please retry." ;
        },
        () => {
          this.isLoading = false;
        });
    },
    changeStatus(entity) {
      const newStatus = entity.status === EVENT_ACTIVE_STATUS ? EVENT_INACTIVE_STATUS : EVENT_ACTIVE_STATUS;
      entity.status = newStatus;
      this.setUpdateParamsToEditEventEntityFields(entity);
      this.updateEvent();
    },
    resetEventParameters() {
      this.status = "active";
      this.title = "";
      this.description = "";
      this.imageUrl = "";
      this.eventTimestamp = Date.now() / 1000;
      this.editEventId = null;
      this.showAddEventModal = false;
    },
    setUpdateParamsToEditEventEntityFields(entity) {
      this.status = entity.status;
      this.title = entity.title;
      this.description = entity.description;
      this.imageUrl = entity.imageUrl;
      this.eventTimestamp = entity.eventTimestamp;
      this.editEventId = entity.eventId;
    },
    openEditEventModal(entity) {
      this.setUpdateParamsToEditEventEntityFields(entity);
      this.showAddEventModal = true;
    },
  },
});
