<template>
  <PageWrapper
    :active-section="SECTION_HOME"
    :active-page="PAGE_HOME"
    align="left"
    width="full"
  >
    <SmartFeed
      :pinia-store="useHomeCommunityPostFeedStore"
      default-smart-feed-layout="grid"
      default-entity-layout="cardSmall"
      load-more-behavior="auto"
    />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { SECTION_HOME, PAGE_HOME } from "@/constants/nav-constants";
import SmartFeed from "SmartFeed/components/SmartFeed.vue";
import { useHomeCommunityPostFeedStore } from "SmartFeed/stores/feed/home-community-post-feed-store";

export default {
  name: "Home",
  components: {
    PageWrapper,
    SmartFeed,
  },
  data() {
    return {
      SECTION_HOME,
      PAGE_HOME,
    };
  },
  methods: {
    useHomeCommunityPostFeedStore,
  },
};
</script>
