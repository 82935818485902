export const SIGN_UP_SETTINGS_MAP = {
  // Hidden temporarily (uncomment if needed)
  // readOnlySite: {
  //   title: "Read Only Site",
  //   description: "No signup no users everything visible to the public",
  //   features: {
  //     isPrivatePublication: false,
  //     canSignUp: false,
  //     requireAccountApproval: false,
  //   },
  // },
  accountNotRequired: {
    title: "Account Not Required",
    description:
        "Non-exclusive content is visible to the public & search engines without an account. " +
        "Some features, like commenting & viewing exclusive content, require creating accounts.",
    features: {
      canSignUp: true,
      isPrivatePublication: false,
      requireAccountApproval: false,
    },
  },
  accountRequired: {
    title: "Account Required",
    description:
        "Your content is hidden from the public & search engines. " +
        "Users must create accounts before they can view your community. " +
        "Anyone can signup, no invite or approval is required.",
    features: {
      canSignUp: true,
      isPrivatePublication: true,
      requireAccountApproval: false,
    },
  },
  applyToJoin: {
    title: "Apply to Join",
    description:
        "Your content is hidden from the public & search engines. " +
        "Anyone can request access, but an admin needs to approve applicants before they can enter your community.",
    features: {
      canSignUp: true,
      isPrivatePublication: true,
      requireAccountApproval: true,
    },
  },
  inviteOnly: {
    title: "Invite Only",
    description:
        "Your content is hidden from the public and from search engines. " +
        "Accounts are required, but cannot be created without an explicit invitation to join your community.",
    features: {
      canSignUp: false,
      isPrivatePublication: true,
      requireAccountApproval: true,
    },
  },
};
