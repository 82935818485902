import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import useFanPublicationStore from "%/stores/fan-publication-store";

export const useMyPublicationDigitalProductFeedStore =
  defineSmartFeedPiniaStore("my-publication-digital-product-feed-store", {
    state: () => ({
      smartFeedEngine: new SmartFeedEngine(),
      metadata: {
        publicationId: useFanPublicationStore().publicationId,
      },
    }),
    getters: {
      feedUrl() {
        return `/publication-digital-products/publication/${this.metadata.publicationId}/smart-feed`;
      },
    },
  });
