<template>
  <PageWrapper
    :active-section="SECTION_MONETIZATION"
    :active-page="PAGE_MONETIZATION_DIGITAL_PRODUCTS"
    page-name="Digital Products"
    align="left"
    width="fixed"
  >
    <iRow align="between" vertical-align="middle">
      <iHeading>Digital Products</iHeading>
      <iLink variant="tertiary" href="javascript:void(0)">
        Change Date Range
      </iLink>
    </iRow>
    <iDataTable
      :fields="digitalProductsFields"
      :items="digitalProductsItems"
      primary-key="productName"
      :has-more="false"
      :is-loading="false"
      hide-actions
    >
      <template #td="{data, value}">
        <iText :font-weight="data.productName === 'Total' ? 'dark' : 'standard'">
          {{ value }}
        </iText>
      </template>
    </iDataTable>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { SECTION_MONETIZATION, PAGE_MONETIZATION_DIGITAL_PRODUCTS } from "@/constants/nav-constants";
import {
  digitalProductsFields,
  digitalProductsItems,
} from "@/constants/monetization-fake-data";

export default {
  name: "DigitalProducts",
  components: {
    PageWrapper,
  },
  data() {
    return {
      SECTION_MONETIZATION,
      PAGE_MONETIZATION_DIGITAL_PRODUCTS,
      digitalProductsFields,
      digitalProductsItems,
    };
  },
};
</script>

<style scoped></style>
