<template>
  <PageWrapper
    :active-section="SECTION_MONETIZATION"
    :active-page="PAGE_MONETIZATION_PUBLICATIONS"
    page-name="Publications"
    align="left"
    width="fixed"
  >
    <iRow align="between" vertical-align="middle">
      <iHeading>Publications</iHeading>
      <iLink variant="tertiary" href="javascript:void(0)">
        Change Date Range
      </iLink>
    </iRow>
    <iDataTable
      :items="publicationsItems"
      primary-key="publicationName"
      :has-more="false"
      :is-loading="false"
      hide-actions
    >
      <template #td="{data, value}">
        <iText :font-weight="data.publicationName === 'Total' ? 'dark' : 'standard'">
          {{ value }}
        </iText>
      </template>
    </iDataTable>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { SECTION_MONETIZATION, PAGE_MONETIZATION_PUBLICATIONS } from "@/constants/nav-constants";
import { publicationsFields, publicationsItems } from "@/constants/monetization-fake-data";

export default {
  name: "Publications",
  components: {
    PageWrapper,
  },
  data() {
    return {
      SECTION_MONETIZATION,
      PAGE_MONETIZATION_PUBLICATIONS,
      publicationsFields,
      publicationsItems,
    };
  },
};
</script>
