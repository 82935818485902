<template>
  <SplashWrapper
    :hide-buttons="true"
  >
    <iRow align="center" vertical-align="middle" height="fill">
      <iColumn
        width="fill"
        :max-width="440"
        align="center"
      >
        <iForm :questions="[]" width="fill" @submit.prevent="submitRegistration">
          <iColumn gap="extraLarge" width="fill">
            <iColumn gap="standard" width="fill">
              <iHeading :wrap="false" font-size="extraExtraLarge" align="center">
                Complete Your Registration
              </iHeading>

              <iRow align="center" width="fill">
                <iText variant="subtle">
                  Please provide your details to complete the registration process and start collaborating.
                </iText>
              </iRow>
            </iColumn>

            <iColumn gap="standard" width="fill">
              <iTextInput
                v-model="localEmail"
                placeholder="Email*"
                name="email"
                disabled
                type="email"
                width="fill"
              />
              <iTextInput
                v-model="orgName"
                placeholder="Organization Name*"
                name="orgName"
                type="text"
                width="fill"
              />
              <iTextInput
                v-model="firstName"
                placeholder="First Name*"
                name="firstName"
                type="text"
                width="fill"
              />
              <iTextInput
                v-model="lastName"
                placeholder="Last Name*"
                name="lastName"
                type="text"
                width="fill"
              />

              <iTextInput
                v-model="password"
                placeholder="Password*"
                name="password"
                type="password"
                width="fill"
              />

              <iCheckbox
                v-model="acceptedTerms"
                required
                label="I agree to the terms and conditions and privacy policy"
                name="terms"
                width="fill"
              />
            </iColumn>

            <PasswordComplexity
              v-if="password"
              :password="password"
              width="fill"
              @update:password="validatePasswordComplexity"
            />

            <iColumn gap="standard" width="fill">
              <iText v-if="errorMessage" variant="error" width="fill">
                {{ errorMessage }}
              </iText>
              <iButton
                type="submit"
                variant="primary"
                width="fill"
                :disabled="!isValidForm || isLoading"
                :is-loading="isLoading"
                loading-spinner-position="inside"
                @click="submitRegistration"
              >
                Complete Registration
              </iButton>
            </iColumn>
          </iColumn>
        </iForm>
      </iColumn>
    </iRow>
  </SplashWrapper>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useUserStore } from "@/stores/user-store";
import SplashWrapper from "@/components/splash/SplashWrapper";
import PasswordComplexity from "@/components/PasswordComplexity";

export default {
  name: "InvitedOffPlatform",
  components: {
    PasswordComplexity,
    SplashWrapper,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
    orgId: {
      type: Number,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    emailAddress: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      acceptedTerms: false,
      orgName: "",
      firstName: "",
      lastName: "",
      localEmail: this.emailAddress,
      password: "",
      validPassword: false,
    };
  },
  computed: {
    ...mapState(useUserStore, [
      "isLoading",
      "signupErrorMessage",
    ]),
    isValidForm() {
      return this.orgName && this.firstName && this.lastName && this.acceptedTerms && this.validPassword;
    },
    errorMessage() {
      return this.signupErrorMessage;
    },
  },
  methods: {
    ...mapActions(useUserStore, [
      "finalizeCollaboratorOffPlatformInvite",
    ]),
    validatePasswordComplexity(value) {
      this.validPassword = value;
    },
    submitRegistration() {
      if (!this.isValidForm) {
        return;
      }

      this.finalizeCollaboratorOffPlatformInvite(
        this.orgId,
        this.userId,
        this.firstName,
        this.lastName,
        this.orgName,
        this.password,
        this.token,
      );
    },
  },
};
</script>

<style scoped>
.auth-form {
  margin: 0 auto;
  padding: 0 10px;
}
</style>
