<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_THEME_PALETTES"
    title="Edit Theme Palette"
    :breadcrumbs="breadcrumbs"
    page-name="Edit Theme Palette"
  >
    <iColumn>
      <ThemePaletteConfiguration
        v-model="innerPalette"
        edit-mode
        @has-errors="formHasErrors = $event"
      />

      <iSpace :height="10" />
      <iText v-if="formHasErrors" variant="error">
        Please correct the errors above before saving.
      </iText>
      <iRow align="between" vertical-align="middle">
        <iRow width="hug">
          <iColumn width="hug">
            <iButton
              :is-loading="isLoadingPostPalette"
              loading-spinner-position="inside"
              :disabled="formHasErrors"
              variant="primary"
              @click="showStagingConfirmation = true"
            >
              Stage Changes
            </iButton>
          </iColumn>
          <iColumn width="hug">
            <iButton
              v-if="canDeployToProduction"
              :is-loading="isLoadingPostPalette"
              loading-spinner-position="inside"
              :disabled="formHasErrors"
              variant="primary"
              @click="showProductionConfirmation = true"
            >
              Publish
            </iButton>
          </iColumn>
          <iColumn width="hug">
            <iLink :href="`/publication/org/${orgId}/theme-palettes`">
              <iButton variant="secondary">
                Go Back
              </iButton>
            </iLink>
          </iColumn>
        </iRow>
      </iRow>
    </iColumn>
    <iPromptModal
      :visible="showStagingConfirmation"
      title="Stage Changes"
      :message="`What changes did you make to your theme palette ?`"
      label="Message/Memo"
      @close="showStagingConfirmation = false"
      @submit="deployToStaging({...innerPalette, memo: $event})"
    />
    <iPromptModal
      :visible="showProductionConfirmation"
      title="Publish Changes"
      :message="`What changes did you make to your theme palette ?`"
      label="Message/Memo"
      @close="showProductionConfirmation = false"
      @submit="deployToProduction({...innerPalette, memo: $event})"
    />
    <iModal
      :visible="showRollbackWarning"
      title="Are you sure?"
      :max-width="800"
      :height="600"
      :close-on-esc="true"
      :close-on-click-outside="true"
      @click:primary="rollbackThemePalette(paletteInfo.production_version_id)"
      @click:secondary="showRollbackWarning = false"
      @close="closeRollbackWarning = false"
    >
      <template #body>
        <iText>
          You are about to rollback the theme palette to its production values,
          this action can not be undone. Are you sure you want to proceed?
        </iText>
      </template>
    </iModal>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import ThemePaletteConfiguration from "@/components/ThemePaletteConfiguration";
import {
  PAGE_THEME_PALETTES,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import { useGeneralStore } from "%/stores/general-store";
import { useThemesStore } from "@/stores/themes-store";
import { mapActions, mapState } from "pinia";

export default {
  name: "ThemePalettesEdit",
  components: { ThemePaletteConfiguration, PageWrapper },
  props: {
    palette: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    paletteInfo: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      SECTION_PUBLICATIONS,
      PAGE_THEME_PALETTES,
      innerPalette: this.palette,
      formHasErrors: false,
      showStagingConfirmation: false,
      showProductionConfirmation: false,
      showRollbackWarning: false,
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["orgId"]),
    ...mapState(useThemesStore, ["isLoadingPostPalette"]),
    canDeployToProduction() {
      return (
        this.paletteInfo.production_version_id !==
        this.paletteInfo.staging_version_id
      );
    },
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        {
          url: `/publication/org/${this.orgId}/theme-palettes`,
          label: "Theme Palettes",
        },
      ];
    },
  },
  methods: {
    ...mapActions(useThemesStore, [
      "deployToStaging",
      "deployToProduction",
      "rollbackThemePalette",
    ]),
  },
};
</script>

<style scoped lang="scss"></style>
