import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { useFanPublicationStore } from "%/stores/fan-publication-store";
import { useGeneralStore } from "%/stores/general-store";

export const useChannelPublicationUserFeedStore = defineSmartFeedPiniaStore("channel-publication-user-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    metadata: {
      publicationId: useFanPublicationStore().publicationId,
    },
  }),
  getters: {
    feedUrl() {
      return `${useGeneralStore().links.fanApiV1}/publication/${this.metadata.publicationId}/users/smart-feed`;
    },
  },
});
