import { useGeneralStore } from "%/stores/general-store";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { links } from "%/enums/link-enums";

export const useAffiliateLinkFeedStore = defineSmartFeedPiniaStore("affiliate-link-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    metadata: {
      publisherOrgId: useGeneralStore().orgId,
    },
    tableFields: [
      {
        key: "affiliateLink",
        label: "Affiliate Link",
        width: "320px",
      },
      {
        key: "originalLink",
        label: "Original Link",
        width: "320px",
      },
      {
        key: "advertiserName",
        label: "Advertiser",
        width: "200px",
      },
      {
        key: "myDescription",
        label: "My Description",
        width: "300px",
      },
      {
        key: "notes",
        label: "Notes",
        width: "300px",
      },
      {
        key: "brandName",
        label: "Brand Name",
        width: "200px",
      },
      {
        key: "productName",
        label: "Product Name",
        width: "200px",
      },
      {
        key: "clicks",
        label: "Clicks",
      },
      {
        key: "sales",
        label: "Sales",
      },
      {
        key: "totalEarnings",
        label: "Total Earnings",
      },
      {
        key: "pendingEarnings",
        label: "Pending Earnings",
      },
      {
        key: "lockedEarnings",
        label: "Locked Earnings",
      },
      {
        key: "actions",
        label: "",
      },
    ],
  }),
  getters: {
    affiliateApiV1() {
      return useGeneralStore().getLink(links.affiliateApiV1);
    },
    feedUrl() {
      return `/affiliate-link/${this.metadata.publisherOrgId}/smartfeed`;
    },
  },
});
