<template>
  <SplashWrapper
    :hide-buttons="true"
    :title="pageTitle"
    :narrow-page="true"
  >
    <iColumn gap="extraLarge">
      <iRow>
        <iText variant="subtle">
          Create complete your profile to continue. This will be your password on inspiration.co
        </iText>
      </iRow>

      <iRow>
        <iColumn gap="large">
          <iTextInput
            v-model="userAuthEmail"
            label="Email address: "
            name="email"
            label-position="above"
            type="email"
            disabled
            width="fill"
          />
          <iTextInput
            v-model="userFirstName"
            label="First Name: "
            name="first_name"
            label-position="above"
            type="text"
            width="fill"
          />
          <iTextInput
            v-model="userLastName"
            label="Last Name: "
            name="last_name"
            label-position="above"
            type="text"
            width="fill"
          />
          <!-- This is not our preferred way of doing things-->
          <!-- This is done specifically to prevent Chromium browsers from incorrectly adding an email to the last name input -->
          <form class="password-form-wrapper">
            <iTextInput
              v-model="password"
              label="Password: "
              label-position="above"
              placeholder="Password*"
              name="password"
              width="fill"
              type="password"
              autocomplete
            />
          </form>
          <PasswordComplexity
            v-if="password"
            :password="password"
            @update:password="validatePasswordComplexity"
          />

          <iText v-if="loginErrorMessage" variant="error">
            {{ loginErrorMessage }}
          </iText>

          <iButton
            variant="primary"
            type="submit"
            width="fill"
            :disabled="hasInvalidPassword"
            :is-loading="isLoading"
            loading-spinner-position="inside"
            @click="submitSignUpForm"
          >
            Sign Up and Continue
          </iButton>
        </iColumn>
      </iRow>
    </iColumn>
  </SplashWrapper>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useUserStore } from "@/stores/user-store";
import SplashWrapper from "@/components/splash/SplashWrapper";
import PasswordComplexity from "@/components/PasswordComplexity.vue";

export default {
  name: "AcceptInvitationPasswordForm",
  components: { PasswordComplexity, SplashWrapper },
  data() {
    return {
      password: "",
    };
  },
  computed: {
    ...mapWritableState(useUserStore, [
      "userFlowSteps",
      "loginErrorMessage",
    ]),
    ...mapState(useUserStore, [
      "userAuthEmail",
      "isLoading",
      "invitedPublicationName",
    ]),
    ...mapWritableState(useUserStore, [
      "userLastName",
      "userFirstName",
    ]),
    hasInvalidPassword() {
      return this.password.length === 0;
    },
    pageTitle() {
      return `Join ${this.invitedPublicationName} with inspiration.co`;
    },
  },
  mounted() {
    this.loginErrorMessage = "";
  },
  methods: {
    ...mapActions(useUserStore, [
      "signUpAndAcceptInvitation",
    ]),
    validatePasswordComplexity(value) {
      this.validPassword = value;
    },
    submitSignUpForm() {
      if (this.hasInvalidPassword) {
        return;
      }
      this.signUpAndAcceptInvitation(this.password);
    },
  },
};
</script>

<style lang="scss" scoped>
.password-form-wrapper {
  width: 100%;
}
</style>
