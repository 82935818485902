import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { useFanPublicationStore } from "%/stores/fan-publication-store";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { useGeneralStore } from "%/stores/general-store";

export const useLovedStoryFeedStore = defineSmartFeedPiniaStore("loved-story-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    metadata: {
      lastId: 0,
      publicationId: useFanPublicationStore().publicationId,
      userId: useGeneralStore().userId,
    },
  }),
  getters: {
    feedUrl() {
      return `publication/${this.metadata.publicationId}/stories/loved/smart-feed`;
    },
    smartFeedRequestGetParams() {
      return {
        last_id: this.metadata.lastId,
        user_id: this.metadata.userId,
      };
    },
  },
});
