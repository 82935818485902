import { useGeneralStore } from "%/stores/general-store";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";

export const useExclusivePublicationFeedStore = defineSmartFeedPiniaStore("exclusive-publication-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    metadata: {
      publicationOrgId: useGeneralStore().orgId,
    },
  }),
  getters: {
    feedUrl() {
      return `/publication/org/${this.metadata.publicationOrgId}/exclusive/smart-feed/status`;
    },
    smartFeedRequestGetParams() {
      return {
        last_id: this.metadata.lastId,
        status: "active",
      };
    },
  },
});
