// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post-container[data-v-b56bf40a] {
  overflow-y: auto;
}
.post-container-content[data-v-b56bf40a] {
  padding: 24px;
  max-width: 844px;
}
.hashtag-link[data-v-b56bf40a] {
  padding: 4px 8px;
  margin-left: 4px;
}
.hashtag-link[data-v-b56bf40a]:hover {
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/pages/stories/SyndicatedStory.vue","webpack://./SyndicatedStory.vue"],"names":[],"mappings":"AAEA;EACE,gBAAA;ACDF;ADGE;EACE,aAAA;EACA,gBAAA;ACDJ;ADKA;EACE,gBAAA;EACA,gBAAA;ACFF;ADGE;EACE,kBAAA;ACDJ","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.post-container {\n  overflow-y: auto;\n\n  &-content {\n    padding: 24px;\n    max-width: 844px;\n  }\n}\n\n.hashtag-link {\n  padding: 4px 8px;\n  margin-left: 4px;\n  &:hover {\n    border-radius: 8px;\n  }\n}\n",".post-container {\n  overflow-y: auto;\n}\n.post-container-content {\n  padding: 24px;\n  max-width: 844px;\n}\n\n.hashtag-link {\n  padding: 4px 8px;\n  margin-left: 4px;\n}\n.hashtag-link:hover {\n  border-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
