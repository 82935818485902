import { SmartFeedEngine as FrameworkSmartFeedEngine } from "@bloglovin/vue-framework";
export default class SmartFeedEngine extends FrameworkSmartFeedEngine {

  async getResolvedStoreImport(filePath) {
    return import(`SmartFeed/${filePath}`);
  }

  async getResolvedLayoutComponent(filePath) {
    return import(`SmartFeed/${filePath}`);
  }
}
