<template>
  <iTextInput
    :model-value="modelValue"
    :label="label"
    :placeholder="label"
    :width="300"
    size="small"
    :debounce="1000"
    right-icon="close"
    @update:model-value="debouncedUpdateFilter"
    @click:icon-right="clearFilter"
  />
</template>

<script>
import { debounce } from "lodash";

export default {
  name: "SmartFeedFilterTextInput",
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [Number, String, Object],
      required: true,
    },
  },
  emits: ["update:modelValue", "change"],
  computed: {
    debouncedUpdateFilter() {
      return debounce(this.updateFilter, 1000);
    },
  },
  methods: {
    clearFilter() {
      this.updateFilter("");
    },
    updateFilter(value) {
      this.$emit("update:modelValue", value);
      if (value !== this.modelValue) {
        this.$emit("change", value);
      }
    },
  },
};
</script>
