<template>
  <iSelect
    :model-value="modelValue"
    :items="items"
    :label="label"
    size="small"
    text-field="label"
    value-field="value"
    return-value
    @update:model-value="updateFilter"
  />
</template>

<script>
export default {
  name: "SmartFeedFilterDropdown",
  props: {
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [Number, String, Object],
      required: true,
    },
  },
  emits: ["update:modelValue", "change"],
  methods: {
    updateFilter(value) {
      this.$emit("update:modelValue", value);
      if (value !== this.modelValue) {
        this.$emit("change", value);
      }
    },
  },
};
</script>
