<template v-if="collaborationCounterOfferEnabled">
  <iModal
    title="Propose Counter Offer"
    :close-on-backdrop="false"
    :close-on-escape="false"
    :close-on-secondary="false"
    :max-width="600"
    @close="closeModal"
  >
    <template #body>
      <iColumn gap="large">
        <iSubHeading>Hiring Compensation Mockup</iSubHeading>
        <iText variant="subtle">
          Placeholder for compensation details, custom terms, or other fields
          needed for a counter offer. This section will eventually display
          the final hiring compensation UI design.
        </iText>
      </iColumn>
    </template>

    <template #footer>
      <iRow align="right" gap="standard">
        <iColumn width="hug">
          <iButton variant="secondary" width="fill" @click="closeModal">
            Cancel
          </iButton>
        </iColumn>
        <iColumn width="hug">
          <iButton
            variant="primary"
            width="fill"
            @click="submitCounter"
          >
            Submit
          </iButton>
        </iColumn>
      </iRow>
    </template>
  </iModal>
</template>

<script>
import { useFeatureStore } from "%/stores/feature-store";

export default {
  name: "CounterOfferModal",
  emits: ["close", "submit"],
  computed: {
    collaborationCounterOfferEnabled() {
      return useFeatureStore().isFeatureEnabled("collaborationCounterOffer");
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    submitCounter() {
      this.$emit("submit");
    },
  },
};
</script>
