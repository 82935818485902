<template>
  <PageWrapper :active-section="SECTION_PUBLICATIONS" :active-page="PAGE_PUBLICATION_POSTS">
    <StoryEditorComponent :margin-width="75" />
    <iModal
      v-if="showEditPostConfirmation"
      title="Edit Story"
      :max-width="600"
      :close-on-backdrop="false"
      :close-on-escape="false"
      :close-on-secondary="false"
      :primary-action-button-loading="updateStoryIsLoading"
      primary-action-button-label="Continue"
      @close="closeEditPostConfirmation"
      @click:primary="goToEditPost"
      @click:secondary="closeEditPostConfirmation"
    >
      <template #body>
        <iParagraph>
          Your story is currently live. If you want to continue editing, your story will be changed to draft.
        </iParagraph>
      </template>
    </iModal>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { PAGE_PUBLICATION_POSTS, SECTION_PUBLICATIONS } from "@/constants/nav-constants";
import PageWrapper from "@/components/PageWrapper.vue";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useSocialNetworksStore } from "@/stores/social-networks-store";
import { useGeneralStore } from "%/stores/general-store";
import StoryEditorComponent from "@/components/publication/StoryEditorComponent";
import { useStoryStore } from "%/stores/story-store";
dayjs.extend(relativeTime);

export default {
  name: "ViewPublicationPost",
  components: {
    StoryEditorComponent,
    PageWrapper,
  },
  data() {
    return {
      PAGE_PUBLICATION_POSTS,
      SECTION_PUBLICATIONS,
      showEditPostConfirmation: false,
      initialLoad: 0,
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["orgId"]),
    ...mapWritableState(useStoryStore, ["story", "updateStoryIsLoading"]),
  },
  methods: {
    ...mapActions(useSocialNetworksStore, ["postMessage"]),
    publishToNetwork(socialNetwork) {
      this.postMessage(this.orgId, socialNetwork, this.story.story_id);
    },
  },
};
</script>

<style scoped lang="scss">

</style>
