<template>
  <iRow vertical-align="middle" wrap="nowrap">
    <iEditor
      :is-loading="newMessageIsLoading"
      :upload-post-image="uploadMessageImage"
      focus
      name="message"
      placeholder="Type a message..."
      variant="slack"
      :preview-link-data="previewLinkData"
      @input="draftMessage = $event"
      @submit="sendNewMessage"
      @get:link-preview="handleGetPreviewData"
    />
  </iRow>
</template>

<script>
import { useConversationStore } from "%/stores/conversation-store";
import { mapActions, mapState, mapWritableState } from "pinia";
import { DEFAULT_USER_AVATAR } from "%/constants/user-constants";

export default {
  name: "NewChatroomMessage",
  props: {
    userAvatarUrl: {
      type: String,
      required: false,
      default: DEFAULT_USER_AVATAR,
    },
    context: {
      type: String,
      required: false,
      default: "",
    },
    entityId: {
      type: Number,
      required: false,
      default: 0,
    },
    conversationId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      previewLinkData: null,
    };
  },
  computed: {
    ...mapWritableState(useConversationStore, ["draftMessage"]),
    ...mapState(useConversationStore, ["isMessageLoading"]),
    newMessageIsLoading() {
      return this.isMessageLoading.newMessage;
    },
  },
  methods: {
    ...mapActions(useConversationStore, [
      "sendMessage",
      "sendPublicationChatroomMessage",
      "uploadMessageImage",
      "getLinkPreviewData",
    ]),
    sendNewMessage() {
      this.previewLinkData = null;

      if (!this.draftMessage) {
        return;
      }
      if (this.context === "publication_chatroom") {
        this.sendPublicationChatroomMessage(this.entityId);
      } else {
        this.sendMessage(this.draftMessage, this.conversationId, this.context, this.entityId);
      }
      this.draftMessage = "";
    },
    handleGetPreviewData(url) {
      if (!url) {
        this.previewLinkData = null;
        return;
      }

      this.getLinkPreviewData(url, data => {
        if (this.draftMessage.includes(url)) {
          this.previewLinkData = data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.send-btn {
  position: absolute;
  right: 12px;
  top: 0;
  bottom: 0;
  margin-block: auto;
}

.message-box-container {
  position: relative;
}

.message-box-container :deep(input) {
  padding-right: 36px;
}
</style>
