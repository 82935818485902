<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_EVENTS"
    title="Events"
    sub-title="Manage your events"
    :breadcrumbs="breadcrumbs"
    page-name="Events"
    align="center"
    width="full"
    action-button-text="Add Event"
    @click:action="openAddEventModal"
  >
    <SmartFeed :pinia-store="useEventFeedStore" default-entity-layout="tableRow" default-smart-feed-layout="table">
      <template #empty-state>
        <iRow vertical-align="middle" class="empty-state">
          <iIcon icon="calender" size="large" />
          <iText>No events yet.</iText>
        </iRow>
      </template>
    </SmartFeed>
    <CreateUpdateEventModal />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper.vue";
import { mapActions, mapState, mapWritableState } from "pinia";
import {
  PAGE_PUBLICATION_EVENTS,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import SmartFeed from "SmartFeed/components/SmartFeed";
import { useEventFeedStore } from "SmartFeed/stores/feed/event-feed-store";
import { useEventStore, EVENT_ACTIVE_STATUS, EVENT_INACTIVE_STATUS } from "@/stores/event-store";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import CreateUpdateEventModal from "@/components/publication/CreateUpdateEventModal.vue";

export default {
  name: "PublicationEvents",
  components: { CreateUpdateEventModal, SmartFeed, PageWrapper },
  data() {
    return {
      PAGE_PUBLICATION_EVENTS,
      SECTION_PUBLICATIONS,
      EVENT_ACTIVE_STATUS,
      EVENT_INACTIVE_STATUS,
    };
  },
  computed: {
    ...mapState(useCreatorPublicationStore, [
      "orgId",
      "publicationId",
    ]),
    ...mapWritableState(useEventStore, [
      "addEventError",
      "showAddEventModal",
      "editEventId",
    ]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        {
          url: `/publication/${this.publicationId}`,
          label: "Overview",
        },
      ];
    },
  },
  methods: {
    useEventFeedStore,
    ...mapActions(useEventStore, ["resetEventParameters"]),
    openAddEventModal() {
      if (this.editEventId) {
        this.resetEventParameters();
      }
      this.addEventError = null;
      this.showAddEventModal = true;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
