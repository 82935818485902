<template>
  <PageWrapper
    :active-page="PAGE_PUBLICATION_CHAT_ROOM"
    :active-section="SECTION_PUBLICATIONS"
    :breadcrumbs="breadcrumbs"
    page-name="Chat Room"
    align="center"
    width="full"
  >
    <iRow align="between" gap="standard" width="fill">
      <iColumn width="hug">
        <iHeading>Manage Collaborators</iHeading>
      </iColumn>
      <iColumn width="hug">
        <iLink
          :hover-underline="false"
          :href="`/publication/${publicationId}/chatroom`"
        >
          <iButton variant="secondary">
            Back to Chatroom
          </iButton>
        </iLink>
      </iColumn>
    </iRow>

    <iColumn gap="extraLarge">
      <iColumn gap="large">
        <iRow align="left" vertical-align="middle" width="fill">
          <iColumn width="hug">
            <iHeading font-size="extraLarge">
              Ads
            </iHeading>
          </iColumn>
          <iColumn width="hug">
            <iButton
              size="small"
              variant="secondary"
              @click="showAdvertisementModal"
            >
              Create new ad
            </iButton>
          </iColumn>
        </iRow>
        <SmartFeed
          :pinia-store="useMyPublicationCollaborationAdFeedStore"
          default-entity-layout="cardSmall"
          default-smart-feed-layout="carousel"
        >
          <template #empty-state>
            <iRow vertical-align="middle" class="empty-state" gap="small">
              <iText>No ads yet.</iText>
            </iRow>
          </template>
        </SmartFeed>
      </iColumn>
      <iColumn gap="large">
        <iColumn gap="small">
          <iRow align="left" vertical-align="middle" width="fill">
            <iColumn width="hug">
              <iHeading font-size="extraLarge">
                Collaborators
              </iHeading>
            </iColumn>
            <iColumn width="hug">
              <iButton
                size="small"
                variant="secondary"
                @click="showInviteToCollaborateModal = true"
              >
                Invite
              </iButton>
            </iColumn>
          </iRow>
          <iRow vertical-align="middle" gap="small" width="fill">
            <iColumn width="hug">
              <iText font-size="small">
                Enable Applications
              </iText>
            </iColumn>
            <iColumn width="hug">
              <iToggleSwitch
                size="small"
                :model-value="isApplicationsEnabled"
                :true-value="true"
                :false-value="false"
                @update:model-value="toggleApplications"
              />
            </iColumn>
            <iColumn
              v-tooltip="{
                text: 'When applications are enabled, your publication\'s profile in the creator community will invite people to apply to join your publication',
                position: 'bottom',
              }"
              width="hug"
            >
              <iIcon icon="info-circle" />
            </iColumn>
          </iRow>
        </iColumn>
        <SmartFeed
          :pinia-store="useMyPublicationCollaboratorsFeedStore"
          default-smart-feed-layout="table"
          default-entity-layout="tableRow"
        >
          <template #empty-state>
            <iRow vertical-align="middle" class="empty-state" gap="small">
              <iText>No collaborators yet.</iText>
            </iRow>
          </template>
        </SmartFeed>
      </iColumn>
    </iColumn>

    <iColumn gap="standard">
      <InviteToCollaborateOffPlatform
        :show-modal="showInviteToCollaborateModal"
        :org-id="getOrgData.org_id"
        :publication-id="publicationId"
        @close="onCloseInviteToCollaborateModal"
      />
      <iModal
        :visible="isAdvertisementModalVisible"
        :max-width="600"
        title="Create Advertisement"
        @close="closeAdvertisementModal"
        @click:primary="createAdvertisement"
        @click:secondary="closeAdvertisementModal"
      >
        <template #body>
          <iColumn gap="large">
            <iParagraph font-size="small">
              Ads for collaborators will appear in a special section of the
              creator community, they will also appear on your publication’s
              profile. Ads allow you to explain exactly what you are looking
              for and invites people to apply for specific roles.
            </iParagraph>
            <iSelect
              v-model="advertiseRole"
              :items="AD_ROLE_OPTIONS"
              label="Role"
              width="fill"
              value-field="value"
            />
            <iTextArea
              v-model="advertiseDescription"
              label="Description"
              placeholder="Enter role description"
              :rows="5"
              width="fill"
            />
          </iColumn>
        </template>
      </iModal>
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper.vue";
import { AD_ROLE_OPTIONS } from "@/constants/collaborator-constants";
import {
  PAGE_PUBLICATION_CHAT_ROOM,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import { useOrganizationStore } from "@/stores/organization-store";
import { mapActions, mapState } from "pinia";
import SmartFeed from "SmartFeed/components/SmartFeed.vue";
import { useMyPublicationCollaboratorsFeedStore } from "SmartFeed/stores/feed/use-my-publication-collaborators-feed-store";
import {
  useMyPublicationCollaborationAdFeedStore,
} from "SmartFeed/stores/feed/my-publication-collaboration-ad-feed-store";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import InviteToCollaborateOffPlatform from "@/components/publication/InviteToCollaborateOffPlatform.vue";

export default {
  name: "PublicationCollaborators",
  components: {
    InviteToCollaborateOffPlatform,
    SmartFeed,
    PageWrapper,
  },
  props: {
    publicationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      advertiseRole: "",
      advertiseDescription: "",
      AD_ROLE_OPTIONS,
      SECTION_PUBLICATIONS,
      PAGE_PUBLICATION_CHAT_ROOM,
      showInviteToCollaborateModal: false,
    };
  },
  computed: {
    ...mapState(useOrganizationStore, ["getOrgData"]),
    ...mapState(useCreatorPublicationStore, [
      "publication",
      "isAdvertisementModalVisible",
      "isCreatingAdvertisement",
    ]),
    isApplicationsEnabled() {
      return this.publication.enable_applications === "enabled";
    },
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        {
          url: `/publication/${this.publicationId}`,
          label: this.publication.name,
        },
      ];
    },
  },
  methods: {
    useMyPublicationCollaboratorsFeedStore,
    useMyPublicationCollaborationAdFeedStore,
    ...mapActions(useCreatorPublicationStore, [
      "enableApplications",
      "disableApplications",
      "createPublicationCollaboratorAd",
      "showAdvertisementModal",
      "closeAdvertisementModal",
    ]),
    async createAdvertisement() {
      if (!this.advertiseRole || !this.advertiseDescription) {
        return;
      }

      await this.createPublicationCollaboratorAd(
        this.publicationId,
        this.advertiseRole.value,
        this.advertiseDescription,
      );
      this.advertiseRole = "";
      this.advertiseDescription = "";
    },
    toggleApplications(value) {
      if (value) {
        this.enableApplications(this.publicationId);
      } else {
        this.disableApplications(this.publicationId);
      }
    },
    onCloseInviteToCollaborateModal() {
      this.showInviteToCollaborateModal = false;
    },
  },
};
</script>
