<template>
  <FeatureConfig />
  <iColumn
    height="fill"
    class="page-wrapper"
    wrap="nowrap"
    gap="none"
  >
    <iRow v-if="isFakeLoggedIn">
      <iAlert variant="error">
        <template #title>
          <iRow wrap="nowrap" vertical-align="middle">
            <iRow wrap="nowrap" vertical-align="middle">
              <AvatarImage :src="impersonatorAvatar" size="24px" />
              <iText variant="light" font-size="large">
                {{ impersonatorUsername }}
              </iText>
              <iText variant="light" font-size="standard">
                is impersonating
              </iText>
              <AvatarImage :src="userAvatar" size="24px" />
              <iText variant="light" font-size="large">
                {{ username }}
              </iText>
            </iRow>
            <iIcon icon="close" variant="light" @click="cancelFakeLogin" />
          </iRow>
        </template>
      </iAlert>
    </iRow>
    <iRow
      class="nav-container"
      :height="80"
      wrap="nowrap"
    >
      <iIcon
        v-if="screenWidth < 768 && currentSideNav && !hideSidebar"
        class="mobile-menu-trigger"
        :icon="isSideBarOpen ? 'close' : 'hamburger'"
        size="large"
        @click.stop="toggleSidebar"
      />
      <MainNav :active-item="activeSection" />
    </iRow>
    <iRow
      :vertical-align="verticalAlign"
      :align="myPrimaryAlignment"
      wrap="nowrap"
      class="site-content"
      height="fill"
      overflow="scroll"
    >
      <iColumn
        v-if="currentSideNav && showSideBar && !hideSidebar"
        v-click-outside="closeSidebar"
        :width="300"
        height="fill"
        class="side-navigation"
        overflow="scroll"
        wrap="nowrap"
      >
        <component :is="currentSideNav" :active-item="activePage" />
      </iColumn>
      <iColumn
        :height="contentHeight"
        class="page-wrapper-content reactive-width"
        :align="myPrimaryAlignment"
        :vertical-align="verticalAlign"
        :width="myPrimaryWidth"
        wrap="nowrap"
        overflow="scroll"
      >
        <iColumn class="page-wrapper-content-header">
          <iRow v-if="breadcrumbs">
            <iBreadcrumbs
              :links="breadcrumbs"
              :current-page="pageName"
            />
          </iRow>
          <iRow v-if="title" align="between">
            <iColumn width="hug" gap="none">
              <iHeading>
                {{ title }}
              </iHeading>
              <iText v-if="subTitle && subTitleType === 'iText'" font-size="standard">
                {{ subTitle }}
              </iText>
            </iColumn>
            <iColumn
              v-if="actionButtonText"
              width="hug"
              height="hug"
              vertical-align="middle"
            >
              <iRow>
                <iLink
                  v-if="actionButtonHref"
                  :href="actionButtonHref"
                >
                  <iButton width="hug" variant="secondary">
                    {{ actionButtonText }}
                  </iButton>
                </iLink>
                <iButton
                  v-else
                  width="hug"
                  variant="secondary"
                  @click="emitActionClick"
                >
                  {{ actionButtonText }}
                </iButton>
                <iButton
                  v-if="secondaryButtonText"
                  width="hug"
                  variant="primary"
                  @click="emitSecondaryButtonAction"
                >
                  {{ secondaryButtonText }}
                </iButton>
              </iRow>
            </iColumn>
          </iRow>
          <iRow v-if="subTitle && subTitleType === 'iSubHeading'">
            <iSubHeading>{{ subTitle }}</iSubHeading>
          </iRow>
        </iColumn>
        <iColumn
          height="fill"
          wrap="nowrap"
          class="primary-content"
          overflow="scroll"
        >
          <slot />
        </iColumn>
      </iColumn>
    </iRow>
  </iColumn>
</template>

<script>
import { useGeneralStore } from "%/stores/general-store";
import MainNav from "@/components/MainNav";
import BillingSideNav from "@/components/side-nav/BillingSideNav";
import MyStoriesSideNav from "@/components/side-nav/MyStoriesSideNav";
import CommunitySideNav from "@/components/side-nav/CommunitySideNav";
import DigitalWalletSideNav from "@/components/side-nav/DigitalWalletSideNav";
import MonetizationSideNav from "@/components/side-nav/MonetizationSideNav";
import PublicationSideNav from "@/components/side-nav/PublicationSideNav";
import SettingsSideNav from "@/components/side-nav/SettingsSideNav";
import AvatarImage from "%/components/AvatarImage";
import {
  SECTION_BILLING,
  SECTION_COMMUNITY,
  SECTION_DIGITAL_WALLET,
  SECTION_HOME,
  SECTION_MONETIZATION,
  SECTION_PUBLICATIONS,
  SECTION_SETTINGS,
  SECTION_STORIES,
} from "@/constants/nav-constants";
import { Enum } from "@bloglovin/vue-component-library";
import { mapActions, mapState } from "pinia";
import FeatureConfig from "%/components/FeatureConfig.vue";

export default {
  name: "PageWrapper",
  components: {
    FeatureConfig,
    MainNav,
    PublicationSideNav,
    MonetizationSideNav,
    DigitalWalletSideNav,
    SettingsSideNav,
    CommunitySideNav,
    BillingSideNav,
    AvatarImage,
  },
  props: {
    activePage: {
      type: String,
      required: true,
    },
    activeSection: {
      type: String,
      required: false,
      default: null,
    },
    align: {
      type: Enum,
      required: false,
      default: "left",
      options: ["left", "center"],
    },
    stickySideNav: {
      type: Boolean,
      required: false,
      default: true,
    },
    verticalAlign: {
      type: String,
      required: false,
      default: "top",
      options: ["top", "middle", "bottom"],
    },
    contentHeight: {
      type: Enum,
      required: false,
      default: "fill",
      options: ["fill", "hug"],
    },
    width: {
      type: Enum,
      required: false,
      default: "full",
      options: ["full", "fixed"],
    },
    breadcrumbs: {
      type: Array,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    subTitle: {
      type: String,
      required: false,
      default: "",
    },
    subTitleType: {
      type: Enum,
      required: false,
      default: "iSubHeading",
      options: ["iSubHeading", "iText"],
    },
    pageName: {
      type: String,
      required: false,
      default: "",
    },
    actionButtonText: {
      type: String,
      required: false,
      default: "",
    },
    actionButtonHref: {
      type: String,
      required: false,
      default: "",
    },
    secondaryButtonText: {
      type: String,
      required: false,
      default: "",
    },
    hideSidebar: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["click:action", "secondary-button-click"],
  data() {
    return {
      SECTION_HOME,
      SECTION_PUBLICATIONS,
      SECTION_MONETIZATION,
      SECTION_DIGITAL_WALLET,
      SECTION_SETTINGS,
      SECTION_COMMUNITY,
      SECTION_BILLING,
      isSideBarOpen: false,
      screenWidth: window.innerWidth,
    };
  },
  styleGuide: () => ({
    contentPaddingTop: { "size.spacing": "small" },
    contentPaddingBottom: { "size.spacing": "large" },
    contentPaddingLeft: { "size.spacing": "standard" },
    contentPaddingRight: { "size.spacing": "standard" },
    sidebarPaddingTop: { "size.spacing": "small" },
  }),
  computed: {
    ...mapState(useGeneralStore, ["userAvatar", "username", "impersonatorUsername", "impersonatorAvatar", "isFakeLoggedIn"]),
    myPrimaryAlignment() {
      return this.align;
    },
    myPrimaryWidth() {
      if (this.width === "fixed") {
        return 1200;
      }
      return "fill";
    },
    currentSideNav() {
      switch (this.activeSection) {
        case SECTION_COMMUNITY:
          return CommunitySideNav;
        case SECTION_DIGITAL_WALLET:
          return DigitalWalletSideNav;
        case SECTION_MONETIZATION:
          return MonetizationSideNav;
        case SECTION_PUBLICATIONS:
          return PublicationSideNav;
        case SECTION_SETTINGS:
          return SettingsSideNav;
        case SECTION_BILLING:
          return BillingSideNav;
        case SECTION_STORIES:
          return MyStoriesSideNav;
      }
      return "";
    },
    showSideBar() {
      return this.screenWidth > 768 || this.isSideBarOpen;
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  methods: {
    ...mapActions(useGeneralStore, ["cancelFakeLogin"]),
    toggleSidebar() {
      this.isSideBarOpen = !this.isSideBarOpen;
    },
    onResize() {
      this.screenWidth = window.innerWidth;
    },
    closeSidebar() {
      this.isSideBarOpen = false;
    },
    emitActionClick(event) {
      this.$emit("click:action", event);
    },
    emitSecondaryButtonAction(event) {
      this.$emit("secondary-button-click", event);
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.mobile-menu-trigger) {
  padding-left: 10px;
  margin-bottom: 8px;
}

.page-wrapper {
  height: 100%;

  &-content {
    height: 100%;

    padding-top: v-bind("$getStyles.contentPaddingTop");
    padding-bottom: v-bind("$getStyles.contentPaddingBottom");
    padding-left: v-bind("$getStyles.contentPaddingLeft");
    padding-right: v-bind("$getStyles.contentPaddingRight");
  }
}

.nav-container {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  max-width: 1515px;
  margin-inline: auto;
}

.hamburger {
  @include tablet {
    display: none;
  }

  @include desktop {
    display: none;
  }
}

.side-navigation {
  padding-top: v-bind("$getStyles.sidebarPaddingTop");
  padding-left: 20px;

  @include phone {
    position: absolute;
    z-index: 3;
    background-color: #ffffff;
    border: #e7e5eb 1px solid;
    border-radius: 8px;
    padding-left: 10px;
  }
}

.site-content {
  max-height: calc(100vh - 80px); // fill all available space after nav
  max-width: 1515px;
  margin-inline: auto;

  @include phone {
    position: relative;
  }
}

:deep(.reactive-width.col.width-fixed) {
  min-width: unset;
}
</style>
