import { defineFeedEntityPiniaStore } from "@bloglovin/vue-framework";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { useGeneralStore } from "%/stores/general-store";
import { useStoryCommentFeedStore } from "SmartFeed/stores/feed/story-comment-feed-store";
export const useStoryCommentEntityStore = defineFeedEntityPiniaStore("story-comment-entity-store", {
  state: () => ({
    loadingUpvoteMessage: {},
    loadingDownvoteMessage: {},
    loadingLikeMessage: {},
    loadingDeleteMessage: {},
  }),
  getters: {},
  actions: {
    async upvoteMessage(messageId, successHandler) {
      this.loadingUpvoteMessage[messageId] = true;
      await this.apiPost(
        `/message/${messageId}/upvote`,
        {},
        response => {
          new SmartFeedEngine().addEntities(response.entities);
          successHandler(response.entities.storyComment[messageId]);
        },
        error => {
          if (error.response.data.exception === "Bloglovin\\Framework\\Middleware\\Exceptions\\SecurityControlException") {
            window.location.href = useGeneralStore().links.web + "/login";
          }
        },
        () => {
          this.loadingUpvoteMessage[messageId] = false;
        },
      );
    },
    async downvoteMessage(messageId, successHandler) {
      this.loadingDownvoteMessage[messageId] = true;
      await this.apiPost(
        `/message/${messageId}/downvote`,
        {},
        response => {
          new SmartFeedEngine().addEntities(response.entities);
          successHandler(response.entities.storyComment[messageId]);
        },
        error => {
          if (error.response.data.exception === "Bloglovin\\Framework\\Middleware\\Exceptions\\SecurityControlException") {
            window.location.href = useGeneralStore().links.web + "/login";
          }
        },
        () => {
          this.loadingDownvoteMessage[messageId] = false;
        },
      );
    },
    async removeDownvoteFromMessage(messageId, successHandler) {
      this.loadingDownvoteMessage[messageId] = true;

      await this.apiPost(
        `/message/${messageId}/revoke-downvote`,
        {},
        response => {
          new SmartFeedEngine().addEntities(response.entities);
          successHandler(response.entities.storyComment[messageId]);
        },
        error => {
          if (error.response.data.exception === "Bloglovin\\Framework\\Middleware\\Exceptions\\SecurityControlException") {
            window.location.href = useGeneralStore().links.web + "/login";
          }
        },
        () => {
          this.loadingDownvoteMessage[messageId] = false;
        },
      );
    },
    async removeUpvoteFromMessage(messageId, successHandler) {
      this.loadingUpvoteMessage[messageId] = true;

      await this.apiPost(
        `/message/${messageId}/revoke-upvote`,
        {},
        response => {
          new SmartFeedEngine().addEntities(response.entities);
          successHandler(response.entities.storyComment[messageId]);
        },
        error => {
          if (error.response.data.exception === "Bloglovin\\Framework\\Middleware\\Exceptions\\SecurityControlException") {
            window.location.href = useGeneralStore().links.web + "/login";
          }
        },
        () => {
          this.loadingUpvoteMessage[messageId] = false;
        },
      );
    },
    async likeMessage(messageId, successHandler) {
      this.loadingLikeMessage[messageId] = true;
      await this.apiPost(
        `/message/${messageId}/like`,
        {},
        response => {
          new SmartFeedEngine().addEntities(response.entities);
          successHandler(response.entities.storyComment[messageId]);
        },
        error => {
          if (error.response.data.exception === "Bloglovin\\Framework\\Middleware\\Exceptions\\SecurityControlException") {
            window.location.href = useGeneralStore().links.web + "/login";
          }
        },
        () => {
          this.loadingLikeMessage[messageId] = false;
        },
      );
    },
    async deleteMessage(messageId) {
      this.loadingDeleteMessage[messageId] = true;
      await this.apiPost(
        `/message/${messageId}/delete`,
        {},
        () => {
          useStoryCommentFeedStore().reload();
        },
        () => {},
        () => {
          this.loadingDeleteMessage[messageId] = false;
        },
      );
    },
    async unlikeMessage(messageId, successHandler) {
      this.loadingLikeMessage[messageId] = true;
      await this.apiPost(
        `/message/${messageId}/revoke-like`,
        {},
        response => {
          new SmartFeedEngine().addEntities(response.entities);
          successHandler(response.entities.storyComment[messageId]);
        },
        error => {
          if (error.response.data.exception === "Bloglovin\\Framework\\Middleware\\Exceptions\\SecurityControlException") {
            window.location.href = useGeneralStore().links.web + "/login";
          }
        },
        () => {
          this.loadingLikeMessage[messageId] = false;
        },
      );
    },
  },
});
