import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { useFanStoryStore } from "~/stores/fan-story-store";

export const useStoryCommentFeedStore = defineSmartFeedPiniaStore("story-comment-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    metadata: {
      storyId: useFanStoryStore().storyId,
    },
  }),
  getters: {
    feedUrl() {
      return `/story/${this.metadata.storyId}/comments`;
    },
  },
});
