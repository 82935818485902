<template>
  <iModal
    :visible="showAddEventModal"
    :show-secondary-action-button="false"
    :max-width="550"
    :title="eventModalTitle"
    :primary-action-button-loading="isLoading"
    :primary-action-button-disabled="isLoading || isUploadingImage"
    :primary-action-button-label="eventModalSubmitButtonLabel"
    @close="closeAddEventModal"
    @click:primary="updateOrCreateEvent"
  >
    <iColumn>
      <iTextInput
        ref="title"
        v-model="title"
        label="Title"
        placeholder="Title"
        type="text"
        required
        :focus="titleFocus"
        @keydown.enter="updateOrCreateEvent"
      />
      <iTextArea
        v-model="description"
        label="Description"
        placeholder="Description"
        type="text"
        width="fill"
        @keydown.enter="updateOrCreateEvent"
      />
      <iDatePicker
        :model-value="eventDatePickerValue"
        label="Event Date"
        placeholder="Event Date"
        @update:model-value="updateEventTimestamp"
        @keydown.enter="updateOrCreateEvent"
      />
      <iSpace :height="10" />
      <iRow>
        <iImageContainer
          v-if="imageUrl"
          :src="imageUrl"
          :height="100"
          width="fill"
        />
        <iUploader @upload-file="uploadImage">
          <iButton
            variant="secondary"
            size="small"
            :is-loading="isUploadingImage"
            :disabled="isUploadingImage"
          >
            {{ imageUrl ? "Change" : "Select" }} Image
          </iButton>
        </iUploader>
      </iRow>
      <iText v-if="addEventError" variant="error">
        {{ addEventError }}
      </iText>
    </iColumn>
  </iModal>
</template>

<script>
import { mapState, mapActions, mapWritableState } from "pinia";
import { useEventStore } from "@/stores/event-store";

export default {
  name: "CreateUpdateEventModal",
  data() {
    return {
      titleFocus: false,
    };
  },
  computed: {
    ...mapState(useEventStore, ["isLoading", "isUploadingImage"]),
    ...mapWritableState(useEventStore, [
      "eventImageUrl",
      "addEventError",
      "title",
      "description",
      "imageUrl",
      "eventTimestamp",
      "showAddEventModal",
      "editEventId",
    ]),
    eventModalTitle() {
      return this.editEventId ? "Edit Event" : "Add Event";
    },
    eventModalSubmitButtonLabel() {
      return this.editEventId ? "Update" : "Create";
    },
    eventDatePickerValue() {
      const date = new Date(this.eventTimestamp * 1000);
      return date.toISOString().split("T")[0];
    },
  },
  watch: {
    showAddEventModal(newValue) {
      if (newValue) {
        this.titleFocus = true;
      }
    },
  },
  methods: {
    ...mapActions(useEventStore, ["createEvent", "uploadImage", "updateEvent"]),
    closeAddEventModal() {
      this.addEventError = null;
      this.showAddEventModal = false;
    },
    updateOrCreateEvent() {
      if (!this.title) {
        this.addEventError = "An event title is required";
        return;
      }

      if (!this.eventTimestamp) {
        this.addEventError = "An event date is required";
        return;
      }

      if (this.editEventId) {
        this.updateEvent();
      } else {
        this.createEvent();
      }
    },
    updateEventTimestamp(modelValue) {
      this.eventTimestamp = Math.round(modelValue);
    },
  },
};
</script>
