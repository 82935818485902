<template>
  <iRow>
    <iRow align="center">
      <iDisplayText>Page not found</iDisplayText>
    </iRow>
    <iRow align="center">
      <iParagraph text-align="center">
        Every journey has its detours, and not all paths lead to where we intend.
        But in every wrong turn, there's a chance to find something new.
        This page may not be what you sought, but perhaps it’s a reminder that the search itself holds value.
      </iParagraph>
    </iRow>
    <iRow align="center">
      <iLink :href="homeUrl">
        <iButton>Go Back Home</iButton>
      </iLink>
    </iRow>
  </iRow>
</template>

<script>

import { useGeneralStore } from "&/stores/general-store";

export default {
  name: "404",
  computed: {
    homeUrl() {
      return useGeneralStore().links.web;
    },
  },
};
</script>
