<template>
  <template v-if="isApplicationsEnabled">
    <iColumn width="hug">
      <template v-if="collaboratorRelationship === 'none'">
        <iColumn v-if="!isOwningOrg">
          <iSubHeading v-if="showTitle" font-weight="standard">
            Apply To Collaborate
          </iSubHeading>
          <iText v-if="showExplanationText" variant="subtle">
            You can apply to collaborate on this publication and create content together
          </iText>
          <iButton
            :size="buttonSize"
            :variant="buttonVariant"
            @click="openModal"
          >
            {{ applyButtonText }}
          </iButton>
        </iColumn>
      </template>
      <template v-else>
        <iText variant="subtle">
          You are currently a {{ collaboratorRelationship }} of this publication
        </iText>
      </template>
    </iColumn>

    <iModal
      v-if="applyToCollaborateModalOpen"
      title="Apply to Collaborate"
      :max-width="600"
      @close="closeModal"
    >
      <template #body>
        <iColumn gap="large">
          <iSubHeading v-if="showRoleText" width="fill">
            You are applying to be a {{ preSelectedRole }}
          </iSubHeading>
          <iColumn gap="none">
            <iSelect
              v-if="!showRoleText"
              v-model="selectedRole"
              :items="ROLE_OPTIONS"
              label="Role"
              width="fill"
              value-field="value"
            />
            <iText v-if="validationErrors.role" variant="error">
              {{ validationErrors.role }}
            </iText>
          </iColumn>
          <iColumn gap="none">
            <iTextArea
              v-model="applicationMessage"
              label="Application Message"
              placeholder="Enter your message here..."
              :rows="5"
              width="fill"
            />
            <iText v-if="validationErrors.message" variant="error">
              {{ validationErrors.message }}
            </iText>
          </iColumn>

          <iText v-if="error" variant="error">
            {{ error }}
          </iText>
        </iColumn>
      </template>
      <template #footer>
        <iRow align="between" gap="standard" width="fill">
          <iColumn width="hug">
            <iButton
              variant="secondary"
              @click="closeModal"
            >
              Cancel
            </iButton>
          </iColumn>
          <iColumn width="hug">
            <iButton
              variant="primary"
              loading-spinner-position="inside"
              :is-loading="isCreatingApplication"
              :disabled="isDisabled"
              @click="submitApplication"
            >
              Submit Application
            </iButton>
          </iColumn>
        </iRow>
      </template>
    </iModal>
  </template>
</template>

<script>
import { ROLE_OPTIONS } from "@/constants/collaborator-constants";
import { useUserStore } from "@/stores/user-store";
import { Enum } from "@bloglovin/vue-component-library";
import { mapActions, mapState, mapWritableState } from "pinia";
import { useCollaboratorStore } from "@/stores/collaborator-store";
import { useGeneralStore } from "%/stores/general-store";

export default {
  name: "ApplyToCollaborate",
  props: {
    publicationId: {
      type: Number,
      required: true,
    },
    orgId: {
      type: Number,
      required: true,
    },
    showExplanationText: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    applyButtonText: {
      type: String,
      default: "Apply Now",
    },
    buttonSize: {
      type: Enum,
      options: ["small", "standard", "large"],
      default: "standard",
    },
    buttonVariant: {
      type: Enum,
      options: ["primary", "secondary"],
      default: "secondary",
    },
    showRoleText: {
      type: Boolean,
      default: false,
    },
    defaultRole: {
      type: String,
      default: "",
    },
    collaboratorRelationship: {
      type: String,
      default: "none",
    },
    isApplicationsEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ROLE_OPTIONS,
      preSelectedRole: this.defaultRole,
      applyToCollaborateModalOpen: false,
      selectedRole: {},
      applicationMessage: "",
      validationErrors: {
        role: "",
        message: "",
      },
    };
  },
  computed: {
    ...mapState(useUserStore, ["userData", "emailData"]),
    ...mapState(useGeneralStore, ["getOrgData"]),
    ...mapState(useCollaboratorStore, ["isCreatingApplication"]),
    ...mapWritableState(useCollaboratorStore, ["error"]),
    isOwningOrg() {
      return this.getOrgData.org_id === this.orgId;
    },
    isFormValid() {
      return (
        (this.selectedRole.value || this.preSelectedRole)
          && this.applicationMessage
      );
    },
    isDisabled() {
      return !this.isFormValid || this.isCreatingApplication;
    },
  },
  methods: {
    ...mapActions(useCollaboratorStore, [
      "createApplication",
    ]),
    openModal() {
      this.applyToCollaborateModalOpen = true;
      this.error = null;
    },
    closeModal() {
      this.applyToCollaborateModalOpen = false;
      this.selectedRole = {};
      this.applicationMessage = "";
      this.error = null;
      this.validationErrors.role = "";
      this.validationErrors.message = "";
    },
    async submitApplication() {
      this.validationErrors.role = "";
      this.validationErrors.message = "";

      if (!this.showRoleText && !this.selectedRole.value) {
        this.validationErrors.role = "Please select a role";
      }

      if (!this.applicationMessage) {
        this.validationErrors.message = "Application message is required";
      }

      if (this.validationErrors.role || this.validationErrors.message) {
        return;
      }

      await this.createApplication(
        this.publicationId,
        this.orgId,
        this.getOrgData.org_id,
        this.emailData.email_address,
        this.userData.first_name + " " + this.userData.last_name,
        this.selectedRole.value || this.preSelectedRole,
        this.closeModal,
      );
    },
  },
};
</script>
