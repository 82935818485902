<template>
  <SplashWrapper :hide-buttons="true">
    <iColumn
      align="center"
      vertical-align="middle"
      width="fill"
      height="fill"
      gap="extraLarge"
    >
      <iColumn :width="500" align="center" gap="large">
        <iRow align="center">
          <iIcon icon="exclamation-triangle" size="large" variant="error" />
        </iRow>
        <iRow align="center">
          <iHeading align="center" variant="standard">
            Invitation No Longer Valid
          </iHeading>
        </iRow>
        <iParagraph text-align="center">
          We're sorry, but the invitation you're trying to access is no longer valid or has expired.
        </iParagraph>
      </iColumn>
    </iColumn>
  </SplashWrapper>
</template>

<script>
import SplashWrapper from "@/components/splash/SplashWrapper";

export default {
  name: "InvitationError",
  components: {
    SplashWrapper,
  },
};
</script>
