import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";

export const useCommunityPublicationStoryFeedStore = defineSmartFeedPiniaStore("community-publication-story-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    metadata: {
      lastId: Number.MAX_SAFE_INTEGER,
      publicationId: useCreatorPublicationStore().publicationId,
    },
    tableFields: [
      { key: "storyTitle", label: "Title" },
      { key: "author", label: "Author" },
      { key: "status", label: "Status" },
      { key: "variants", label: "Variants" },
      { key: "timestampUpdated", label: "Edited" },
      { key: "timestampCreated", label: "Created" },
      { key: "actions", label: "" },
    ],
  }),
  getters: {
    feedUrl() {
      return `/publication/${this.metadata.publicationId}/community-story/smart-feed`;
    },
  },
});
