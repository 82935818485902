<template>
  <iModal
    v-if="showModal"
    title="Invite to Collaborate"
    :max-width="650"
    primary-action-button-label="Send Invite"
    :primary-action-button-loading="isCreatingOnPlatformInvitation"
    @close="onClose"
    @click:primary="onSendInvite"
  >
    <template #body>
      <iColumn gap="large">
        <iColumn gap="none">
          <iAutoComplete
            label="Publication"
            :items="formattedPublications"
            :model-value="publication"
            width="fill"
            item-value-key="value"
            item-label-key="label"
            placeholder="Search for a publication"
            @update:model-value="handleChange"
          />
          <iText v-if="validationErrors.publication" variant="error">
            {{ validationErrors.publication }}
          </iText>
        </iColumn>

        <iColumn gap="none">
          <iSelect
            v-model="selectedRole"
            label="Role"
            :items="ROLE_OPTIONS"
            value-field="value"
            text-field="name"
            empty-string="Choose a role"
            width="fill"
          />
          <iText v-if="validationErrors.role" variant="error">
            {{ validationErrors.role }}
          </iText>
        </iColumn>

        <iColumn gap="none">
          <iTextArea
            v-model="inviteMessage"
            label="Invite Message"
            placeholder="Enter invitation message..."
            :rows="5"
            width="fill"
            @enter="onSendInvite"
          />
        </iColumn>

        <iColumn v-if="collaborationCounterOfferEnabled">
          <iSubHeading>Collaboration Timeline</iSubHeading>
          <iTimeLine
            :items="timelineItems"
          />
        </iColumn>

        <iColumn v-if="collaborationCounterOfferEnabled">
          <iTextInput
            v-model="compensationDetails"
            label="Compensation"
            placeholder="Enter compensation details..."
            width="fill"
          />
          <iText variant="subtle">
            This will be replaced with a final design in a future update.
          </iText>
        </iColumn>
      </iColumn>
      <iText v-if="error" variant="error">
        {{ error }}
      </iText>
    </template>
  </iModal>
</template>

<script>
import { usePublicationFeedStore } from "SmartFeed/stores/feed/publication-feed-store";
import { usePublicationEntityStore } from "SmartFeed/stores/entity/publication-entity-store";
import { ROLE_OPTIONS } from "@/constants/collaborator-constants";
import { mapActions, mapState, mapWritableState } from "pinia";
import { useFeatureStore } from "%/stores/feature-store";
import { useCollaboratorStore } from "@/stores/collaborator-store";
import { useGeneralStore } from "%/stores/general-store";

export default {
  name: "InviteToCollaborateOnPlatform",
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    orgName: {
      type: String,
      required: true,
    },
    orgId: {
      type: Number,
      required: true,
    },
  },
  emits: ["close", "update:modelValue"],
  data() {
    return {
      inviteMessage: "",
      publication: "",
      selectedRole: {},
      compensationDetails: "",
      validationErrors: {
        publication: "",
        role: "",
      },
      timelineItems: [
        {
          title: "Collaboration Start",
          description: "Project start date: TBD",
          primary: true,
        },
        {
          title: "Milestones",
          description: "Key deliverables (placeholder)",
          bubbleSmall: false,
        },
        {
          title: "End of Collaboration",
          description: "Closing / Handover (placeholder)",
          secondary: true,
        },
      ],
      ROLE_OPTIONS,
    };
  },
  computed: {
    ...mapState(useCollaboratorStore, [
      "isCreatingOnPlatformInvitation",
    ]),
    ...mapState(useGeneralStore, ["orgData"]),
    ...mapWritableState(useCollaboratorStore, ["error"]),
    collaborationCounterOfferEnabled() {
      return useFeatureStore().isFeatureEnabled("collaborationCounterOffer");
    },
    publications() {
      return Object.values(usePublicationEntityStore().entities);
    },
    formattedPublications() {
      return this.publications.map(item => ({
        value: item.publicationId,
        label: item.name,
      }));
    },
  },
  mounted() {
    usePublicationFeedStore().getSmartFeed();
  },
  methods: {
    ...mapActions(useCollaboratorStore, [
      "createOnPlatformInvitation",
    ]),
    onClose() {
      this.error = "";
      this.validationErrors.publication = "";
      this.validationErrors.role = "";
      this.inviteMessage = "";
      this.publication = "";
      this.selectedRole = {};
      this.compensationDetails = "";
      this.$emit("close");
    },
    handleChange(value) {
      this.publication = value;
      this.$emit("update:modelValue", this.publication);
    },
    async onSendInvite() {
      this.validationErrors.publication = "";
      this.validationErrors.role = "";

      if (!this.publication) {
        this.validationErrors.publication = "Please select a publication.";
      }

      if (!this.selectedRole.value) {
        this.validationErrors.role = "Please select a role.";
      }

      if (this.validationErrors.publication || this.validationErrors.role) {
        return;
      }

      // TODO: add org email

      await this.createOnPlatformInvitation(
        this.publication,
        this.orgId,
        this.orgData.org_id,
        this.orgName,
        this.selectedRole.value,
        this.onClose,
      );
    },
  },
};
</script>
