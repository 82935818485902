import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";

export const useMyPublicationSupportConversationFeedStore = defineSmartFeedPiniaStore("my-publication-support-conversation-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    metadata: {
      lastId: Number.MAX_SAFE_INTEGER,
      publicationId: useCreatorPublicationStore().publicationId,
    },
  }),
  getters: {
    feedUrl() {
      return `publication/${this.metadata.publicationId}/support-conversations/smart-feed`;
    },
  },
});
