<template>
  <PageWrapper
    :active-page="PAGE_PUBLICATION_INVITED_TO_COLLABORATE"
    :active-section="SECTION_PUBLICATIONS"
    title="Invited to Collaborate"
    :breadcrumbs="publicationBreadCrumbs"
    page-name="Invited to Collaborate"
    align="left"
    width="full"
  >
    <iSubHeading>Pending Invitations</iSubHeading>
    <SmartFeed
      :pinia-store="useCollaboratorInvitationFeedStore"
      default-smart-feed-layout="carousel"
      default-entity-layout="cardSmall"
    >
      <template #empty-state>
        <iColumn gap="extraSmall" align="left">
          <iText variant="subtle">
            You currently have no pending invitations to collaborate on any publications.
          </iText>
        </iColumn>
      </template>
    </SmartFeed>

    <SmartFeed
      :pinia-store="useCollaboratingPublicationFeedStore"
      default-entity-layout="cardSmall"
      default-smart-feed-layout="carousel"
    >
      <iSubHeading>
        Collaborating Publications
      </iSubHeading>
      <template #empty-state>
        <iColumn gap="extraSmall" align="left">
          <iText variant="subtle">
            You currently have no collaborating publications.
          </iText>
        </iColumn>
      </template>
    </SmartFeed>
    <InvitationReviewModal
      v-if="isInvitationReviewModalVisible"
      :invitation="selectedInvitationReview"
      @close="closeInvitationReviewModal"
      @accept="onAcceptInvitation"
      @decline="onDeclineInvitation"
      @counter="onCounterInvitation"
    />
    <CounterOfferModal
      v-if="isCounterOfferModalVisible"
      @close="closeCounterOfferModal"
      @submit="onSubmitCounterOffer"
    />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_PUBLICATION_INVITED_TO_COLLABORATE,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import SmartFeed from "SmartFeed/components/SmartFeed.vue";
import { useCollaboratorInvitationFeedStore } from "SmartFeed/stores/feed/collaborator-invitation-feed-store";
import CounterOfferModal from "@/pages/publication/CounterOfferModal.vue";
import InvitationReviewModal from "@/pages/publication/InvitationReviewModal.vue";
import { mapActions, mapState } from "pinia";
import { useCollaboratorStore } from "@/stores/collaborator-store";
import { useCollaboratingPublicationFeedStore } from "SmartFeed/stores/feed/collaborating-publication-feed-store";

export default {
  name: "InvitedToCollaborate",
  components: {
    InvitationReviewModal, CounterOfferModal,
    SmartFeed,
    PageWrapper,
  },
  data() {
    return {
      PAGE_PUBLICATION_INVITED_TO_COLLABORATE,
      SECTION_PUBLICATIONS,
    };
  },
  computed: {
    ...mapState(useCollaboratorStore, [
      "isCounterOfferModalVisible",
      "isInvitationReviewModalVisible",
      "selectedInvitationReview",
    ]),
    publicationBreadCrumbs() {
      return [{ url: "/", label: "Home" }];
    },
  },
  methods: {
    useCollaboratingPublicationFeedStore,
    useCollaboratorInvitationFeedStore,
    ...mapActions(useCollaboratorStore, [
      "acceptInvitation",
      "denyInvitation",
      "openApplicationReviewModal",
      "closeCounterOfferModal",
      "openCounterOfferModal",
      "openInvitationReviewModal",
      "closeInvitationReviewModal",
    ]),
    async onAcceptInvitation() {
      const invitation = this.selectedInvitationReview;
      await this.acceptInvitation(
        invitation.publicationId,
        invitation.orgId,
      );
    },
    async onDeclineInvitation() {
      const invitation = this.selectedInvitationReview;
      await this.denyInvitation(
        invitation.publicationId,
        invitation.orgId,
      );
    },
    onCounterInvitation() {
      this.closeInvitationReviewModal();
      this.openCounterOfferModal();
    },
    onSubmitCounterOffer() {
      this.closeCounterOfferModal();
    },
  },
};
</script>
