<template>
  <PageWrapper
    :active-section="SECTION_MONETIZATION"
    :active-page="PAGE_MONETIZATION_SYNDICATION"
    page-name="Syndication"
    align="left"
    width="fixed"
  >
    <iRow align="between" vertical-align="middle">
      <iHeading>Syndication</iHeading>
      <iLink variant="tertiary" href="javascript:void(0)">
        Change Date Range
      </iLink>
    </iRow>

    <iDataTable
      :fields="syndicationFields"
      :items="syndicationItems"
      primary-key="storyName"
      :has-more="false"
      :is-loading="false"
      hide-actions
    >
      <template #td="{data, value}">
        <iText :font-weight="data.storyName === 'Total' ? 'dark' : 'standard'">
          {{ value }}
        </iText>
      </template>
    </iDataTable>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { SECTION_MONETIZATION, PAGE_MONETIZATION_SYNDICATION } from "@/constants/nav-constants";
import { syndicationFields, syndicationItems } from "@/constants/monetization-fake-data";

export default {
  name: "StorySyndication",
  components: {
    PageWrapper,
  },
  data() {
    return {
      SECTION_MONETIZATION,
      PAGE_MONETIZATION_SYNDICATION,
      syndicationFields,
      syndicationItems,
    };
  },
};
</script>
