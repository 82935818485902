import { defineStore } from "pinia";
import UserPreferenceFailedRetrievalError from "@/errors/user-preference-failed-retrieval-error";

export const useUserPreferenceStore = defineStore("user-preference-store", {
  state: () => ({
    userPreferences: {},
    isUpdating: false,
  }),
  getters: {},
  actions: {
    async getUserPreference(name) {
      if (name in this.userPreferences) {
        return this.userPreferences[name];
      }

      throw new UserPreferenceFailedRetrievalError(`Failed to get data from preferences with '${name}' key.`,
        {
          preferences: this.userPreferences,
          preferenceName: name,
        });
    },
    setUserPreferences(userPreferences) {
      this.userPreferences = userPreferences;
    },
    updateUserPreferenceByName(name, value) {
      this.isUpdating = true;
      this.apiPost("user/preferences",
        {
          preference_name: name,
          value: value,
        },
        data => {
          this.userPreferences = data.userPreferences;
        },
        () => {},
        () => {
          this.isUpdating = false;
        });
    },
  },
});
