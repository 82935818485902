<template>
  <iColumn gap="extraSmall">
    <iRow width="hug" wrap="nowrap" vertical-align="middle">
      <iSubHeading>Timeline</iSubHeading>
    </iRow>
    <iRow width="hug" wrap="nowrap" vertical-align="middle">
      <iText>Start</iText>
    </iRow>

    <iRow width="hug" wrap="nowrap" vertical-align="middle">
      <input
        v-model="timelineStartType"
        name="timelineStart"
        type="radio"
        value="immediately"
        style="height:30px; width: 20px; accent-color: black;"
      >
      <iText> Immediately</iText>
    </iRow>
    <iRow width="hug" wrap="nowrap" vertical-align="middle">
      <input
        v-model="timelineStartType"
        name="timelineStart"
        type="radio"
        value="onDate"
        style="height:30px; width: 20px; accent-color: black;"
      >
      <iText width="hug" :wrap="false">
        On Date
      </iText>
      <iSpace :width="5" />
      <iColumn width="hug" vertical-align="middle" :height="40">
        <iDatePicker v-model="timelineEndDate" :disabled="timelineStartType === 'immediately'" />
      </iColumn>
    </iRow>
    <iRow width="hug" wrap="nowrap" vertical-align="middle">
      <iText>End</iText>
    </iRow>
    <iRow width="hug" wrap="nowrap" vertical-align="middle">
      <input
        v-model="timelineEndType"
        type="radio"
        name="timelineEnd"
        value="ongoing"
        style="height:30px; width: 20px; accent-color: black;"
      >
      <iText>Ongoing</iText>
    </iRow>
    <iRow width="hug" wrap="nowrap" vertical-align="middle">
      <input
        v-model="timelineEndType"
        name="timelineEnd"
        type="radio"
        value="onDate"
        style="height:30px; width: 20px; accent-color: black;"
      >
      <iText width="hug" :wrap="false">
        On Date
      </iText>
      <iSpace :width="5" />
      <iColumn width="hug" vertical-align="middle" :height="40">
        <iDatePicker v-model="timelineEndDate" :disabled="timelineEndType === 'ongoing'" class="date-picker" />
      </iColumn>
    </iRow>
    <iSpace :height="10" />
  </iColumn>

  <iColumn gap="extraSmall">
    <iRow>
      <iSubHeading>Compensation</iSubHeading>
    </iRow>
    <iRow width="hug" wrap="nowrap" vertical-align="middle">
      <iCheckbox v-model="flatFeeEnabled" />
      <iText :wrap="false">
        Flat Fee
      </iText>
      <iTextInput
        v-model="flatFeeAmount"
        size="small"
        :disabled="!flatFeeEnabled"
        type="number"
        :width="100"
      >
        <template #prepend>
          $
        </template>
      </iTextInput>
    </iRow>

    <iRow width="hug" wrap="nowrap" vertical-align="middle">
      <iCheckbox v-model="revShareEnabled" />
      <iText :wrap="false">
        Rev Share
      </iText>
    </iRow>

    <iRow
      width="hug"
      wrap="nowrap"
      vertical-align="middle"
      gap="none"
      style="margin-left: 10px"
    >
      <iColumn>
        <iRow width="hug" wrap="nowrap" vertical-align="middle">
          <input
            v-model="revShareType"
            name="revShareType"
            type="radio"
            :disabled="!revShareEnabled"
            value="guaranteed"
            style="height:30px; width: 20px; accent-color: black;"
          >
          <iText>Guaranteed</iText>
          <iTextInput
            v-model="guaranteedPercentValue"
            size="small"
            type="number"
            :width="70"
            :disabled="revShareType === 'performanceAllocation'"
          >
            <template #append>
              %
            </template>
          </iTextInput>
        </iRow>
        <iRow width="hug" wrap="nowrap" vertical-align="middle">
          <input
            v-model="revShareType"
            name="revShareType"
            type="radio"
            :disabled="!revShareEnabled"
            value="performanceAllocation"
            style="height:30px; width: 20px; accent-color: black;"
          >
          <iText>Performance Allocation</iText>
        </iRow>
        <iRow
          width="hug"
          wrap="nowrap"
          vertical-align="middle"
          style="margin-left:6px"
        >
          <iCheckbox v-model="guaranteedMinimumEnabled" :disabled="!revShareEnabled" />
          <iText :wrap="false">
            Guaranteed Minimum
          </iText>
          <iTextInput
            v-model="guaranteedMinimumValue"
            type="number"
            size="small"
            :disabled="!revShareEnabled"
            :width="100"
          >
            <template #prepend>
              $
            </template>
          </iTextInput>
        </iRow>
        <iRow
          width="hug"
          wrap="nowrap"
          vertical-align="middle"
          style="margin-left:6px"
        >
          <iCheckbox v-model="performanceMaximumEnabled" :disabled="!revShareEnabled" />
          <iText :wrap="false">
            Maximum
          </iText>
          <iTextInput
            v-model="performanceMaximumValue"
            type="number"
            size="small"
            :disabled="!revShareEnabled"
            :width="100"
          >
            <template #prepend>
              $
            </template>
          </iTextInput>
        </iRow>
      </iColumn>
    </iRow>
  </iColumn>

  <iColumn gap="extraSmall">
    <iRow width="hug" wrap="nowrap" vertical-align="middle">
      <iSubHeading>Scope of Work</iSubHeading>
    </iRow>
    <iRow width="fill" wrap="nowrap" vertical-align="middle">
      <iTextArea
        v-model="scopeOfWorkDetails"
        width="fill"
        placeholder="This scope outlines tasks and deliverables for this collaboration..."
      />
    </iRow>
  </iColumn>
</template>

<script>
export default {
  name: "CollaborationContractProposal",
  data() {
    return {
      flatFeeEnabled: true,
      flatFeeAmount: "",
      revShareEnabled: false,
      guaranteedPercentSelected: false,
      guaranteedPercentValue: "",
      performanceAllocation: false,
      guaranteedMinimumEnabled: false,
      guaranteedMinimumValue: "",
      performanceMaximumEnabled: false,
      performanceMaximumValue: "",
      scopeOfWorkDetails: "",
      timelineStartImmediately: true,
      timelineStartOnDate: false,
      timelineStartDate: "",
      timelineStartType: "immediately",
      timelineEndOngoing: true,
      timelineEndOnDate: false,
      timelineEndDate: "",
      timelineEndType: "ongoing",
      revShareType: "",
    };
  },

};
</script>

<style scoped lang="scss">
:deep(input[type="date"]) {
  width: 150px !important;
  height: 40px;
}
</style>
