<template>
  <iRow
    v-if="isChannelUser"
    align="between"
    class="manage-users"
    width="fill"
  >
    <iRow
      vertical-align="middle"
      class="add-people-action"
      width="hug"
      wrap="nowrap"
      @click="openAddUserModal"
    >
      <iColumn
        :width="40"
        :height="40"
        class="add-user-icon"
        vertical-align="middle"
        align="center"
      >
        <iIcon icon="person" variant="light" />
      </iColumn>
      <iText>Add people</iText>
    </iRow>
  </iRow>
  <iSpace />
  <iColumn gap="small">
    <SmartFeed
      :pinia-store="useChannelUserFeedStore"
      default-entity-layout="cardSmall"
      default-smart-feed-layout="list"
    />
  </iColumn>
  <iModal
    v-if="showAddUserModal"
    :max-width="600"
    :show-primary-action-button="false"
    :show-secondary-action-button="false"
    :visible="showAddUserModal"
    title="Invite People to Channel"
    @close="closeAddUserModal"
  >
    <iColumn width="fill">
      <iAutoComplete
        v-model="searchTerm"
        :items="publicationUsersNotInChannel"
        item-label-key="firstName"
        item-value-key="userId"
        placeholder="Search for users"
        @item-selected="selectedUser = $event"
      />
      <iRow align="right">
        <iButton
          :disabled="!selectedUser"
          variant="primary"
          loading-spinner-position="inside"
          :is-loading="isLoadingInviteToChannel"
          @click="inviteToChannel(selectedUser.userId)"
        >
          Add
        </iButton>
      </iRow>
    </iColumn>
  </iModal>
</template>

<script>
import { mapActions, mapState } from "pinia";
import SmartFeed from "SmartFeed/components/SmartFeed.vue";
import { useFanPublicationStore } from "%/stores/fan-publication-store";
import { useGeneralStore } from "%/stores/general-store";
import { useChannelUserFeedStore } from "SmartFeed/stores/feed/channel-user-feed-store";
import { useChannelPublicationUserFeedStore } from "SmartFeed/stores/feed/channel-publication-user-feed-store";
import { usePublicationUsersEntityStore } from "SmartFeed/stores/entity/publication-users-entity-store";
import { useChannelUserEntityStore } from "SmartFeed/stores/entity/channel-user-entity-store";
import { useChannelStore } from "%/stores/channel-store";

export default {
  name: "ManageUsers",
  components: { SmartFeed },
  data() {
    return {
      searchQuery: "",
      activePublicationUsers: [],
      loadingSearchActivePublicationUsers: false,
      searchTerm: "",
      selectedUser: null,
      showAddUserModal: false,
    };
  },
  computed: {
    ...mapState(useFanPublicationStore, ["publicationId"]),
    ...mapState(useChannelStore, ["isChannelUser", "isLoadingInviteToChannel"]),
    ...mapState(useGeneralStore, ["fanUrl"]),
    ...mapState(usePublicationUsersEntityStore, { publicationUserEntities: "entities" }),
    ...mapState(useChannelPublicationUserFeedStore, {
      publicationUsersHasMore: "hasMore",
      publicationUserFeed: "feed",
    }),
    ...mapState(useChannelUserEntityStore, { channelUserEntities: "entities" }),
    ...mapState(useChannelUserFeedStore, { channelUsersHasMore: "hasMore", channelUserFeed: "feed" }),
    publicationUsers() {
      const entities = {};
      this.publicationUserFeed.forEach(publicationUser => {
        let userIds = Object.keys(this.publicationUserEntities);
        if (userIds.includes(publicationUser.id.toString())) {
          entities[publicationUser.id] = this.publicationUserEntities[publicationUser.id];
        }
      });
      return Object.values(entities);
    },
    channelUsers() {
      const entities = {};
      this.channelUserFeed.forEach(channelUser => {
        let userIds = Object.keys(this.channelUserEntities);
        if (userIds.includes(channelUser.id.toString())) {
          entities[channelUser.id] = this.channelUserEntities[channelUser.id];
        }
      });
      return Object.values(entities);
    },
    publicationUsersNotInChannel() {
      return this.publicationUsers.filter(publicationUser => {
        return !this.channelUsers.some(channelUser => {
          return channelUser.userId === publicationUser.userId;
        });
      });
    },
  },
  mounted() {
    useChannelPublicationUserFeedStore().getSmartFeed();
  },
  methods: {
    ...mapActions(useChannelStore, ["inviteUserToChannel"]),
    useChannelUserFeedStore,
    openAddUserModal() {
      this.showAddUserModal = true;
    },
    closeAddUserModal() {
      this.showAddUserModal = false;
    },
    inviteToChannel(userId) {
      this.inviteUserToChannel(userId, () => {
        this.closeAddUserModal();
      });
    },
    username(user) {
      return `${user.firstName} ${user.lastName}`;
    },
  },
  styleGuide: () => ({
    paddingTop: { "size.spacing": "standard" },
    paddingBottom: { "size.spacing": "standard" },
    paddingLeft: { "size.spacing": "standard" },
    paddingRight: { "size.spacing": "standard" },
    paddingSmallTop: { "size.spacing": "small" },
    paddingSmallBottom: { "size.spacing": "small" },
    paddingSmallLeft: { "size.spacing": "small" },
    paddingSmallRight: { "size.spacing": "small" },
    addUserBackgroundColor: { "color.font": "branded" },
    addUserBorderRadius: { "size.borderRadius": "standard" },
  }),
};
</script>
<style lang="scss" scoped>
.add-user-icon {
  background-color: v-bind("$getStyles.addUserBackgroundColor");
  padding-top: v-bind("$getStyles.paddingSmallTop");
  padding-bottom: v-bind("$getStyles.paddingSmallBottom");
  padding-left: v-bind("$getStyles.paddingSmallLeft");
  padding-right: v-bind("$getStyles.paddingSmallRight");
  border-radius: v-bind("$getStyles.addUserBorderRadius");

  &:hover {
    background-color: v-bind("$getStyles.addUserBackgroundColor");
    padding-top: v-bind("$getStyles.paddingSmallTop");
    padding-bottom: v-bind("$getStyles.paddingSmallBottom");
    padding-left: v-bind("$getStyles.paddingSmallLeft");
    padding-right: v-bind("$getStyles.paddingSmallRight");
    border-radius: v-bind("$getStyles.addUserBorderRadius");
  }
}

.add-people-action {
  cursor: pointer;
}

:deep(a.text-link) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  &:hover {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>
