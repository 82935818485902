<template>
  <iColumn
    v-if="isMounted && checkIsChannelLoading"
    class="chatroom-actions-container"
    gap="none"
    height="hug"
    wrap="nowrap"
  >
    <iRow v-if="displayJoinChannel" height="hug">
      <NewChatroomMessage
        :context="context"
        :conversation-id="conversationId"
        :entity-id="entityId"
      />
    </iRow>
    <iColumn
      v-else
      align="center"
      vertical-align="middle"
      width="fill"
      wrap="nowrap"
    >
      <iButton variant="text" @click="openShowDetailsModal">
        <iRow gap="extraSmall" vertical-align="middle" width="hug">
          <iIcon :icon="channelVisibility === 'invite_only' ? 'lock' : 'hashtag'" size="small" />
          <iTitle>{{ channelName }}</iTitle>
        </iRow>
      </iButton>
      <iRow width="hug">
        <iButton variant="secondary" @click="openShowDetailsModal">
          Details
        </iButton>
        <iButton :is-loading="isLoadingJoinChannel" :disabled="!isLoggedIn" @click="joinChannel">
          Join Channel
        </iButton>
      </iRow>
    </iColumn>
  </iColumn>
  <iModal
    :max-width="600"
    :show-primary-action-button="false"
    :show-secondary-action-button="false"
    :visible="showDetailsChannelModal"
    title="Details"
    @close="closeShowDetailsModal"
  >
    <iColumn>
      <iRow vertical-align="middle" wrap="nowrap">
        <iText class="detail-label">
          Channel Name:
        </iText>
        <iTextEditInput
          v-if="isChannelUser && channelName !== 'general'"
          :model-value="channelName"
          edit-cta-style="icon"
          editable-on-click
          inline
          placeholder="Channel Name"
          save-action-style="icon"
          :is-loading="loadingUpdateChannelName"
          @submit="saveChannelName"
        />
        <iText v-else class="detail-value">
          {{ channelName }}
        </iText>
      </iRow>
      <iRow vertical-align="middle" wrap="nowrap">
        <iText class="detail-label">
          Description:
        </iText>
        <iTextEditInput
          v-if="isChannelUser"
          :model-value="description"
          edit-cta-style="icon"
          editable-on-click
          inline
          placeholder="Description"
          save-action-style="icon"
          :is-loading="loadingUpdateChannelDescription"
          @submit="saveChannelDescription"
        />
        <iText v-else class="detail-value">
          {{ description || "No Description" }}
        </iText>
      </iRow>
      <iColumn vertical-align="middle" wrap="nowrap">
        <iText class="detail-label">
          Created By:
        </iText>
        <iRow
          v-if="channelCreator.userId"
          width="fill"
          vertical-align="middle"
          align="between"
        >
          <iLink :href="channelCreatorHref">
            <iRow width="hug" vertical-align="middle">
              <AvatarImage :src="channelCreator.userAvatar" size="35px" />
              <iText>{{ channelCreator.username }}</iText>
            </iRow>
          </iLink>
          <iText
            v-tooltip="formattedTimestamp(channelCreator.timestampJoined, 'DD MMMM YYYY  HH:mm')"
            class="timestamp"
            variant="subtle"
          >
            {{ timeAgo(channelCreatedAt) }}
          </iText>
        </iRow>
      </iColumn>
      <iRow>
        <iButton
          v-if="isChannelUser"
          :is-loading="isLoadingLeaveChannel"
          size="small"
          variant="secondary"
          @click="leaveChannel"
        >
          Leave
        </iButton>
        <iButton
          v-else
          size="small"
          :disabled="!isLoggedIn"
          @click="joinChannel"
        >
          Join
        </iButton>
      </iRow>
    </iColumn>
  </iModal>
  <iModal
    :max-width="600"
    :show-primary-action-button="false"
    :show-secondary-action-button="false"
    :visible="showMembersChannelModal"
    title="Members"
    @close="closeShowMembersModal"
  >
    <ManageUsers />
  </iModal>
  <iModal
    :max-width="600"
    :show-primary-action-button="false"
    :show-secondary-action-button="false"
    :visible="showSettingsChannelModal"
    title="Settings"
    @close="closeShowSettingsModal"
  >
    <iColumn align="center">
      <iText class="subtle-text">
        No Settings
      </iText>
    </iColumn>
  </iModal>
</template>

<script>
import AvatarImage from "%/components/AvatarImage.vue";
import { mapActions, mapState, mapWritableState } from "pinia";
import { useConversationStore } from "%/stores/conversation-store";
import NewChatroomMessage from "%/components/community/chatrooms/slack-style/NewChatroomMessage";
import { useChannelStore } from "%/stores/channel-store";
import ManageUsers from "%/components/community/chatrooms/slack-style/ManageUsers.vue";
import { timeAgo, formattedTimestamp } from "~/helpers/publication-helper";
import { useGeneralStore } from "%/stores/general-store";

export default {
  name: "ChatroomUserActions",
  components: {
    NewChatroomMessage,
    ManageUsers,
    AvatarImage,
  },
  props: {
    context: {
      type: String,
      required: true,
    },
    entityId: {
      type: Number,
      required: true,
    },
    conversationId: {
      type: Number,
      required: false,
      default: 0,
    },
    channelName: {
      type: String,
      required: false,
      default: "",
    },
    description: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      activeTab: "members",
      tabs: [
        { id: "details", label: "Details" },
        { id: "members", label: "Members" },
        { id: "settings", label: "Settings" },
      ],
      isMounted: false,
    };
  },
  computed: {
    ...mapState(useConversationStore, ["conversation"]),
    ...mapWritableState(useConversationStore, [
      "showDetailsChannelModal",
      "showMembersChannelModal",
      "showSettingsChannelModal",
    ]),
    ...mapState(useChannelStore, ["channelId", "isChannelUser", "isLoadingJoinChannel", "isLoadingLeaveChannel", "channelVisibility"]),
    displayJoinChannel() {
      return this.context !== "channel" || this.isChannelUser;
    },
    checkIsChannelLoading() {
      if (this.context !== "channel") {
        return true;
      }
      return typeof this.isChannelUser === "boolean";
    },
    ...mapState(useGeneralStore, ["fanUrl", "isLoggedIn"]),
    ...mapState(useChannelStore, [
      "channelId",
      "isChannelUser",
      "isLoadingJoinChannel",
      "isLoadingLeaveChannel",
      "channelVisibility",
      "channelCreator",
      "channelCreatedAt",
      "loadingUpdateChannelName",
      "loadingUpdateChannelDescription",
    ]),
    modalTitle() {
      return this.tabs[this.tabs.findIndex(tab => tab.id === this.activeTab)].label;
    },
    channelCreatorHref() {
      return `${this.fanUrl}/user-profile/${this.channelCreator.userId}`;
    },
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    timeAgo,
    formattedTimestamp,
    ...mapActions(useChannelStore, [
      "joinChannel",
      "leaveChannel",
      "fetchChannelCreatorDetails",
      "updateChannelName",
      "updateChannelDescription",
    ]),
    openShowDetailsModal() {
      this.showDetailsChannelModal = true;
      this.fetchChannelCreatorDetails();
    },
    openShowMembersModal() {
      this.showMembersChannelModal = true;
    },
    openShowSettingsModal() {
      this.showSettingsChannelModal = true;
    },
    closeShowDetailsModal() {
      this.showDetailsChannelModal = false;
    },
    closeShowMembersModal() {
      this.showMembersChannelModal = false;
    },
    closeShowSettingsModal() {
      this.showSettingsChannelModal = false;
    },
    saveChannelName(channelName) {
      this.updateChannelName(channelName);
    },
    saveChannelDescription(description) {
      this.updateChannelDescription(description);
    },
  },
  styleGuide: () => ({
    labelSize: { "size.font": "standard" },
    valueColor: { "color.font": "subtle" },
  }),
};
</script>

<style lang="scss" scoped>
.detail-label {
  font-size: v-bind('$getStyles.labelSize') !important;

  &:hover {
    font-size: v-bind('$getStyles.labelSize') !important;
  }
}

.subtle-text {
  color: v-bind('$getStyles.valueColor');
  &:hover {
    color: v-bind('$getStyles.valueColor');
  }
}
</style>
