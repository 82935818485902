<template>
  <iColumn>
    <iSubHeading font-size="large">
      Theme Information
    </iSubHeading>
    <iText v-if="editMode" variant="subtle" font-size="verySmall">
      Please note: These fields are not part of version control and
      will be updated when staging or publishing.
    </iText>
    <iTextInput
      v-model="paletteName"
      :style-overrides="inputErrorStyle(`palette-name`)"
      class="size-input"
      :width="300"
      placeholder="Enter a name for the palette"
      label="Theme Palette Name"
    />
    <iSpace :height="10" />
    <iSubHeading font-size="large">
      Theme Palette Configuration
    </iSubHeading>
    <iColumn class="theme-palette-wrapper" width="fill">
      <iColumn v-for="(key, index) in paletteKeys" :key="index">
        <iSubHeading font-size="extraExtraLarge">
          {{ camelToTitleCase(key) }}
        </iSubHeading>
        <iColumn v-for="(subKey, subIndex) in Object.keys(palette[key])" :key="subIndex" class="w-full">
          <iColumn v-if="key === 'fonts'">
            <iColumn v-if="subKey === 'weight'">
              <iSubHeading font-size="large">
                {{ camelToTitleCase(subKey) }}
              </iSubHeading>
              <iRow>
                <iColumn
                  v-for="(weight, weightIndex) in Object.keys(palette[key][subKey])"
                  :key="weightIndex"
                  class="weight-input-container"
                >
                  <iSelect
                    v-model="palette[key][subKey][weight]"
                    class="size-input"
                    return-value
                    value-field="value"
                    :label="camelToTitleCase(weight)"
                    :items="FONT_WEIGHT_OPTIONS"
                    :width="300"
                  />
                </iColumn>
              </iRow>
            </iColumn>
          </iColumn>
          <iColumn v-if="key === 'sizes'" class="w-full">
            <iSubHeading font-size="large">
              {{
                subKey === 'variants' ? camelToTitleCase(subKey) + ' (px)' : camelToTitleCase(subKey)
              }}
            </iSubHeading>
            <iRow>
              <iColumn
                v-for="(sizeKey, sizeIndex) in Object.keys(palette[key][subKey])"
                :key="sizeIndex"
                class="size-input-container"
              >
                <iTextInput
                  :style-overrides="inputErrorStyle(`size-${subKey}-${sizeKey}`)"
                  :model-value="palette[key][subKey][sizeKey].replace('px', '')"
                  class="size-input"
                  :width="300"
                  :label="camelToTitleCase(sizeKey)"
                  @update:model-value="handleSizeInputChange($event, subKey, sizeKey, subKey !== 'lineHeight')"
                />
              </iColumn>
            </iRow>
          </iColumn>
          <iColumn v-if="key === 'colors' && subKey !== 'transparent'">
            <iSubHeading font-size="large">
              {{ camelToTitleCase(subKey) }}
              <iIcon icon="pencil" @click="handleOpenColorGenerator(subKey)" />
            </iSubHeading>
            <iRow>
              <iColumn
                v-for="(colorKey, colorIndex) in
                  Object.keys(palette[key][subKey])"
                :key="colorIndex"
                class="color-input-container"
                align="center"
                @click="handleOpenColorEdit(subKey, colorKey)"
              >
                <ColorBlock :color="palette[key][subKey][colorKey]" />
                <iText font-size="verySmall" variant="subtle">
                  {{ colorKey }}
                </iText>
              </iColumn>
            </iRow>
          </iColumn>
        </iColumn>
      </iColumn>
    </iColumn>
    <iModal
      :visible="isColorGeneratorVisible"
      :title="camelToTitleCase(colorKeyToGenerate)"
      :max-width="800"
      :height="600"
      :close-on-esc="true"
      :close-on-click-outside="true"
      @click:primary="handleColorGenerator"
      @click:secondary="handleCloseColorGenerator"
      @close="handleCloseColorGenerator"
    >
      <template #body>
        <iTextInput
          v-model="colorCodeToGenerate"
          label="Color Code"
          :style-overrides="inputErrorStyle('colorCodeToGenerate')"
          placeholder="Enter a color code (#ffffff)"
          width="fill"
        />
        <iText v-if="errors.colorCodeToGenerate" variant="error">
          {{ errors.colorCodeToGenerate }}
        </iText>
      </template>
    </iModal>
    <iModal
      :visible="isColorEditVisible"
      :title="camelToTitleCase(colorKeyToEdit) + ' ' + colorVariantToEdit"
      :max-width="800"
      :height="600"
      :close-on-esc="true"
      :close-on-click-outside="true"
      @click:primary="handleColorEdit"
      @click:secondary="handleCloseColorEdit"
      @close="handleCloseColorEdit"
    >
      <template #body>
        <iTextInput
          v-model="colorCodeToEdit"
          label="Color Code"
          :style-overrides="inputErrorStyle('colorCodeToEdit')"
          placeholder="Enter a color code (#ffffff)"
          width="fill"
        />
        <iText v-if="errors.colorCodeToEdit" variant="error">
          {{ errors.colorCodeToEdit }}
        </iText>
      </template>
    </iModal>
  </iColumn>
</template>

<script>
import { camelToTitleCase } from "@bloglovin/vue-framework";
import { VISIBILITY_OPTIONS, FONT_WEIGHT_OPTIONS } from "@/constants/theme-config-constants";
import { generateColorVariants } from "@/helpers/color-helpers";
import ColorBlock from "@/components/themes/ColorBlock";

export default {
  name: "ThemePaletteConfiguration",
  components: { ColorBlock },
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:modelValue", "has-errors"],
  data() {
    return {
      palette: this.modelValue,
      FONT_WEIGHT_OPTIONS,
      VISIBILITY_OPTIONS,
      errors: {},
      isColorGeneratorVisible: false,
      colorKeyToGenerate: "",
      colorCodeToGenerate: "",
      isColorEditVisible: false,
      colorKeyToEdit: "",
      colorVariantToEdit: "",
      colorCodeToEdit: "",
    };
  },
  computed: {
    paletteKeys() {
      return Object.keys(this.palette).filter(key => key !== "name" && key !== "visibility");
    },
    paletteName: {
      get() {
        return this.palette.name;
      },
      set(value) {
        if (!value) {
          this.addError("palette-name", "Please enter a name for the palette");
        } else {
          this.removeError("palette-name");
        }
        this.palette.name = value;
      },
    },
  },
  watch: {
    palette: {
      handler(newValue) {
        this.$emit("update:modelValue", newValue);
      },
      deep: true,
    },
    errors: {
      handler(newValue) {
        this.$emit("has-errors", Object.keys(newValue).length > 0);
      },
      deep: true,
    },
  },
  methods: {
    camelToTitleCase,
    handleOpenColorGenerator(colorKey) {
      this.isColorGeneratorVisible = true;
      this.colorKeyToGenerate = colorKey;
      this.colorCodeToGenerate = "";
      this.removeError("colorCodeToGenerate");
    },
    handleCloseColorGenerator() {
      this.isColorGeneratorVisible = false;
      this.colorKeyToGenerate = "";
      this.colorCodeToGenerate = "";
      this.removeError("colorCodeToGenerate");
    },
    handleColorGenerator() {
      if (!this.isHexCode(this.colorCodeToGenerate)) {
        this.addError("colorCodeToGenerate", "Please enter a valid hex code");
        return;
      } else {
        this.removeError("colorCodeToGenerate");
      }
      this.palette.colors[this.colorKeyToGenerate] = generateColorVariants(this.colorCodeToGenerate);
      this.handleCloseColorGenerator();
    },
    handleOpenColorEdit(colorKey, colorVariant) {
      this.isColorEditVisible = true;
      this.colorKeyToEdit = colorKey;
      this.colorCodeToEdit = this.palette.colors[colorKey][colorVariant];
      this.colorVariantToEdit = colorVariant;
      this.removeError("colorCodeToEdit");
    },
    handleCloseColorEdit() {
      this.isColorEditVisible = false;
      this.colorKeyToEdit = "";
      this.colorVariantToEdit = "";
      this.colorCodeToEdit = "";
      this.removeError("colorCodeToEdit");
    },
    handleColorEdit() {
      if (!this.isHexCode(this.colorCodeToEdit)) {
        this.addError("colorCodeToEdit", "Please enter a valid hex code");
        return;
      } else {
        this.removeError("colorCodeToEdit");
      }
      this.palette.colors[this.colorKeyToEdit][this.colorVariantToEdit] = this.colorCodeToEdit;
      this.handleCloseColorEdit();
    },
    inputErrorStyle(key) {
      if (this.errors[key]) {
        return { "inputBorderColor": "striking", "inputBorderFocusColor": "striking" };
      }
      return {};
    },
    addError(key, message) {
      this.errors = {
        ...this.errors,
        [key]: message,
      };
    },
    removeError(key) {
      this.errors = Object.keys(this.errors).reduce((acc, errorKey) => {
        if (errorKey !== key) {
          acc[errorKey] = this.errors[errorKey];
        }
        return acc;
      }, {});
    },
    isFloatOrInt(value) {
      return /^-?\d+(\.\d+)?$/.test(value);
    },
    isHexCode(value) {
      return /^#[0-9A-F]{6}[0-9a-f]{0,2}$/i.test(value);
    },
    handleSizeInputChange(value, subKey, sizeKey, addPx = true) {
      if (!value || !this.isFloatOrInt(value)) {
        this.addError(`size-${subKey}-${sizeKey}`, "Please enter a valid size");
      } else {
        this.removeError(`size-${subKey}-${sizeKey}`);
      }

      this.palette.sizes[subKey][sizeKey] = `${value}${addPx ? "px" : ""}`;
    },
  },
  styleGuide: () => ({
    inputBorderWidth: { "size.border": "standard" },
    inputBorderColor: { "color.border": "dark" },
    inputBorderRadius: { "size.borderRadius": "large" },
  }),
};
</script>

<style scoped lang="scss">
.size-input-container, .weight-input-container, .color-input-container {
  flex-grow: 0;
  flex-basis: fit-content;
}

@include phone {
  .size-input {
    width: 100%;
    max-width: 100%;
     & :deep(.i-text-input) {
       width: 100%;
       max-width: 100%;
     }
  }
  .size-input-container, .weight-input-container {
    flex-grow: 1;
    flex-basis: auto;
    width: 100%;
  }
}

.color-input-container {
  cursor: pointer;
}

.w-full {
  width: 100%;
}

.color-box {
  width: 50px;
  height: 50px;
  border-style: solid;
  box-sizing: border-box;
  outline: none;
  border-width: v-bind('$getStyles.inputBorderWidth');
  border-radius: v-bind('$getStyles.inputBorderRadius');
  border-color: v-bind('$getStyles.inputBorderColor');
}

.full-width {
  width: 100%;
}

.input-disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
</style>
