// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-state[data-v-33c0fe96] {
  padding: var(--33c0fe96-\\\$getStyles\\.emptyStatePadding);
}`, "",{"version":3,"sources":["webpack://./src/SmartFeed/scripts/components/SmartFeed.vue","webpack://./SmartFeed.vue"],"names":[],"mappings":"AAEA;EACE,uDAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.empty-state{\n  padding: v-bind(\"$getStyles.emptyStatePadding\");\n}\n",".empty-state {\n  padding: v-bind(\"$getStyles.emptyStatePadding\");\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
