<template>
  <PageWrapper
    :active-section="SECTION_BILLING"
    :active-page="PAGE_PURCHASED_DIGITAL_PRODUCTS"
    title="Purchased Digital Products"
    :breadcrumbs="breadcrumbs"
    page-name="Purchased Digital Products"
    align="left"
    width="full"
  >
    <SmartFeed
      :pinia-store="useMyDigitalProductPurchaseFeedStore"
      default-smart-feed-layout="table"
      default-entity-layout="tableRow"
    >
      <template #empty-state>
        <iCard width="fill" :style-overrides="{borderWidth: 'none'}">
          <template #body>
            <iColumn gap="large" align="center">
              <iColumn gap="standard" align="center">
                <iSubHeading variant="subtle">
                  No digital products purchased yet
                </iSubHeading>
                <iParagraph
                  variant="subtle"
                  text-align="center"
                  :max-width="400"
                >
                  Your digital product purchases will appear here once.
                </iParagraph>
              </iColumn>
            </iColumn>
          </template>
        </iCard>
      </template>
    </SmartFeed>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_PURCHASED_DIGITAL_PRODUCTS,
  SECTION_BILLING,
} from "@/constants/nav-constants";
import SmartFeed from "SmartFeed/components/SmartFeed.vue";
import { useSubscriptionBillingHistoryFeedStore } from "SmartFeed/stores/feed/subscription-billing-history-feed-store";
import { useMyDigitalProductPurchaseFeedStore } from "SmartFeed/stores/feed/my-digital-product-purchase-feed-store";

export default {
  name: "PurchasedDigitalProducts",
  components: {
    SmartFeed,
    PageWrapper,
  },
  data() {
    return {
      SECTION_BILLING,
      PAGE_PURCHASED_DIGITAL_PRODUCTS,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: "/billing", label: "Billing" },
      ];
    },
  },
  methods: { useMyDigitalProductPurchaseFeedStore, useSubscriptionBillingHistoryFeedStore },
};
</script>
