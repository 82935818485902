export const overviewFields = [
  { key: "type", label: "Type" },
  { key: "subscriptions", label: "Subscriptions" },
  { key: "digitalProducts", label: "Digital Products" },
  { key: "affiliate", label: "Affiliate" },
  { key: "syndication", label: "Syndication" },
  { key: "sponsoredContent", label: "Sponsored Content" },
  { key: "displayAds", label: "Display Ads" },
];

export const overviewItems = [
  {
    type: "Gross Revenue",
    subscriptions: "$10,240.00",
    digitalProducts: "$10,240.00",
    affiliate: "$10,240.00",
    syndication: "$10,240.00",
    sponsoredContent: "$10,240.00",
    displayAds: "$10,240.00",
  },
  {
    type: "Inspiration Fees",
    subscriptions: "($102.40)",
    digitalProducts: "($102.40)",
    affiliate: "($102.40)",
    syndication: "($102.40)",
    sponsoredContent: "($102.40)",
    displayAds: "($102.40)",
  },
  {
    type: "Collaborator Expenses",
    subscriptions: "($3,875.00)",
    digitalProducts: "($3,875.00)",
    affiliate: "($3,875.00)",
    syndication: "($3,875.00)",
    sponsoredContent: "($3,875.00)",
    displayAds: "($3,875.00)",
  },
  {
    type: "Net Revenue",
    subscriptions: "$6,262.60",
    digitalProducts: "$3,875.00",
    affiliate: "$3,875.00",
    syndication: "$3,875.00",
    sponsoredContent: "$3,875.00",
    displayAds: "$3,875.00",
  },
];

export const publicationsFields = [
  { key: "publicationName", label: "Publication" },
  { key: "displayAds", label: "Display Ads" },
  { key: "sponsoredContent", label: "Sponsored Content" },
  { key: "affiliate", label: "Affiliate" },
  { key: "subscriptions", label: "Subscriptions" },
  { key: "digitalProducts", label: "Digital Products" },
  { key: "total", label: "Total" },
];

export const publicationsItems = [
  {
    publicationName: "Fashion Daily",
    displayAds: "$1,200.00",
    sponsoredContent: "$500.00",
    affiliate: "$300.00",
    subscriptions: "$200.00",
    digitalProducts: "$100.00",
    total: "$2,300.00",
  },
  {
    publicationName: "Tech Bytes",
    displayAds: "$750.00",
    sponsoredContent: "$0.00",
    affiliate: "$600.00",
    subscriptions: "$650.00",
    digitalProducts: "$40.00",
    total: "$2,040.00",
  },
  {
    publicationName: "Food Bliss",
    displayAds: "$900.00",
    sponsoredContent: "$150.00",
    affiliate: "$80.00",
    subscriptions: "$300.00",
    digitalProducts: "$20.00",
    total: "$1,450.00",
  },
  {
    publicationName: "Total",
    displayAds: "$2,850.00",
    sponsoredContent: "$650.00",
    affiliate: "$980.00",
    subscriptions: "$1,150.00",
    digitalProducts: "$160.00",
    total: "$5,790.00",
  },
];

export const subscriptionsFields = [
  { key: "planName", label: "Subscription Plan" },
  { key: "publicationName", label: "Publication" },
  { key: "amount", label: "Amount" },
];

export const subscriptionsItems = [
  {
    planName: "Basic Monthly",
    publicationName: "Fashion Daily",
    amount: "$9.99",
  },
  {
    planName: "Pro Annual",
    publicationName: "Tech Bytes",
    amount: "$199.99",
  },
  {
    planName: "Family Plan",
    publicationName: "Food Bliss",
    amount: "$14.99",
  },
  {
    planName: "Total",
    publicationName: "",
    amount: "$224.97",
  },
];

export const digitalProductsFields = [
  { key: "productName", label: "Digital Product" },
  { key: "publicationName", label: "Publication" },
  { key: "amount", label: "Amount" },
];

export const digitalProductsItems = [
  {
    productName: "E-book: Mastering Fashion",
    publicationName: "Fashion Daily",
    amount: "$29.99",
  },
  {
    productName: "Tech Whitepaper",
    publicationName: "Tech Bytes",
    amount: "$49.99",
  },
  {
    productName: "Cooking Video Course",
    publicationName: "Food Bliss",
    amount: "$19.99",
  },
  // total row
  {
    productName: "Total",
    publicationName: "",
    amount: "$99.97",
  },
];

export const displayAdsFields = [
  { key: "adName", label: "Ad Name" },
  { key: "advertiserName", label: "Advertiser Name" },
  { key: "amount", label: "Amount" },
];

export const displayAdsItems = [
  {
    adName: "Banner Top",
    advertiserName: "XYZ Corp",
    amount: "$450.00",
  },
  {
    adName: "Sidebar Rectangle",
    advertiserName: "RandomCo",
    amount: "$600.00",
  },
  {
    adName: "Footer Ad",
    advertiserName: "Fashion Inc",
    amount: "$350.00",
  },
  {
    adName: "Total",
    advertiserName: "",
    amount: "$1,400.00",
  },
];

export const syndicationFields = [
  { key: "storyName", label: "Story Name" },
  { key: "revenue", label: "Revenue" },
  { key: "cost", label: "Cost" },
];

export const syndicationItems = [
  {
    storyName: "Fall Fashion Trends",
    revenue: "$180.00",
    cost: "",
  },
  {
    storyName: "Future of Tech 2050",
    revenue: "$0.00",
    cost: "$90.00",
  },
  {
    storyName: "Delicious Baking Secrets",
    revenue: "$120.00",
    cost: "",
  },
  {
    storyName: "Total",
    revenue: "$300.00",
    cost: "$90.00",
  },
];

export const sponsoredContentFields = [
  { key: "campaignName", label: "Campaign Name" },
  { key: "advertiser", label: "Advertiser" },
  { key: "status", label: "Status" },
  { key: "amountContracted", label: "Amount Contracted" },
  { key: "amountEarned", label: "Amount Earned" },
  { key: "amountPaid", label: "Amount Paid" },
];

export const sponsoredContentItems = [
  {
    campaignName: "Fashion Week Collab",
    advertiser: "LuxuryBrand",
    status: "Active",
    amountContracted: "$1,000.00",
    amountEarned: "$500.00",
    amountPaid: "$400.00",
  },
  {
    campaignName: "Tech Launch Review",
    advertiser: "InnovateX",
    status: "Completed",
    amountContracted: "$2,000.00",
    amountEarned: "$2,000.00",
    amountPaid: "$2,000.00",
  },
  {
    campaignName: "Healthy Meals Series",
    advertiser: "FoodCo",
    status: "In Progress",
    amountContracted: "$1,500.00",
    amountEarned: "$1,000.00",
    amountPaid: "$750.00",
  },
  {
    campaignName: "Total",
    advertiser: "",
    status: "",
    amountContracted: "$4,500.00",
    amountEarned: "$3,500.00",
    amountPaid: "$3,150.00",
  },
];
