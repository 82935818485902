<template>
  <iModal
    :max-width="700"
    :visible="isAddSubscriptionPlanModalOpen"
    title="New Subscription Plan"
    primary-action-button-label="Add Plan"
    :primary-action-button-loading="isLoadingCreateSubscriptionPlan"
    @close="closeAddSubscriptionPlanModal"
    @click:primary="addSubscriptionPlan"
    @click:secondary="closeAddSubscriptionPlanModal"
  >
    <template #body>
      <iColumn width="fill">
        <iTextInput v-model="name" label="Name" />
        <iTextArea
          v-model="description"
          :required="true"
          label="Description"
          name="description"
          placeholder="Describe your subscription..."
          width="fill"
        />
        <iSelect
          id="billing-frequency"
          v-model="billingFrequency"
          label="Billing Frequency"
          :items="subscriptionTypes"
          value-field="name"
          text-field="name"
        />
        <iTextInput
          v-model="price"
          type="number"
          label="Price"
          :padding-left-multiplier="2"
        >
          <template #prepend>
            {{ currencyCode.symbol }}
          </template>
        </iTextInput>
      </iColumn>
    </template>
  </iModal>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import { MAPPED_CURRENCY_CODES } from "@/constants/currency-constants";
import { useSubscriptionStore } from "@/stores/subscription-store";

export default {
  name: "CreateSubscriptionModal",
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      name: "",
      description: "",
      price: 0,
      billingFrequency: { name: "weekly", id: "weekly" },
      exclusivityWindowDays: 0,
      currencyCode: MAPPED_CURRENCY_CODES[0],
    };
  },
  computed: {
    ...mapState(useCreatorPublicationStore, ["validBillingFrequencies"]),
    ...mapState(useSubscriptionStore, [
      "isLoadingCreateSubscriptionPlan",
      "isAddSubscriptionPlanModalOpen",
    ]),
    subscriptionTypes() {
      return this.validBillingFrequencies.map(key => ({
        name: key,
        id: key,
      }));
    },
  },
  methods: {
    ...mapActions(useSubscriptionStore, [
      "createSubscriptionPlan",
      "closeAddSubscriptionPlanModal",
      "openAddSubscriptionPlanModal",
      "closeAddSubscriptionPlanModal",
    ]),
    async addSubscriptionPlan() {
      this.createSubscriptionPlan(
        this.name,
        this.description,
        this.billingFrequency.name,
        parseFloat(this.price),
        this.currencyCode.key,
        5,
        // eslint-disable-next-line @stylistic/js/comma-dangle
        parseInt(this.exclusivityWindowDays)
      );
    },
  },
};
</script>
