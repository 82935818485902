// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.support-chat-list[data-v-323452e8] {
  border-right: var(--323452e8-\\\$getStyles\\.chatListBorderRightWidth) solid var(--323452e8-\\\$getStyles\\.chatListBorderRightColor) !important;
}
.chat-list-item.active[data-v-323452e8] {
  background: var(--323452e8-\\\$getStyles\\.activeItemBackgroundColor);
}
.chat-list-item.active[data-v-323452e8] svg {
  fill: var(--323452e8-\\\$getStyles\\.activeItemFontColor);
}
.active[data-v-323452e8] {
  color: var(--323452e8-\\\$getStyles\\.activeItemFontColor);
}
.chat-list-item[data-v-323452e8] {
  border-radius: var(--323452e8-\\\$getStyles\\.itemBorderRadius);
  padding: var(--323452e8-\\\$getStyles\\.itemPadding);
  cursor: pointer;
  transition: background-color 0.3s;
}
.chat-list-item[data-v-323452e8]:hover:not(.active) {
  background: var(--323452e8-\\\$getStyles\\.itemHoverBackgroundColor);
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/pages/publication/PublicationSupport.vue","webpack://./PublicationSupport.vue"],"names":[],"mappings":"AAEE;EACE,0IAAA;ACDJ;ADIE;EACE,kEAAA;ACDJ;ADGI;EACE,sDAAA;ACDN;ADKE;EACE,uDAAA;ACFJ;ADKE;EACE,4DAAA;EACA,iDAAA;EACA,eAAA;EACA,iCAAA;ACFJ;ADII;EACE,iEAAA;ACFN","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n  .support-chat-list{\n    border-right: v-bind(\"$getStyles.chatListBorderRightWidth\") solid v-bind(\"$getStyles.chatListBorderRightColor\") !important;\n  }\n\n  .chat-list-item.active {\n    background: v-bind('$getStyles.activeItemBackgroundColor');\n\n    :deep(svg) {\n      fill: v-bind('$getStyles.activeItemFontColor');\n    }\n  }\n\n  .active {\n    color: v-bind('$getStyles.activeItemFontColor');\n  }\n\n  .chat-list-item {\n    border-radius: v-bind('$getStyles.itemBorderRadius');\n    padding: v-bind('$getStyles.itemPadding');\n    cursor: pointer;\n    transition: background-color 0.3s;\n\n    &:hover:not(.active) {\n      background: v-bind('$getStyles.itemHoverBackgroundColor');\n    }\n  }\n",".support-chat-list {\n  border-right: v-bind(\"$getStyles.chatListBorderRightWidth\") solid v-bind(\"$getStyles.chatListBorderRightColor\") !important;\n}\n\n.chat-list-item.active {\n  background: v-bind(\"$getStyles.activeItemBackgroundColor\");\n}\n.chat-list-item.active :deep(svg) {\n  fill: v-bind(\"$getStyles.activeItemFontColor\");\n}\n\n.active {\n  color: v-bind(\"$getStyles.activeItemFontColor\");\n}\n\n.chat-list-item {\n  border-radius: v-bind(\"$getStyles.itemBorderRadius\");\n  padding: v-bind(\"$getStyles.itemPadding\");\n  cursor: pointer;\n  transition: background-color 0.3s;\n}\n.chat-list-item:hover:not(.active) {\n  background: v-bind(\"$getStyles.itemHoverBackgroundColor\");\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
