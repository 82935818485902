<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_SUBSCRIPTIONS"
    title="Daily Subscription Revenue"
    :breadcrumbs="breadcrumbs"
    page-name="Daily Subscription Revenue"
    align="center"
    width="full"
  >
    <iDataTable
      v-if="hasSubscriptionData"
      :fields="subscriptionRevenueFields"
      :items="subscriptionRevenueItems"
      primary-key="plan"
      :has-more="false"
      hide-actions
    >
      <template #td="{data, value}">
        <iText :font-weight="data.plan === 'Total' ? 'dark' : 'standard'">
          {{ formatValue(data, value) }}
        </iText>
      </template>
    </iDataTable>
    <iColumn v-else gap="large" align="center">
      <iSpace :height="80" />
      <iColumn gap="standard" align="center">
        <iSubHeading variant="subtle" font-size="large">
          No daily subscription revenue data
        </iSubHeading>
        <iParagraph
          variant="subtle"
          text-align="center"
          :max-width="400"
        >
          Daily subscription revenue data will appear here once you have active subscribers.
        </iParagraph>
      </iColumn>
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper.vue";
import { mapState } from "pinia";
import {
  PAGE_PUBLICATION_DAILY_SUBSCRIPTION_REVENUE,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import { camelToTitleCase } from "@bloglovin/vue-framework";

export default {
  name: "PublicationDailySubscriptionRevenue",
  components: { PageWrapper },
  props: {
    dailySubscriptionRevenue: {
      type: Object,
      default: () => ({
        plans: [],
        total: {
          subscribers: 0,
          daily_revenue: 0,
          engagement_allocation: 0,
          audience_allocation: 0,
        },
      }),
    },
  },
  data() {
    return {
      SECTION_PUBLICATIONS,
      PAGE_PUBLICATION_SUBSCRIPTIONS: PAGE_PUBLICATION_DAILY_SUBSCRIPTION_REVENUE,
      subscriptionRevenueFields: [
        { key: "plan", label: "Plan" },
        { key: "term", label: "Term" },
        { key: "price", label: "Price" },
        { key: "subscribers", label: "Subscribers" },
        { key: "daily_revenue", label: "Daily Revenue" },
      ],
    };
  },
  computed: {
    ...mapState(useCreatorPublicationStore, [
      "orgId",
      "publicationId",
    ]),
    hasSubscriptionData() {
      return this.dailySubscriptionRevenue.plans.length > 0;
    },
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        {
          url: `/publication/${this.publicationId}`,
          label: "Overview",
        },
      ];
    },
    subscriptionRevenueItems() {
      const planItems = this.dailySubscriptionRevenue.plans.map(plan => ({
        plan: plan.plan,
        term: camelToTitleCase(plan.term),
        price: plan.price,
        subscribers: plan.subscribers,
        daily_revenue: plan.daily_revenue,
      }));

      const totalRow = {
        plan: "Total",
        term: "",
        price: "",
        subscribers: this.dailySubscriptionRevenue.total.subscribers,
        daily_revenue: this.dailySubscriptionRevenue.total.daily_revenue,
      };

      const engagementRow = {
        plan: "Engagement Allocation 50%",
        term: "",
        price: "",
        subscribers: "",
        daily_revenue: this.dailySubscriptionRevenue.total.engagement_allocation,
      };

      const audienceRow = {
        plan: "Audience 50%",
        term: "",
        price: "",
        subscribers: "",
        daily_revenue: this.dailySubscriptionRevenue.total.audience_allocation,
      };

      return [...planItems, totalRow, engagementRow, audienceRow];
    },
  },
  methods: {
    formatValue(data, value) {
      if (value === "") {
        return;
      }
      if (data.price === value) {
        return `$${value}`;
      } else if (data.daily_revenue === value) {
        return `$${value.toFixed(2)}`;
      }
      return value;
    },
  },
};
</script>
