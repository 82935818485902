import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { useChannelStore } from "%/stores/channel-store";

export const useChannelUserFeedStore = defineSmartFeedPiniaStore("channel-user-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    metadata: {
      lastId: 0,
      channelId: useChannelStore().channelId,
    },
    tableFields: [
      {
        key: "userId",
        label: "User Id",
      },
      {
        key: "username",
        label: "Username",
      },
      {
        key: "userAvatar",
        label: "User Avatar",
      },
      {
        key: "timestampJoined",
        label: "Join Date",
      },
      {
        key: "actions",
        label: "",
      },
    ],
  }),
  getters: {
    feedUrl() {
      return `/channel/${this.metadata.channelId}/users/smart-feed`;
    },
  },
});
