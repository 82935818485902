// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-12b5e22c] tiptap.ProseMirror {
  height: 100%;
  box-sizing: border-box;
  cursor: text;
}
.native-story-title[data-v-12b5e22c] {
  margin-top: 4px;
}
.native-story-title[data-v-12b5e22c] .i-text.text-edit {
  width: 100%;
  font-size: 26px;
  font-weight: 700;
}
.text-btn[data-v-12b5e22c] {
  opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/publication/StoryEditorComponent.vue","webpack://./StoryEditorComponent.vue"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,sBAAA;EACA,YAAA;ACDF;ADIA;EACE,eAAA;ACDF;ADGE;EACE,WAAA;EACA,eAAA;EACA,gBAAA;ACDJ;ADKA;EACE,YAAA;ACFF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n:deep(tiptap.ProseMirror) {\n  height: 100%;\n  box-sizing: border-box;\n  cursor: text;\n}\n\n.native-story-title {\n  margin-top: 4px;\n\n  :deep(.i-text.text-edit) {\n    width: 100%;\n    font-size: 26px;\n    font-weight: 700;\n  }\n}\n\n.text-btn {\n  opacity: 0.5;\n}\n",":deep(tiptap.ProseMirror) {\n  height: 100%;\n  box-sizing: border-box;\n  cursor: text;\n}\n\n.native-story-title {\n  margin-top: 4px;\n}\n.native-story-title :deep(.i-text.text-edit) {\n  width: 100%;\n  font-size: 26px;\n  font-weight: 700;\n}\n\n.text-btn {\n  opacity: 0.5;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
