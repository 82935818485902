<template>
  <PageWrapper
    :active-page="PAGE_SYNDICATED_STORY"
    :active-section="SECTION_STORIES"
    page-name="Syndicated Story"
    hide-sidebar
  >
    <iColumn
      class="post-container"
      align="center"
      height="fill"
      wrap="nowrap"
    >
      <iColumn
        class="post-container-content"
        gap="none"
        height="fill"
        wrap="nowrap"
      >
        <iRow align="between" wrap="nowrap" vertical-align="middle">
          <iHeading
            :style-overrides="{
              'standardFontWeight': 'dark',
              'marginBottom': 'none'
            }"
          >
            {{ story.title || 'Untitled Syndicated Post' }}
          </iHeading>
          <iColumn width="hug">
            <iButton
              :loader-on-left="true"
              loading-spinner-position="before"
              size="small"
              :is-loading="isPublishingStory"
              :disabled="publishButtonDisabled"
              @click="handlePublishClick"
            >
              {{ publishButtonText }}
            </iButton>
          </iColumn>
        </iRow>
        <iColumn>
          <iText v-if="story.is_private" variant="subtle">
            Exclusive Content
          </iText>
          <iRow>
            <template v-if="story.hashtags && story.hashtags.length">
              <iLink
                v-for="hashtag in story.hashtags"
                :key="hashtag.hashtag_id"
                class="hashtag-link"
                :href="navigateToHashtag(hashtag.hashtag)"
              >
                #{{ hashtag.hashtag }}
              </iLink>
            </template>
          </iRow>
          <iStyledCard
            :author-avatar="authorAvatar"
            :author-name="authorName"
            :author-description="relativeDateCreated"
          />
        </iColumn>
        <iEditor
          ref="editor"
          preview
          :segments="segments"
          height="auto"
          overflow="none"
          variant="builder"
          width="fill"
        />
      </iColumn>
    </iColumn>
  </PageWrapper>
  <AssignSyndicatedPublishingPlatformModal
    v-if="assignToSyndicatedPublishingPlatformModalOpen"
    :story-id="story.story_id"
    @change="assignStory"
    @close="assignToSyndicatedPublishingPlatformModalOpen = false;"
  />
  <AssignExclusivePublishingPlatformModal
    v-if="assignToExclusivePublishingPlatformModalOpen"
    :story-id="story.story_id"
    @change="assignStory"
    @close="assignToExclusivePublishingPlatformModalOpen = false;"
  />
</template>

<script>
import { mapState, mapActions, mapWritableState } from "pinia";
import dayjs from "dayjs";
import PageWrapper from "@/components/PageWrapper.vue";
import { useStoryStore } from "%/stores/story-store";
import AssignExclusivePublishingPlatformModal
  from "@/components/publication/AssignExclusivePublishingPlatformModal.vue";
import AssignSyndicatedPublishingPlatformModal
  from "@/components/publication/AssignSyndicatedPublishingPlatformModal.vue";
import { DEFAULT_USER_AVATAR } from "%/constants/user-constants";
import { PAGE_SYNDICATED_STORY, SECTION_STORIES } from "@/constants/nav-constants";

export default {
  name: "SyndicatedStory",
  components: {
    AssignSyndicatedPublishingPlatformModal, AssignExclusivePublishingPlatformModal,
    PageWrapper,
  },
  data() {
    return {
      PAGE_SYNDICATED_STORY,
      SECTION_STORIES,
    };
  },
  computed: {
    ...mapState(useStoryStore, [
      "story",
      "isPublishingStory",
      "assignToExclusivePublishingPlatformModalOpen",
      "assignToSyndicatedPublishingPlatformModalOpen",
    ]),
    ...mapWritableState(useStoryStore, [
      "assignToSyndicatedPublishingPlatformModalOpen",
      "assignToExclusivePublishingPlatformModalOpen",
      "forcePublishAfterAssigning",
    ]),
    publishButtonText() {
      if (this.story.allow_remix) {
        return this.story.requires_syndication_approval
          ? "Request Remix Approval"
          : "Remix";
      }
      return this.story.requires_syndication_approval
        ? "Request Permission"
        : "Publish";
    },
    publishButtonDisabled() {
      return this.story.status === "pending_author_approval";
    },
    authorName() {
      return this.story.author || "Author";
    },
    authorAvatar() {
      if (this.story.author_avatar === "" || !this.story.author_avatar) {
        return DEFAULT_USER_AVATAR;
      }
      return this.story.author_avatar;
    },
    relativeDateCreated() {
      if (!this.story.timestamp_created) {
        return "";
      }
      return dayjs.unix(this.story.timestamp_created).fromNow();
    },
    segments() {
      if (!this.story.segments || !this.story.segment_order) {
        return [];
      }
      const segmentArray = Object.values(this.story.segments);
      return this.story.segment_order.reduce((acc, curr) => {
        const seg = segmentArray.find(item => item.story_segment_id === curr.story_segment_id);
        if (seg) {
          acc.push(seg);
        }
        return acc;
      }, []);
    },
  },
  created() {
    if (this.story && this.story.title) {
      document.title = this.story.title;
    }
  },
  methods: {
    ...mapActions(useStoryStore, [
      "publishStoryFromSyndicationMarketplace",
      "assignStoryToPublishingPlatform",
      "publishStoryFromMarketplace",
      "publishSyndicationStory",
    ]),
    navigateToHashtag(hashtag) {
      return `#/hashtag/${hashtag}`;
    },
    assignStory(details) {
      this.assignToSyndicatedPublishingPlatformModalOpen = false;
      this.publishSyndicationStory(
        this.story.story_id,
        details.publishing_platform_id,
      );
    },
    handlePublishClick() {
      this.publishStoryFromMarketplace();
    },
  },
};
</script>

<style scoped lang="scss">
.post-container {
  overflow-y: auto;

  &-content {
    padding: 24px;
    max-width: 844px;
  }
}

.hashtag-link {
  padding: 4px 8px;
  margin-left: 4px;
  &:hover {
    border-radius: 8px;
  }
}
</style>
