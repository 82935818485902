<template>
  <PageWrapper
    :active-section="SECTION_MONETIZATION"
    :active-page="PAGE_MONETIZATION_MY_BRANDS"
    title="My Brands"
    page-name="My Brands"
    :breadcrumbs="breadcrumbs"
    align="center"
    width="full"
  >
    <SmartFeed
      :pinia-store="useMyFavoriteAdvertiserCollectionItemFeedStore"
      default-smart-feed-layout="grid"
      default-entity-layout="cardSmall"
    >
      <template #empty-state>
        <iStyledCard width="fill">
          <template #title>
            <iSubHeading variant="subtle" font-size="large" align="left">
              No Brands yet
            </iSubHeading>
          </template>
          <template #description>
            <iParagraph
              variant="subtle"
              :max-width="400"
            >
              <iRow gap="small">
                Browse <iLink variant="subtle" href="/community/directory/advertisers" target="_blank">
                  here
                </iLink> to find new brands.
              </iRow>
            </iParagraph>
          </template>
        </iStyledCard>
      </template>
    </Smartfeed>
  </Pagewrapper>
</template>
<script>
import PageWrapper from "@/components/PageWrapper";
import SmartFeed from "SmartFeed/components/SmartFeed";
import {
  PAGE_MONETIZATION_MY_BRANDS,
  SECTION_MONETIZATION,
} from "@/constants/nav-constants";
import {
  useMyFavoriteAdvertiserCollectionItemFeedStore,
} from "SmartFeed/stores/feed/my-favorite-advertiser-collection-item-feed-store";
import { mapState } from "pinia";
import { useAffiliateLinkStore } from "%/stores/affiliate-link-store";

export default {
  name: "MyBrands",
  components: {
    PageWrapper,
    SmartFeed,
  },
  data() {
    return {
      PAGE_MONETIZATION_MY_BRANDS,
      SECTION_MONETIZATION,
    };
  },
  computed: {
    ...mapState(useAffiliateLinkStore, [
      "orgId",
    ]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/monetization/org/${this.orgId}`, label: "Monetization" },
      ];
    },
  },
  methods: {
    useMyFavoriteAdvertiserCollectionItemFeedStore,
  },
};
</script>
