import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { useGeneralStore } from "%/stores/general-store";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";

export const useCompanyUsersFeedStore = defineSmartFeedPiniaStore("company-users-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    metadata: {
      orgId: useGeneralStore().orgId,
    },
    tableFields: [
      { key: "emailAddress", label: "Email" },
      { key: "fullName", label: "Name" },
      { key: "roleId", label: "Role" },
      { key: "status", label: "Status" },
      { key: "actions", label: "" },
    ],
  }),
  getters: {
    feedUrl() {
      return `/org/${this.metadata.orgId}/users/company/smart-feed`;
    },
  },
});
