<template>
  <PageWrapper
    :active-section="SECTION_MONETIZATION"
    :active-page="PAGE_MONETIZATION_SUBSCRIPTIONS"
    page-name="Subscriptions"
    align="left"
    width="fixed"
  >
    <iHeading>Subscriptions</iHeading>
    <iDataTable
      :fields="subscriptionsFields"
      :items="subscriptionsItems"
      primary-key="planName"
      :has-more="false"
      :is-loading="false"
      hide-actions
    >
      <template #td="{data, value}">
        <iText :font-weight="data.planName === 'Total' ? 'dark' : 'standard'">
          {{ value }}
        </iText>
      </template>
    </iDataTable>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { SECTION_MONETIZATION, PAGE_MONETIZATION_SUBSCRIPTIONS } from "@/constants/nav-constants";
import {
  subscriptionsFields,
  subscriptionsItems,
} from "@/constants/monetization-fake-data";

export default {
  name: "Subscriptions",
  components: {
    PageWrapper,
  },
  data() {
    return {
      SECTION_MONETIZATION,
      PAGE_MONETIZATION_SUBSCRIPTIONS,
      subscriptionsFields,
      subscriptionsItems,
    };
  },
};
</script>

<style scoped>
</style>
