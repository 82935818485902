<template>
  <iDropdownMenu
    :items="dropdownItems"
    :show-dropdown="showMenuOptions"
    :teleport-to-body="false"
    position="right"
    @close="hideMenu"
    @click="handleAction($event)"
  >
    <template #trigger>
      <iRow vertical-align="middle" @click="toggleMenu">
        <iIcon :is-loading="isLoading" icon="ellipsis" />
      </iRow>
    </template>
  </iDropdownMenu>
  <Teleport to="body">
    <iModal
      :show-footer="false"
      :visible="isShowingReportingOptions"
      title="Report This Message"
      :max-width="$vssMobile ? 'fill' : 600"
      @blur="closeReportsModal"
      @close="closeReportsModal"
    >
      <template #body>
        <iColumn :ordered="true" type="none">
          <iRow
            v-for="(offense, index) in OFFENSE_OPTIONS"
            :key="index"
          >
            <iButton
              variant="secondary"
              @click="report(offense.value)"
            >
              {{ offense.name }}
            </iButton>
          </iRow>
        </iColumn>
      </template>
    </iModal>
  </Teleport>
</template>

<script>
import { mapState, mapWritableState } from "pinia";
import { useConversationStore } from "%/stores/conversation-store";
import { OFFENSE_OPTIONS } from "%/constants/conversations-constants";
import { MenuConstants, useWindowStore } from "@bloglovin/vue-component-library";

export default {
  name: "ConversationMenu",
  props: {
    messageId: {
      type: Number,
      required: true,
    },
    moderationStatus: {
      type: String,
      required: true,
    },
    isModerator: {
      type: Boolean,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    isAuthor: {
      type: Boolean,
      required: true,
    },
    moderationEnabled: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "delete",
    "undelete",
    "block",
    "approve",
    "report",
    "edit",
  ],
  data() {
    return {
      OFFENSE_OPTIONS,
      isSharing: false,
      showMenuOptions: false,
      isShowingReportingOptions: false,
    };
  },
  computed: {
    ...mapState(useWindowStore, [
      "$vssMobile",
    ]),
    ...mapWritableState(useConversationStore, ["loadingMessages"]),
    showBlockButton() {
      return !this.isAuthor && this.isModerator && this.moderationEnabled && this.moderationStatus === "approved";
    },
    showDeleteButton() {
      return this.isAuthor && this.status != "inactive";
    },
    showUndeleteButton() {
      return this.isAuthor && this.status === "inactive";
    },
    showEditButton() {
      return this.settings.allowsEditing && this.isAuthor && this.status === "active";
    },
    dropdownItems() {
      const items = [];

      if (this.showEditButton) {
        items.push({
          name: "edit",
          label: "Edit",
          actionType: MenuConstants.ACTION_TYPE_BUTTON_CLICK,
          actionValue: "turnOnIsEditing",
        });
      }

      if (!this.isAuthor) {
        items.push({
          name: "report",
          label: "Report",
          actionType: MenuConstants.ACTION_TYPE_BUTTON_CLICK,
          actionValue: "showReportsMenu",
        });
      }

      if (this.showBlockButton) {
        items.push({
          name: "block",
          label: "Block",
          actionType: MenuConstants.ACTION_TYPE_BUTTON_CLICK,
          actionValue: "blockComment",
        });
      }

      if (this.settings.allowsSharing && !this.isSharing) {
        items.push({
          name: "share",
          label: "Share",
          actionType: MenuConstants.ACTION_TYPE_BUTTON_CLICK,
          actionValue: "showSocialShareButtons",
        });
      }

      if (this.showDeleteButton) {
        items.push({
          name: "delete",
          label: "Delete",
          actionType: MenuConstants.ACTION_TYPE_BUTTON_CLICK,
          actionValue: "deleteComment",
        });
      }

      if (this.showUndeleteButton) {
        items.push({
          name: "undelete",
          label: "Restore",
          actionType: MenuConstants.ACTION_TYPE_BUTTON_CLICK,
          actionValue: "unDeleteComment",
        });
      }

      return items;
    },
  },
  created() {
    useWindowStore().init();
  },
  beforeUnmount() {
    useWindowStore().remove();
  },
  methods: {
    handleAction(event) {
      this[event]();
    },
    turnOnIsEditing() {
      this.hideMenu();
      if (!this.isAuthor) {
        return;
      }
      this.$emit("edit");
    },
    toggleMenu() {
      this.showMenuOptions = !this.showMenuOptions;
    },
    hideMenu() {
      this.showMenuOptions = false;
    },
    showReportsMenu() {
      this.isShowingReportingOptions = true;
    },

    deleteComment() {
      if (!this.isAuthor) {
        return;
      }
      this.hideMenu();
      this.$emit("delete");
    },
    unDeleteComment() {
      if (!this.isAuthor) {
        return;
      }
      this.hideMenu();
      this.$emit("undelete");
    },
    report(reason) {
      this.isShowingReportingOptions = false;
      this.hideMenu();
      this.$emit("report", reason);
    },
    closeReportsModal() {
      this.isShowingReportingOptions = false;
    },
    blockComment() {
      if (!this.isModerator) {
        return;
      }
      this.$emit("block", this.OFFENSE_OPTIONS[0].value);
    },
    showSocialShareButtons() {
      this.isSharing = true;
      this.hideMenu();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
