<template>
  <iModal
    v-if="showModal"
    title="Invite to Collaborate"
    :max-width="650"
    primary-action-button-label="Send Invite"
    :primary-action-button-loading="isCreatingOffPlatformInvitation"
    @close="onClose"
    @click:primary="onSendInvite"
  >
    <template #body>
      <iColumn gap="large">
        <iColumn gap="none">
          <iTextInput
            v-model="inviteeName"
            label="Name"
            placeholder="Enter invitee's name"
            width="fill"
            focus
          />
          <iText v-if="validationErrors.inviteeName" variant="error">
            {{ validationErrors.inviteeName }}
          </iText>
        </iColumn>

        <iColumn gap="none">
          <iTextInput
            v-model="inviteeEmail"
            label="Email"
            placeholder="Enter invitee's email"
            width="fill"
          />
          <iText v-if="validationErrors.inviteeEmail" variant="error">
            {{ validationErrors.inviteeEmail }}
          </iText>
        </iColumn>

        <iColumn gap="none">
          <iSelect
            v-model="selectedRole"
            label="Role"
            :items="ROLE_OPTIONS"
            value-field="value"
            text-field="name"
            empty-string="Choose a role"
            width="fill"
          />
          <iText v-if="validationErrors.role" variant="error">
            {{ validationErrors.role }}
          </iText>
        </iColumn>

        <iColumn gap="none">
          <iTextArea
            v-model="inviteMessage"
            label="Invite Message"
            placeholder="Enter invitation message..."
            :rows="5"
            width="fill"
            @enter="onSendInvite"
          />
        </iColumn>

        <iColumn v-if="collaborationCounterOfferEnabled">
          <iSubHeading>Collaboration Timeline</iSubHeading>
          <iTimeLine
            :items="timelineItems"
          />
        </iColumn>

        <iColumn v-if="collaborationCounterOfferEnabled">
          <iTextInput
            v-model="compensationDetails"
            label="Compensation"
            placeholder="Enter compensation details..."
            width="fill"
          />
          <iText variant="subtle">
            This will be replaced with a final design in a future update.
          </iText>
        </iColumn>
      </iColumn>
      <iText v-if="error" variant="error">
        {{ error }}
      </iText>
    </template>
  </iModal>
</template>

<script>
import { ROLE_OPTIONS } from "@/constants/collaborator-constants";
import { mapActions, mapState, mapWritableState } from "pinia";
import { useFeatureStore } from "%/stores/feature-store";
import { useCollaboratorStore } from "@/stores/collaborator-store";
import { validateEmail } from "@bloglovin/vue-component-library";

export default {
  name: "InviteToCollaborateOffPlatform",
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    publicationId: {
      type: Number,
      required: true,
    },
    orgId: {
      type: Number,
      required: true,
    },
  },
  emits: ["close", "update:modelValue"],
  data() {
    return {
      inviteeName: "",
      inviteeEmail: "",
      inviteMessage: "",
      selectedRole: {},
      compensationDetails: "",
      validationErrors: {
        role: "",
        inviteeName: "",
        inviteeEmail: "",
      },
      timelineItems: [
        {
          title: "Collaboration Start",
          description: "Project start date: TBD",
          primary: true,
        },
        {
          title: "Milestones",
          description: "Key deliverables (placeholder)",
          bubbleSmall: false,
        },
        {
          title: "End of Collaboration",
          description: "Closing / Handover (placeholder)",
          secondary: true,
        },
      ],
      ROLE_OPTIONS,
    };
  },
  computed: {
    ...mapState(useCollaboratorStore, [
      "isCreatingOffPlatformInvitation",
    ]),
    ...mapWritableState(useCollaboratorStore, ["error"]),
    collaborationCounterOfferEnabled() {
      return useFeatureStore().isFeatureEnabled("collaborationCounterOffer");
    },
  },
  methods: {
    ...mapActions(useCollaboratorStore, [
      "createOffPlatformInvitation",
    ]),
    onClose() {
      this.error = "";
      this.validationErrors.role = "";
      this.validationErrors.inviteeName = "";
      this.validationErrors.inviteeEmail = "";
      this.inviteeName = "";
      this.inviteeEmail = "";
      this.inviteMessage = "";
      this.selectedRole = {};
      this.compensationDetails = "";
      this.$emit("close");
    },
    async onSendInvite() {
      this.validationErrors.role = "";

      if (!this.selectedRole.value) {
        this.validationErrors.role = "Please select a role.";
      }

      if (!this.inviteeEmail) {
        this.validationErrors.inviteeEmail = "Email is required";
      } else if (!validateEmail(this.inviteeEmail)) {
        this.validationErrors.inviteeEmail = "Please enter a valid email address";
      }

      if (!this.inviteeName) {
        this.validationErrors.inviteeName = "Invitee name is required";
      }

      if (
        this.validationErrors.role ||
          this.validationErrors.inviteeEmail ||
          this.validationErrors.inviteeName
      ) {
        return;
      }

      await this.createOffPlatformInvitation(
        this.publicationId,
        this.orgId,
        this.inviteeEmail,
        this.inviteeName,
        this.selectedRole.value,
        this.onClose,
      );
    },
  },
};
</script>
