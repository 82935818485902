<template>
  <PageWrapper
    :active-section="SECTION_COMMUNITY"
    :active-page="PAGE_MARKETPLACE"
    title="Syndication Marketplace"
    :breadcrumbs="breadcrumbs"
    page-name="Syndication Marketplace"
  >
    <SmartFeedV2 :source="useSyndicationMarketPlaceFeedStore" />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { SECTION_COMMUNITY, PAGE_MARKETPLACE } from "@/constants/nav-constants";
import SmartFeedV2 from "SmartFeed/components/SmartFeedV2";
import { useSyndicationMarketPlaceFeedStore } from "SmartFeed/stores/feed/syndication-marketplace-feed-store";

export default {
  name: "SyndicationMarketplace",
  components: { SmartFeedV2, PageWrapper },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_MARKETPLACE,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: "/community", label: "Community" },
      ];
    },
  },
  methods: {
    useSyndicationMarketPlaceFeedStore,
  },
};
</script>

<style scoped lang="scss">

</style>
