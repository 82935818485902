import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { useGeneralStore } from "%/stores/general-store";

export const useMyFavoriteAdvertiserCollectionItemFeedStore = defineSmartFeedPiniaStore("my-favorite-advertiser-collection-item-feed-store", {
  state: () => ({
    visibility: "",
    metadata: {
      lastId: 0,
      orgId: useGeneralStore().orgId,
      itemId: 0,
      itemContext: "",
      hasItem: false,
    },
    smartFeedEngine: new SmartFeedEngine(),
  }),
  getters: {
    feedUrl() {
      return `/my-favorite-advertiser-collection/${this.metadata.orgId}/smartfeed`;
    },
    smartFeedRequestGetParams() {
      return {
        last_id: this.metadata.lastId,
        item_id: this.metadata.itemId,
        item_context: this.metadata.itemContext,
      };
    },
  },
});
