import { defineStore } from "pinia";
import { useGeneralStore } from "%/stores/general-store";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";

export const useCollectionStore = defineStore("collection-store", {
  state: () => ({
    isLoadingCollections: false,
    isLoading: false,
    uploadedCoverImage: null,
    isUploadingCoverImage: false,
    loadingItem: false,
    collectionItemId: null,
  }),
  getters: {
    orgId() {
      return useGeneralStore().orgId;
    },
  },
  actions: {
    async createCollection(body) {
      this.isLoading = true;
      body.org_id = this.orgId;
      return this.apiPost("/collection/create", body, () => {
        this.isLoading = false;
      }, () => {
        this.isLoading = false;
      });
    },
    async updateCollection(collectionId, body) {
      this.isLoading = true;
      return this.apiPost(`/collection/${collectionId}/update`, body, response => {
        new SmartFeedEngine().addEntities(response.entities);
        this.isLoading = false;
      }, () => {
        this.isLoading = false;
      });
    },
    uploadCoverImage(coverImageFile, collectionId) {
      this.isUploadingCoverImage = true;
      let formData = new FormData();
      formData.append("image", coverImageFile);
      formData.append("collection_id", collectionId);

      this.apiPost("/collection/upload-cover-image", formData, response => {
        this.uploadedCoverImage = response.cover_image_url;
        this.isUploadingCoverImage = false;
      }, () => {
        this.isUploadingCoverImage = false;
      });
    },
    addItemToCollection(collectionId, itemId, itemContext = "story") {
      this.loadingItem = true;
      this.apiPost(`/collection/${collectionId}/item/add`,
        {
          item_id: itemId,
          item_context: itemContext,
        },
        response => {
          new SmartFeedEngine().addEntities(response.entities);
          this.loadingItem = false;
        },
        () => {
          this.loadingItem = false;
        });
    },
    removeItemFromCollection(collectionItemId, collectionId, itemId, itemContext, onSuccess, onFailure) {
      this.loadingItem = true;
      this.apiPost(`/collection/item/${collectionItemId}/remove`,
        {
          collection_id: collectionId,
          item_id: itemId,
          item_context: itemContext,
        },
        response => {
          new SmartFeedEngine().addEntities(response.entities);
          this.loadingItem = false;
          onSuccess();
        },
        () => {
          onFailure();
          this.loadingItem = false;
        });
    },
  },
});
