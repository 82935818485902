<template>
  <iModal
    :visible="showModal"
    :show-secondary-action-button="false"
    :max-width="550"
    title="Add User"
    :primary-action-button-loading="loadingAddUser"
    @close="closeModal"
    @click:primary="addUser"
  >
    <iColumn>
      <iColumn gap="none">
        <iTextInput
          v-model="addUserFirstName"
          label="First Name"
          placeholder="First Name"
          type="text"
          focus
          required
        />
        <iText v-if="validationErrors.firstName" variant="error">
          {{ validationErrors.firstName }}
        </iText>
      </iColumn>

      <iColumn gap="none">
        <iTextInput
          v-model="addUserLastName"
          label="Last Name"
          placeholder="Last Name"
          type="text"
          required
        />
        <iText v-if="validationErrors.lastName" variant="error">
          {{ validationErrors.lastName }}
        </iText>
      </iColumn>

      <iColumn gap="none">
        <iTextInput
          v-model="addUserEmail"
          label="Email"
          placeholder="Email"
          type="email"
          required
          @enter="addUser"
        />
        <iText v-if="validationErrors.email" variant="error">
          {{ validationErrors.email }}
        </iText>
      </iColumn>

      <iText v-if="addUserError" variant="error">
        {{ addUserError }}
      </iText>
    </iColumn>
  </iModal>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import { validateEmail } from "@bloglovin/vue-component-library";

export default {
  name: "AddPublicationUser",
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    publicationId: {
      type: Number,
      required: true,
    },
  },
  emits: ["close"],
  data() {
    return {
      addUserFirstName: "",
      addUserLastName: "",
      addUserEmail: "",
      validationErrors: {
        firstName: "",
        lastName: "",
        email: "",
      },
    };
  },
  computed: {
    ...mapState(useCreatorPublicationStore, [
      "loadingAddUser",
    ]),
    ...mapWritableState(useCreatorPublicationStore, ["addUserError"]),
  },
  methods: {
    ...mapActions(useCreatorPublicationStore, ["addPublicationUser"]),
    closeModal() {
      this.resetForm();
      this.$emit("close");
    },
    resetForm() {
      this.addUserError = null;
      this.addUserFirstName = "";
      this.addUserLastName = "";
      this.addUserEmail = "";
      this.validationErrors.firstName = "";
      this.validationErrors.lastName = "";
      this.validationErrors.email = "";
    },
    addUser() {
      this.validationErrors.firstName = "";
      this.validationErrors.lastName = "";
      this.validationErrors.email = "";

      let hasErrors = false;

      if (!this.addUserFirstName) {
        this.validationErrors.firstName = "First name is required";
        hasErrors = true;
      }

      if (!this.addUserLastName) {
        this.validationErrors.lastName = "Last name is required";
        hasErrors = true;
      }

      if (!this.addUserEmail) {
        this.validationErrors.email = "Email is required";
        hasErrors = true;
      } else if (!validateEmail(this.addUserEmail)) {
        this.validationErrors.email = "Please enter a valid email address";
        hasErrors = true;
      }

      if (hasErrors) {
        return;
      }

      this.addPublicationUser({
        publicationId: this.publicationId,
        firstName: this.addUserFirstName,
        lastName: this.addUserLastName,
        email: this.addUserEmail,
        onSuccess: this.closeModal,
      });
    },
  },
};
</script>
