// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-3132862b] input[type="date"] {
  width: 150px !important;
  height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/CollaborationContractProposal.vue","webpack://./CollaborationContractProposal.vue"],"names":[],"mappings":"AAEA;EACE,uBAAA;EACA,YAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n:deep(input[type=\"date\"]) {\n  width: 150px !important;\n  height: 40px;\n}\n",":deep(input[type=\"date\"]) {\n  width: 150px !important;\n  height: 40px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
