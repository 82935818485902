import { defineStore } from "pinia";
import { useGeneralStore } from "%/stores/general-store";
import { useChannelUserFeedStore } from "SmartFeed/stores/feed/channel-user-feed-store";

export const useChannelStore = defineStore("channel-store", {
  state: () => ({
    channels: [],
    channel: {},
    channelCreator: {},
    isLoadingCreateChannel: false,
    isLoadingJoinChannel: false,
    isLoadingLeaveChannel: false,
    isLoadingInviteToChannel: false,
    isLoadingFetchChannel: false,
    isLoadingFetchChannels: false,
    loadingUpdateChannelName: false,
    loadingUpdateChannelDescription: false,
    loadingFetchChannelCreatorDetails: false,
  }),
  getters: {
    isChannelUser() {
      return this.channel.is_channel_user;
    },
    channelUsersCount() {
      return this.channel.users_count;
    },
    channelVisibility() {
      return this.channel.visibility;
    },
    channelCreatedAt() {
      return this.channel.timestamp_created;
    },
  },
  actions: {
    createChannel(channelName, context, entityId, visibility, successHandler) {
      if (this.isLoadingCreateChannel) {
        return;
      }
      this.isLoadingCreateChannel = true;
      this.apiPost("/channel", {
        channel_name: channelName,
        context: context,
        entity_id: entityId,
        visibility: visibility,
      }, data => {
        this.channels.push(data);
        successHandler(data);
      }, () => {}, () => {
        this.isLoadingCreateChannel = false;
      });
    },
    fetchChannels(context, entityId, visibility) {
      this.isLoadingFetchChannels = true;
      let apiUrl = `/channels/${context}/${entityId}`;
      if (visibility) {
        apiUrl = `/channels/${context}/${entityId}/${visibility}`;
      }
      this.apiGet(apiUrl, {}, data => {
        this.channels = data;
      }, () => {}, () => {
        this.isLoadingFetchChannels = false;
      });
    },
    async fetchChannel(channelId) {
      this.isLoadingFetchChannel = true;
      await this.apiGet(`/channel/${channelId}`,
        {},
        data => {
          this.channel = data;
          this.channelId = channelId;
        },
        () => {},
        () => {
          this.isLoadingFetchChannel = false;
        });
    },
    joinChannel() {
      if (this.isLoadingJoinChannel) {
        return;
      }
      this.isLoadingJoinChannel = true;
      this.apiPost(`/channel/${this.channelId}/user/${useGeneralStore().userId}`,
        {},
        data => {
          this.channel = data;
          useChannelUserFeedStore().reload();
        },
        () => {},
        () => {
          this.isLoadingJoinChannel = false;
          // reload channel data
          window.location.reload();
        });
    },
    updateChannelName(channelName) {
      this.loadingUpdateChannelName = true;
      this.apiPost(`/channel/${this.channelId}/channel-name`,
        {
          channel_name: channelName,
        },
        data => {
          this.channel.channel_name = data.channel_name;
          let index = this.channels.findIndex(channel => channel.channel_id === this.channelId);
          this.channels[index].channel_name = data.channel_name;
        },
        () => {},
        () => {
          this.loadingUpdateChannelName = false;
        });
    },
    updateChannelDescription(description) {
      this.loadingUpdateChannelDescription = true;
      this.apiPost(`/channel/${this.channelId}/description`,
        {
          description: description,
        },
        data => {
          this.channel.description = data.description;
        },
        () => {},
        () => {
          this.loadingUpdateChannelDescription = false;
        });
    },
    fetchChannelCreatorDetails() {
      this.loadingFetchChannelCreatorDetails = true;
      this.apiGet("/channel/user", {
        channel_id: this.channel.channel_id,
        user_id: this.channel.created_by_user_id,
      }, data => {
        this.channelCreator = data;
      }, () => {}, () => {
        this.loadingFetchChannelCreatorDetails = false;
      });
    },
    leaveChannel() {
      if (this.isLoadingLeaveChannel) {
        return;
      }
      this.isLoadingLeaveChannel = true;
      this.apiPost(`/channel/${this.channelId}/user/remove-self`,
        {},
        data => {
          this.channel = data;
          useChannelUserFeedStore().reload();
        },
        () => {},
        () => {
          this.isLoadingLeaveChannel = false;
          // reload channel data
          window.location.reload();
        });
    },
    inviteUserToChannel(userId, success = () => {}) {
      if (this.isLoadingInviteUserToChannel) {
        return;
      }
      this.isLoadingInviteUserToChannel = true;
      this.apiPost(`/channel/${this.channelId}/user/${userId}`,
        {},
        data => {
          this.channel = {
            ...data,
            is_channel_user: this.isChannelUser,
          };
          useChannelUserFeedStore().reload();
          success();
        },
        () => {},
        () => {
          this.isLoadingInviteUserToChannel = false;
          // reload channel data
        });
    },
  },
});
