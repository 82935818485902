export const DEFAULT_ORG_LOGO = "https://cdn-alpha.inspiration.co/images/generic_company_logo.webp";
export const DEFAULT_SPLASH_IMAGE = "https://inspirationprod.blob.core.windows.net/inspiration-cdn/images/publication-blog.jpg";
export const FANWEB_EMPTY_STATE_IMAGE = "https://cdn-alpha.inspiration.co/images/fanweb-empty-state.webp";
export const PUBLICATION_COMMENTS_DEFAULT_CONFIG = {
  enabled: false,
  allowsReply: false,
  allowsLikes: false,
  allowsComments: false,
  allowsUpvoteAndDownvote: false,
  allowsShare: false,
  allowsEditing: false,
  nestedRepliesLimit: 1,
  collapsedReplies: false,
  showReplyReference: false,
  defaultSortingOrder: "Upvotes Descending",
};

export const VALID_UGC_TYPES = {
  name: { name: "None", value: "none" },
  article: { name: "Article", value: "article" },
  multimedia: { name: "Multimedia", value: "multimedia" },
  quickPost: { name: "Quick Post", value: "quickPost" },
};

export const VALID_COMMUNITY_TYPES = {
  none: {
    name: "None",
    value: "none",
  },
  chat: {
    name: "Chat",
    value: "chat",
  },
  forum: {
    name: "Forum",
    value: "forum",
  },
};

export const VALID_MODERATION_STRATEGY_TYPES = {
  not_visible_until_approved: {
    name: "Not visible until approved",
    value: "not_visible_until_approved",
    description: "Unreviewed content will be visible to your community immediately. You can block individual pieces of content or individual users. " +
        "Once a user is blocked, none of their content will be visible unless you explicitly approve it.",
  },
  visible_immediately: {
    name: "Visible immediately",
    value: "visible_immediately",
    description: "Unreviewed content will be visible to your community immediately. You can block individual pieces of content or individual users. " +
        "Once a user is blocked, none of their content will be visible unless you explicitly approve it.",
  },
};
