<template>
  <SplashWrapper :hide-buttons="true">
    <iRow align="center" vertical-align="middle" height="fill">
      <iColumn width="fill" :max-width="450" align="center">
        <iColumn gap="extraLarge" width="fill">
          <iRow align="center">
            <iHeading :wrap="false" font-size="extraExtraLarge" align="center">
              You are currently signed in!
            </iHeading>
          </iRow>
          <iRow align="center">
            <iText variant="subtle">
              You are currently logged in as: {{ userData.first_name }} {{ userData.last_name }} ({{ userData.email_data.email_address }})
            </iText>
          </iRow>
          <iRow align="center">
            <iText variant="subtle">
              This invitation is intended for new users who haven’t created an account yet.
              Please log out to accept this invitation.
            </iText>
          </iRow>
          <iRow align="center">
            <iButton
              variant="primary"
              width="fill"
              :is-loading="isLoading"
              loading-spinner-position="inside"
              @click="submitLogoutUser"
            >
              Logout
            </iButton>
          </iRow>
        </iColumn>
      </iColumn>
    </iRow>
  </SplashWrapper>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useUserStore } from "@/stores/user-store";
import SplashWrapper from "@/components/splash/SplashWrapper";

export default {
  name: "LoggedIn",
  components: {
    SplashWrapper,
  },
  props: {
    redirectUri: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(useUserStore, ["isLoading", "userData"]),
    errorMessage() {
      return this.signupErrorMessage;
    },
  },
  methods: {
    ...mapActions(useUserStore, ["logoutUser"]),
    validatePasswordComplexity(value) {
      this.validPassword = value;
    },
    submitLogoutUser() {
      let uri = decodeURIComponent(this.redirectUri);
      uri = uri.replace(/\\\//g, "/");
      this.logoutUser(uri);
    },
  },
};
</script>
