<template>
  <PageWrapper
    :active-section="SECTION_MONETIZATION"
    :active-page="PAGE_BROWSER_PLUGIN"
    title="Browser Plugins"
    :breadcrumbs="breadcrumbs"
    page-name="Browser Plugins"
    align="left"
    width="fixed"
    action-button-text="Install Plugin"
    @click:action="installPlugin"
  >
    <iImageCarousel :images="carouselImages" :height="200" gap="large" />
    <iColumn width="fill">
      <iHeader>Overview</iHeader>
      <iParagraph max-width="fill">
        <!--eslint-disable-next-line -->
        The inspiration.co browser extension empowers creators to monetize their content with ease. With a simple click, users can generate…
      </iParagraph>
      <iParagraph max-width="fill">
        <!-- eslint-disable-next-line -->
        The inspiration.co browser extension empowers creators to monetize their content with ease. With a simple click, users can generate affiliate links directly from any product page they visit. This seamless tool eliminates the need for manual link generation, saving time and streamlining the affiliate marketing process.
      </iParagraph>
      <iParagraph max-width="fill">
        <!-- eslint-disable-next-line -->
        Quick Link Creation: While browsing any website, users can click the inspiration.co browser extension icon. The extension will automatically detect the product URL and generate a unique affiliate link.
        One-Click Copy: Once the link is created, users can copy it instantly to share across social media, blogs, emails, or newsletters.
        <!-- eslint-disable-next-line -->
        Tracking & Insights: Users can view link performance metrics within their inspiration.co dashboard, helping them track clicks, conversions, and commissions.
        <!-- eslint-disable-next-line -->
        Universal Compatibility: The extension works on thousands of retailer websites, giving creators access to a vast network of monetization opportunities.
      </iParagraph>
      <iParagraph max-width="fill">
        <!-- eslint-disable-next-line -->
        This extension makes affiliate marketing simpler, faster, and more accessible, allowing creators to focus on what they do best — creating and sharing content.
      </iParagraph>
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { SECTION_MONETIZATION, PAGE_BROWSER_PLUGIN } from "@/constants/nav-constants";
import { mapState } from "pinia";
import { useGeneralStore } from "%/stores/general-store";

export default {
  name: "BrowserPlugins",
  components: {
    PageWrapper,
  },
  props: {},
  data() {
    return {
      SECTION_MONETIZATION,
      PAGE_BROWSER_PLUGIN,
    };
  },
  computed: {
    ...mapState(useGeneralStore, [
      "orgId",
    ]),
    carouselImages() {
      return [
        "https://cdn-alpha.inspiration.co/images/extension-upsell-4.png",
        "https://cdn-alpha.inspiration.co/images/extension-upsell-5.png",
        "https://cdn-alpha.inspiration.co/images/extension-upsell-6.png",
      ].map(img => {
        return {
          src: img,
          alt: "",
        };
      });
    },
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/monetization/org/${this.orgId}`, label: "Monetization" },
      ];
    },
  },
  methods: {
    installPlugin() {
      const userAgent = navigator.userAgent;
      let redirectLink = "https://chromewebstore.google.com/detail/inspiration/djnlgjmhgcpbeiemehmdhlgpaojgckjo";
      let hasChromeReference = false;

      if (userAgent.includes("Chrome/")) {
        hasChromeReference = true;
      }

      if (userAgent.includes("Firefox/")) {
        // redirectLink = "";
      }

      if (!hasChromeReference && userAgent.includes("Safari/")) {
        // redirectLink = "";
      }

      if (userAgent.includes("Opera/") || userAgent.includes("OPR/")) {
        // redirectLink = "";
      }

      window.location.href = redirectLink;
    },
  },
};
</script>
