<template>
  <PageWrapper
    :active-section="SECTION_MONETIZATION"
    :active-page="PAGE_MONETIZATION_OVERVIEW"
    page-name="Monetization Overview"
    align="left"
    width="fixed"
  >
    <iHeading>Overview</iHeading>
    <iParagraph>
      This table shows a quick breakdown of your revenue sources.
    </iParagraph>
    <iDataTable
      :fields="overviewFields"
      :items="overviewItems"
      :has-more="false"
      :is-loading="false"
      primary-key="type"
      hide-actions
    >
      <template #td="{data, value}">
        <iText :font-weight="data.type === 'Net Revenue' ? 'dark' : 'standard'">
          {{ value }}
        </iText>
      </template>
    </iDataTable>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { SECTION_MONETIZATION, PAGE_MONETIZATION_OVERVIEW } from "@/constants/nav-constants";
import { overviewFields, overviewItems } from "@/constants/monetization-fake-data";

export default {
  name: "Overview",
  components: {
    PageWrapper,
  },
  data() {
    return {
      SECTION_MONETIZATION,
      PAGE_MONETIZATION_OVERVIEW,
      overviewFields,
      overviewItems,
    };
  },
};
</script>
