<template>
  <PageWrapper
    :active-page="PAGE_STORIES"
    :active-section="SECTION_STORIES"
    :breadcrumbs="breadcrumbs"
    page-name="My Stories"
  >
    <iRow align="between" wrap="nowrap">
      <iColumn width="hug">
        <iHeading>My Stories</iHeading>
      </iColumn>
      <iColumn width="hug">
        <iButton
          :is-loading="isLoadingCreateStory"
          loading-spinner-position="before"
          variant="secondary"
          width="hug"
          @click="createNewStory"
        >
          Create Story
        </iButton>
      </iColumn>
    </iRow>
    <SmartFeed
      :pinia-store="useOrgPostFeedStore"
      default-entity-layout="tableRow"
      default-smart-feed-layout="table"
      sticky-headers
      vertical-align="middle"
    >
      <template #empty-state>
        <iStyledCard width="fill">
          <template #title>
            <iSubHeading variant="subtle" font-size="large" align="left">
              No stories yet
            </iSubHeading>
          </template>
          <template #description>
            <iParagraph
              variant="subtle"
              :max-width="400"
            >
              Write your first story here
            </iParagraph>
          </template>
        </iStyledCard>
      </template>
    </SmartFeed>
    <AssignPublishingPlatformModal
      v-if="assignToPublishingPlatformModalOpen"
      :can-skip="newStoryBool"
      :story-id="!newStoryBool ? story.story_id : ''"
      @close="closeAssignPubPlatModal"
      @change="onChangeAssignPubPlatModal"
    />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { SECTION_STORIES, PAGE_STORIES } from "@/constants/nav-constants";
import SmartFeed from "SmartFeed/components/SmartFeed";
import { useOrgPostFeedStore } from "SmartFeed/stores/feed/org-post-feed-store";
import { mapActions, mapState, mapWritableState } from "pinia";
import { useStoryStore } from "%/stores/story-store";
import AssignPublishingPlatformModal from "@/components/publication/AssignPublishingPlatformModal.vue";

export default {
  name: "Stories",
  components: {
    AssignPublishingPlatformModal,
    SmartFeed,
    PageWrapper,
  },
  data() {
    return {
      SECTION_STORIES,
      PAGE_STORIES,
      newStoryBool: false,
    };
  },
  computed: {
    ...mapState(useStoryStore, ["isLoadingCreateStory", "story", "forcePublishAfterAssigning"]),
    ...mapWritableState(useStoryStore, ["assignToPublishingPlatformModalOpen"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
      ];
    },
  },
  methods: {
    ...mapActions(useStoryStore, [
      "createStory",
      "assignStoryToPublishingPlatform",
      "publishStory",
    ]),
    useOrgPostFeedStore,
    createNewStory() {
      this.newStoryBool = true;
      this.assignToPublishingPlatformModalOpen = true;
    },
    closeAssignPubPlatModal() {
      this.newStoryBool = false;
      this.assignToPublishingPlatformModalOpen = false;
    },
    onChangeAssignPubPlatModal(details) {
      if (this.newStoryBool) {
        this.createStory(details);
      } else {
        this.assignStoryToPublishingPlatform(
          this.story.story_id,
          details.publishing_platform_type,
          details.publishing_platform_id,
          () => {
            this.story.publishing_platform_id = details.publishing_platform_id;
            this.story.publishing_platform_type = details.publishing_platform_type;
            this.story.publishing_platform_name = details.publishing_platform_name;
            if (this.forcePublishAfterAssigning) {
              this.publishingStory();
              this.forcePublishAfterAssigning = false;
            }
          },
          () => {},
        );
      }
      this.assignToPublishingPlatformModalOpen = false;
    },
  },
};
</script>

<style scoped>
</style>
